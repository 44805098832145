<template>
  <div class="dzUpload">
    <a-form
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 16 }"
      style="margin-top: 20px; width: 432px"
    >
      <a-form-item label="上传文件:">
        <a-upload-dragger
          :fileList="fileList"
          :remove="handleRemove"
          :beforeUpload="beforeUpload"
        >
          <p class="ant-upload-drag-icon">
            <a-icon type="inbox" />
          </p>
          <p class="ant-upload-text">单击或拖动文件到此区域进行上传</p>
          <p class="ant-upload-hint">只支持单个上传</p>
        </a-upload-dragger>
      </a-form-item>
      <a-form-item>
        <a-button
          type="primary"
          @click.stop="submit()"
          style="margin-left: 200px"
          size="large"
          >确 定</a-button
        >
      </a-form-item>
    </a-form>

    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableData"
      :pagination="false"
      size="small"
      :rowKey="(record) => record.id"
      :loading="loadingTable"
    >
      <span slot="status" slot-scope="text, record">
        <a-tag :color="record.statusColor">{{ record.statusLabel }}</a-tag>
      </span>
    </a-table>
  </div>
</template>

<script>
export default {
  name: "dzUpload",
  data() {
    return {
      fileList: [],
      tableColumns: [
        {
          title: "序号",
          ellipsis: true,
          dataIndex: "id",
          align: "center",
          width: 100,
        },
        {
          title: "请求批次",
          ellipsis: true,
          dataIndex: "requestId",
          width: 400,
        },
        {
          title: "数量",
          ellipsis: true,
          dataIndex: "count",
          align: "center",
          width: 150,
        },
        {
          title: "批次名称",
          ellipsis: true,
          dataIndex: "batchName",
          width: 400,
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
          align: "center",
          width: 150,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 170,
        },
      ],
      tableData: [],
      loadingTable: false,
      statusList: [],
      timer: null,
    };
  },
  created() {
    this.getStatusList();
  },
  methods: {
    query() {
      this.loadingTable = true;
      this.$http.get("/dzDkDate/list").then((res) => {
        if (res.result == 200) {
          this.tableData = res.data;
          this.tableData.forEach((item) => {
            this.statusList.forEach((status) => {
              if (item.status == status.code) {
                this.$set(item, "statusLabel", status.desc);
                switch (item.status) {
                  case "WAITING":
                    item.statusColor = "orange";
                    break;
                  case "RUNNING":
                    item.statusColor = "orange";
                    break;
                  case "SUCCESS":
                    item.statusColor = "green";
                    break;
                  case "ERROR":
                    item.statusColor = "red";
                    break;
                  case "WAITING_MATCH":
                    item.statusColor = "orange";
                    break;
                  case "MATCHING":
                    item.statusColor = "orange";
                    break;
                  case "MATCH_SUCCESS":
                    item.statusColor = "green";
                    break;
                  case "MATCH_ERROR":
                    item.statusColor = "red";
                    break;
                }
              }
            });
          });
          let timerFlag = false;
          this.tableData.forEach((item) => {
            if (
              (item.status == "WAITING" ||
                item.status == "RUNNING" ||
                item.status == "WAITING_MATCH" ||
                item.status == "MATCHING") &&
              !this.timer
            ) {
              timerFlag = true;
            }
          });
          if (timerFlag) {
            this.refresh();
          } else {
            if (this.timer) {
              clearInterval(this.timer);
              this.timer = null;
            }
          }
          this.loadingTable = false;
        }
      });
    },
    refresh() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      this.timer = setInterval(() => {
        this.query();
      }, 2000);
    },
    getStatusList() {
      this.$http.get("/dzDkDate/status").then((res) => {
        if (res.result == 200) {
          this.statusList = res.data;
          this.query();
        }
      });
    },
    // 上传文件数据处理
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      this.fileList = this.fileList.slice(-1);
      return false;
    },
    submit() {
      if (this.fileList.length == 0) {
        this.$message.warning("请选择文件后再上传");
        return;
      }
      let data = {
        file: this.fileList[0],
      };
      this.$http.files("/dzDkDate/upload", data).then((res) => {
        if (res.result == 200) {
          this.$message.success("上传成功");
        }
      });
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
};
</script>
