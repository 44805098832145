<template>
  <div class="tdPlaceConfig">
    <div class="reminder">
      内容格式: PhoneMd5{{ type == "DATA" ? ",省份,城市,运营商" : "" }}
    </div>
    <div class="searchCondition">
      <div class="searchButton">
        <a-button type="primary" @click="add" icon="plus">添加</a-button>
      </div>
    </div>
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="false"
      :loading="loadingTable"
      size="small"
      :rowKey="(record, index) => index"
    >
      <span slot="status" slot-scope="text, record">
        <a-tag color="orange" v-if="record.status == 'RUNNING'">上传中</a-tag>
        <a-tag color="red" v-else-if="record.status == 'ERROR'">上传失败</a-tag>
        <a-tag color="green" v-else> 上传成功 </a-tag>
      </span>
      <span slot="action" slot-scope="text, record, index">
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(index)"
        >
          <template slot="title">是否确认删除{{ record.label }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 添加 -->
    <a-modal
      title="添加"
      v-model="addKeyVisible"
      :maskClosable="false"
      class="action-class"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item :label="(type == 'DATA' ? '底包' : '黑名单') + '名称:'">
          <a-input v-model="label" placeholder="请输入" />
        </a-form-item>
        <a-form-item label="路径:">
          <a-input v-model="path" placeholder="请输入路径" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button key="submit" type="primary" @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "tdPlaceConfig",
  props: ["type"],
  data() {
    return {
      groupId: "",
      addKeyVisible: false,
      tableColumns: [
        {
          title: "label",
          dataIndex: "label",
          ellipsis: true,
        },
        {
          title: "value",
          dataIndex: "value",
          ellipsis: true,
        },
        {
          title: "状态",
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 80,
        },
      ],
      tableDataSource: [],
      loadingTable: false,
      label: "",
      path: "",
      queryTimer: null,
    };
  },
  computed: {
    content() {
      let obj = {
        data: this.tableDataSource,
      };
      return JSON.stringify(obj);
    },
  },
  mounted() {
    this.query();
  },
  methods: {
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query();
    },
    // 点击查询
    query() {
      let data = {
        type: this.type,
      };
      this.loadingTable = true;

      this.$http.get("/td/getConfig", data).then((res) => {
        if (res.result == 200) {
          this.loadingTable = false;
          this.tableDataSource = res.data.data;
          let runningArr = this.tableDataSource.filter((item) => {
            return item.status == "RUNNING";
          });
          if (runningArr.length > 0 && !this.queryTimer) {
            this.queryTimer = setInterval(() => {
              this.query();
            }, 2000);
          }
          if (runningArr.length == 0 && this.queryTimer) {
            clearInterval(this.queryTimer);
            this.queryTimer = null;
          }
        }
      });
    },
    // 新增
    add() {
      this.label = "";
      this.path = "";
      this.addKeyVisible = true;
    },
    // 确定新增
    add_submit() {
      if (!this.label || !this.path) {
        this.$message.warning("请填写信息");
        return;
      }
      let data = {
        label: this.label,
        path: this.path,
        content: this.content,
        type: this.type,
      };
      this.$http.files("/td/updateConfig", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("添加成功");
          this.query();
          this.addKeyVisible = false;
        }
      });
    },
    // 点击删除
    deleteClick(index) {
      this.tableDataSource.splice(index, 1);
      let data = {
        content: this.content,
        type: this.type,
      };
      this.$http.files("/td/updateConfig", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
        }
      });
    },
  },
  beforeDestroy() {
    if (this.queryTimer) {
      clearInterval(this.queryTimer);
      this.queryTimer = null;
    }
  },
};
</script>
