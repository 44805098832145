<template>
  <div class="tdTask">
    <div class="reminder">每十分钟自动更新一次</div>
    <a-tabs v-model="active">
      <a-tab-pane key="1" tab="package取数">
        <router-view
          name="second"
          model="AI00160"
          v-if="active == '1'"
        ></router-view>
      </a-tab-pane>
      <a-tab-pane key="2" tab="投放取数">
        <router-view
          name="second"
          model="AI00166"
          v-if="active == '2'"
        ></router-view>
      </a-tab-pane>
      <a-tab-pane key="3" tab="配置">
        <a-tabs v-model="configActive">
          <a-tab-pane key="1" tab="底包">
            <router-view
              name="second"
              type="DATA"
              v-if="configActive == '1'"
            ></router-view>
          </a-tab-pane>
          <a-tab-pane key="2" tab="黑名单">
            <router-view
              name="second"
              type="BLACK"
              v-if="configActive == '2'"
            ></router-view>
          </a-tab-pane>
        </a-tabs>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
export default {
  name: "tdTask",
  data() {
    return {
      active: "1",
      configActive: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/tdTask/tdPlacePackageData") {
          this.active = "1";
        } else if (newVal == "/tdTask/tdPlaceFetchData") {
          this.active = "2";
        } else if (newVal.indexOf("/tdTask/tdPlaceConfig") !== -1) {
          this.active = "3";
          if (newVal == "/tdTask/tdPlaceConfig/data") {
            this.configActive = "1";
          } else if (newVal == "/tdTask/tdPlaceConfig/black") {
            this.configActive = "2";
          }
        }
      },
    },
    active: {
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/tdTask/tdPlacePackageData") return;
          this.$router.replace("/tdTask/tdPlacePackageData");
        }
        if (newVal === "2") {
          if (this.$route.path == "/tdTask/tdPlaceFetchData") return;
          this.$router.replace("/tdTask/tdPlaceFetchData");
        }
        if (newVal === "3") {
          this.configActive = "1";
          if (this.$route.path == "/tdTask/tdPlaceConfig/data") return;
          this.$router.replace("/tdTask/tdPlaceConfig/data");
        }
      },
    },
    configActive: {
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/tdTask/tdPlaceConfig/data") return;
          this.$router.replace("/tdTask/tdPlaceConfig/data");
        }
        if (newVal === "2") {
          if (this.$route.path == "/tdTask/tdPlaceConfig/black") return;
          this.$router.replace("/tdTask/tdPlaceConfig/black");
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.tdTask {
  padding-top: 20px;
}
</style>
