<template>
  <div class="apiList">
    <a-tabs v-model="active">
      <a-tab-pane tab="HTTP接口列表" key="1">
        <router-view
          name="three"
          :active="active"
          v-if="active == '1'"
        ></router-view>
      </a-tab-pane>
      <a-tab-pane tab="KAFKA接口列表" key="2">
        <router-view
          name="three"
          :active="active"
          v-if="active == '2'"
        ></router-view>
      </a-tab-pane>
      <a-tab-pane tab="FTP接口列表" key="3">
        <router-view
          name="three"
          :active="active"
          v-if="active == '3'"
        ></router-view>
      </a-tab-pane>
      <a-tab-pane key="4" tab="接口账号列表">
        <router-view name="three" v-if="active == '4'"></router-view>
      </a-tab-pane>
      <a-tab-pane tab="子任务列表" key="5">
        <router-view
          name="three"
          :active="active"
          v-if="active == '5'"
        ></router-view>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
export default {
  name: "apiList",
  data() {
    return {
      active: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/tabTaskList/apiList/HTTPInterface") {
          this.active = "1";
        } else if (newVal == "/tabTaskList/apiList/KAFKAInterface") {
          this.active = "2";
        } else if (newVal == "/tabTaskList/apiList/FTPInterface") {
          this.active = "3";
        } else if (newVal == "/tabTaskList/apiList/apiUserList") {
          this.active = "4";
        } else if (newVal == "/tabTaskList/apiList/apiSubTaskList") {
          this.active = "5";
        }
      },
    },
    active: {
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/tabTaskList/apiList/HTTPInterface") return;
          this.$router.replace("/tabTaskList/apiList/HTTPInterface");
        }
        if (newVal === "2") {
          if (this.$route.path == "/tabTaskList/apiList/KAFKAInterface") return;
          this.$router.replace("/tabTaskList/apiList/KAFKAInterface");
        }
        if (newVal === "3") {
          if (this.$route.path == "/tabTaskList/apiList/FTPInterface") return;
          this.$router.replace("/tabTaskList/apiList/FTPInterface");
        }
        if (newVal === "4") {
          if (this.$route.path == "/tabTaskList/apiList/apiUserList") return;
          this.$router.replace("/tabTaskList/apiList/apiUserList");
        }
        if (newVal === "5") {
          if (this.$route.path == "/tabTaskList/apiList/apiSubTaskList") return;
          this.$router.replace("/tabTaskList/apiList/apiSubTaskList");
        }
      },
    },
  },
};
</script>

<style></style>
