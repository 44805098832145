import Vue from "vue";
import App from "./App";
import router from "./router";
import axios from "axios";
import http from "./components/js/http.js";
import common from "./components/js/common.js";
import "element-ui/lib/theme-chalk/index.css";
import { Progress, Cascader } from "element-ui";
import antd from "./components/js/antd";
import Directives from "./components/directives";
import VueDraggableResizable from "vue-draggable-resizable";
import syncLoading from "./components/js/syncLoading.js"; // 引入loading
import VueClipboard from "vue-clipboard2";

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.prototype.$http = http;
Vue.prototype.$common = common;
Vue.component("vue-draggable-resizable", VueDraggableResizable);
Vue.use(Directives);
Vue.use(syncLoading); // 全局使用loading
Vue.use(Progress);
Vue.use(Cascader);
Vue.use(VueClipboard);
Vue.use(antd);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
