<template>
  <div class="umengFetchData">
    <a-tabs v-model="activeName">
      <a-tab-pane tab="任务列表" key="1">
        <router-view name="second" v-if="activeName == '1'"></router-view>
      </a-tab-pane>
      <a-tab-pane tab="数据集" key="2">
        <router-view name="second" v-if="activeName == '2'"></router-view>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
export default {
  name: "umengFetchData",
  data() {
    return {
      activeName: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/umengFetchData/umengTaskDetail") {
          this.activeName = "1";
        } else if (newVal == "/umengFetchData/umengDataSet") {
          this.activeName = "2";
        }
      },
    },
    activeName: {
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/umengFetchData/umengTaskDetail") return;
          this.$router.replace("/umengFetchData/umengTaskDetail");
        }
        if (newVal === "2") {
          if (this.$route.path == "/umengFetchData/umengDataSet") return;
          this.$router.replace("/umengFetchData/umengDataSet");
        }
      },
    },
  },
};
</script>

<style></style>
