<template>
  <div class="userList">
    <div class="searchCondition" style="margin-top: 20px">
      <div class="searchConditionItem">
        <span>平台：</span>
        <a-select v-model="platformSearch" placeholder="请选择平台" allowClear>
          <a-select-option
            v-for="item in options"
            :key="item.code"
            :value="item.code"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        <span>用户名：</span>
        <a-input
          v-model.trim="requestId"
          placeholder="请输入用户名"
          allowClear
          @change="allowClearChange"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchConditionItem">
        <span>accessKey：</span>
        <a-input
          v-model.trim="accessKeySearch"
          placeholder="请输入accessKey"
          allowClear
          @change="allowClearChange"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
      </div>
    </div>

    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableData"
      :pagination="tablePagination"
      size="small"
      :rowKey="(record) => record.id"
      :loading="loadingTable"
    >
      <span slot="action" slot-scope="text, record">
        <a-button type="link" @click="dumpLogExport(record)">导出</a-button>
      </span>
    </a-table>

    <a-modal
      :maskClosable="false"
      title="导出"
      v-model="exportShow"
      width="500px"
      :afterClose="exportClose"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="result:">
          <a-input
            v-model="result"
            placeholder="请输入result"
            style="width: 80%"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button type="primary" @click="export_submit">确定</a-button>
          <a-button @click="exportShow = false">取消</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "credentialStuffingBatchList",
  data() {
    return {
      tableColumns: [
        {
          title: "序号",
          ellipsis: true,
          dataIndex: "id",
          align: "center",
          width: 100,
        },
        {
          title: "批次",
          ellipsis: true,
          dataIndex: "requestId",
          width: 200,
        },
        {
          title: "平台",
          ellipsis: true,
          dataIndex: "platform",
          width: 200,
        },
        {
          title: "accessKey",
          ellipsis: true,
          dataIndex: "accessKey",
          width: 200,
        },
        {
          title: "数量",
          ellipsis: true,
          dataIndex: "limit",
          align: "center",
          width: 100,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 120,
        },
      ],
      tableData: [],
      loadingTable: false,
      tablePagination: {},
      currentPage: 1,
      platformSearch: undefined,
      requestId: "",
      accessKeySearch: "",
      options: [],
      result: "",
      exportShow: false,
      exportId: "",
      exportRequestId: "",
    };
  },
  mounted() {
    this.query(1);
  },
  methods: {
    download(uuid) {
      location.href =
        location.protocol +
        process.env.VUE_APP_BASE_API +
        `/dumpLog/download?uuid=${uuid}&requestId=${this.exportRequestId}`;
      this.$message.success("导出成功");
    },
    dumpLogCheck(uuid) {
      this.$http.get("/dumpLog/check", { uuid }).then((res) => {
        if (res.result === 200) {
          if (res.data == "true") {
            this.download(uuid);
          } else if (res.data == "false") {
            this.dumpLogCheck(uuid);
          } else {
            this.$message.error(res.data);
          }
        }
      });
    },
    exportClose() {
      this.result = "";
      this.exportId = "";
    },
    export_submit() {
      let data = {
        id: this.exportId,
        result: this.result,
      };
      this.$http.get("/dumpLog/export", data).then((res) => {
        if (res.result === 200) {
          this.exportShow = false;
          this.dumpLogCheck(res.data);
        }
      });
    },
    getPlatformList() {
      this.$http.get("/user/platformList", null, "/apiPrefix").then((res) => {
        if (res.result === 200) {
          this.options = res.data;
        }
      });
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 查询
    query(index) {
      this.loadingTable = true;
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: 10,
        platform: this.platformSearch,
        requestId: this.requestId,
        accessKey: this.accessKeySearch,
      };
      this.getUserList(data);
      this.getPlatformList();
    },
    getUserList(data) {
      this.$http.get("/dumpLog/logPage", data).then((res) => {
        if (res.result === 200) {
          this.tableData = res.data.records;
          this.tablePagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.currentPage_data(current),
          };
          this.loadingTable = false;
        }
      });
    },
    // 翻页
    currentPage_data(index) {
      this.query(index);
    },
    dumpLogExport(val) {
      this.exportId = val.id;
      this.exportRequestId = val.requestId;
      this.exportShow = true;
    },
  },
};
</script>
