<template>
  <div class="timedTask">
    <a-tabs v-model="active" type="card">
      <a-tab-pane tab="定时任务列表" key="1">
        <div class="searchCondition">
          <div class="searchConditionItem">
            <span>任务名称：</span>
            <a-input
              v-model.trim="name"
              placeholder="请输入任务名称"
              allowClear
              @change="allowClearChange"
              @pressEnter="query(1)"
            />
          </div>
          <div class="searchConditionItem">
            <span>状态：</span>
            <a-select
              v-model="statusSelect"
              allowClear
              placeholder="请选择"
              @change="query(1)"
            >
              <a-select-option
                v-for="(item, index) in statusList"
                :key="index"
                :value="item.code"
              >
                {{ item.desc }}
              </a-select-option>
            </a-select>
          </div>
          <div class="searchConditionItem">
            <span>提交人：</span>
            <a-select
              v-model="timedSubmitter"
              placeholder="请选择"
              showSearch
              :filterOption="false"
              allowClear
              @search="searchUserList"
              @blur="getUserList()"
              @change="query(1)"
              style="width: 100px"
            >
              <a-select-option
                v-for="item in submitterList"
                :key="item.id"
                :value="item.loginName"
              >
                {{ item.userName }}
              </a-select-option>
            </a-select>
          </div>
          <div class="searchButton">
            <a-button type="primary" @click="query(1)" icon="search"
              >查询</a-button
            >
            <a-button type="primary" @click="newTimedTask" icon="plus"
              >创建定时任务</a-button
            >
          </div>
        </div>

        <a-table
          :rowClassName="$common.rowClassColor"
          bordered
          :components="$common.getTitle(tableColumns)"
          :columns="tableColumns"
          :dataSource="tableData"
          :pagination="tablePagination"
          size="small"
          :rowKey="(record) => record.id"
          :loading="loadingTable"
        >
          <span slot="status" slot-scope="text, record">
            <a-tag :color="record.statusColor">{{ record.statusLabel }}</a-tag>
          </span>
          <span slot="cron" slot-scope="text, record">
            {{ cronFormat(text) }}
            <a-icon
              type="edit"
              @click="update_cron(record)"
              style="
                float: right;
                color: #1890ff;
                font-size: 13px;
                margin-top: 5px;
                cursor: pointer;
              "
            />
          </span>
          <span slot="action" slot-scope="text, record">
            <a-popconfirm
              placement="left"
              okText="确认"
              cancelText="取消"
              @confirm="up_pipeline(record)"
              v-if="record.status == 'STOP'"
            >
              <template slot="title"
                >是否确认启动【{{ record.name }}】定时</template
              >
              <a href="javascript:;">启动定时</a>
            </a-popconfirm>
            <a-popconfirm
              placement="left"
              okText="确认"
              cancelText="取消"
              @confirm="stop_pipeline(record)"
              v-else
            >
              <template slot="title"
                >是否确认停止【{{ record.name }}】定时</template
              >
              <a href="javascript:;">停止定时</a>
            </a-popconfirm>
            <a-divider type="vertical" />
            <a-button
              type="link"
              class="text-button"
              @click="subtaskList(record, 1)"
              >子任务列表</a-button
            >
            <a-divider type="vertical" />
            <a-button
              type="link"
              class="text-button"
              @click="update_pipeline(record)"
              >修改</a-button
            >
            <a-divider type="vertical" />
            <a-dropdown>
              <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
                其它 <a-icon type="down" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a-button
                    type="link"
                    size="small"
                    @click="setPipelineTaskClean(record)"
                    >设置清理周期</a-button
                  >
                </a-menu-item>
                <a-menu-item>
                  <a-popconfirm
                    placement="left"
                    okText="确认"
                    cancelText="取消"
                    @confirm="delete_pipeline(record)"
                  >
                    <template slot="title"
                      >确认是否删除{{ record.name }}</template
                    >
                    <a href="javascript:;" style="color: #ff4d4f">删除</a>
                  </a-popconfirm>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
      </a-tab-pane>
      <a-tab-pane tab="子任务列表" key="2">
        <div class="searchCondition">
          <div class="searchConditionItem">
            <span>序号：</span>
            <a-input
              v-model.trim="id"
              placeholder="请输入"
              style="width: 90px"
              allowClear
              @change="allowClearChange"
              @pressEnter="subtaskList(null, 1)"
            />
          </div>
          <div class="searchConditionItem">
            <span>任务名称：</span>
            <a-input
              v-model.trim="subTaskName"
              placeholder="请输入任务名称"
              allowClear
              @change="allowClearSubChange"
              @pressEnter="subtaskList(null, 1)"
            />
          </div>
          <div class="searchConditionItem">
            <span>状态：</span>
            <a-select v-model="statusSelect" allowClear placeholder="请选择">
              <a-select-option
                v-for="item in statusList"
                :key="item.code"
                :value="item.code"
              >
                {{ item.desc }}
              </a-select-option>
            </a-select>
          </div>
          <div class="searchConditionItem">
            <span>提交人：</span>
            <a-select
              v-model="timedSubTaskSubmitter"
              placeholder="请选择"
              showSearch
              :filterOption="false"
              allowClear
              @search="searchUserList"
              @blur="getUserList()"
              @change="subtaskList(null, 1)"
              style="width: 100px"
            >
              <a-select-option
                v-for="item in submitterList"
                :key="item.id"
                :value="item.loginName"
              >
                {{ item.userName }}
              </a-select-option>
            </a-select>
          </div>
          <div class="searchButton">
            <a-button type="primary" @click="subtaskList(null, 1)" icon="search"
              >查询</a-button
            >
          </div>
        </div>

        <a-table
          :rowClassName="$common.rowClassColor"
          bordered
          :components="$common.getTitle(subtaskListTableColumns)"
          :columns="subtaskListTableColumns"
          :dataSource="subtaskListTableData"
          :pagination="subtaskListTablePagination"
          size="small"
          :rowKey="(record) => record.id"
          :loading="subtaskListLoadingTable"
        >
          <span slot="status" slot-scope="text, record">
            <a-tag :color="record.statusColor">{{ record.statusLabel }}</a-tag>
            <a-tooltip
              v-if="record.status == 'SCHEDULED'"
              :title="'执行时间:' + record.executeDate"
              placement="top"
            >
              <a-icon type="exclamation-circle" style="color: #d2983c" />
            </a-tooltip>
          </span>
          <span slot="executeSecond" slot-scope="text, record">
            <span v-if="record.status == 'SCHEDULED'">-</span>
            <span v-else>
              {{ $common.timeConsuming(record.executeSecond) }}
            </span>
          </span>
          <span slot="action" slot-scope="text, record">
            <a-button
              type="link"
              class="text-button"
              @click="cook_pipeline(record)"
              >{{ record.status == "DRAFT" ? "编辑" : "查看" }}</a-button
            >
            <a-divider type="vertical" />
            <a-button
              type="link"
              class="text-button"
              @click="file_download(record)"
              :disabled="record.status !== 'SUCCESS' && record.expired !== true"
              >文件下载</a-button
            >
            <a-divider type="vertical" />
            <a-popconfirm
              placement="left"
              okText="确认"
              cancelText="取消"
              @confirm="stopTaskNow(record)"
              :disabled="record.status !== 'RUNNING'"
            >
              <template slot="title">中断执行{{ record.name }}任务?</template>
              <a-button
                type="link"
                size="small"
                :disabled="record.status !== 'RUNNING'"
                >中断执行</a-button
              >
            </a-popconfirm>
          </span>
        </a-table>
      </a-tab-pane>
    </a-tabs>

    <a-modal
      :maskClosable="false"
      title="创建定时任务"
      v-model="createShow"
      width="420px"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="名称">
          <a-input
            v-model="taskName"
            placeholder="请输入名称"
            style="width: 90%"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button type="primary" @click="create_submit()">确 定</a-button>
          <a-button @click="createShow = false">取 消</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      :maskClosable="false"
      title="修改cron表达式"
      v-model="updateCronShow"
      width="500px"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="cron表达式:">
          <a-select
            v-model="cronSelect"
            placeholder="请选择cron表达式"
            style="width: 160px"
          >
            <a-select-option
              v-for="item in cronList"
              :key="item.id"
              :value="item.value"
              >{{ item.label }}</a-select-option
            >
          </a-select>
          <a-input
            v-if="cronSelect == ''"
            v-model="cron"
            placeholder="请输入cron表达式"
            style="width: 160px; margin-left: 5px"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button type="primary" @click="updateCronSubmit()">确 定</a-button>
          <a-button @click="updateCronShow = false">取 消</a-button>
        </div>
      </template>
    </a-modal>
    <a-modal
      :maskClosable="false"
      title="子任务列表"
      v-model="subtaskListShow"
      width="1200px"
      :afterClose="subtaskListClosed"
      :footer="null"
    >
      <div class="searchCondition">
        <div class="searchConditionItem">
          <span>任务名称：</span>
          <a-input
            v-model.trim="subTaskName"
            placeholder="请输入任务名称"
            allowClear
            @change="allowClearSubChange"
            @pressEnter="subtaskList(null, 1)"
          />
        </div>
        <div class="searchButton">
          <a-button type="primary" @click="subtaskList(null, 1)" icon="search"
            >查询</a-button
          >
        </div>
      </div>
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(subtaskListTableColumns)"
        :columns="subtaskListTableColumns"
        :dataSource="subtaskListTableData"
        :pagination="subtaskListTablePagination"
        size="small"
        :rowKey="(record) => record.id"
        :loading="subtaskListLoadingTable"
      >
        <span slot="status" slot-scope="text, record">
          <a-tag :color="record.statusColor">{{ record.statusLabel }}</a-tag>
          <a-tooltip
            v-if="record.status == 'SCHEDULED'"
            :title="'执行时间:' + record.executeDate"
            placement="top"
          >
            <a-icon type="exclamation-circle" style="color: #d2983c" />
          </a-tooltip>
        </span>
        <span slot="executeSecond" slot-scope="text, record">
          <span v-if="record.status == 'SCHEDULED'">-</span>
          <span v-else>
            {{ $common.timeConsuming(record.executeSecond) }}
          </span>
        </span>
        <span slot="action" slot-scope="text, record">
          <a-button
            type="link"
            class="text-button"
            @click="cook_pipeline(record)"
            >{{ record.status == "DRAFT" ? "编辑" : "查看" }}</a-button
          >
          <a-divider type="vertical" />
          <a-button
            type="link"
            class="text-button"
            @click="file_download(record)"
            :disabled="record.status !== 'SUCCESS' && record.expired !== true"
            >文件下载</a-button
          >
          <a-divider type="vertical" />
          <a-button
            type="link"
            class="text-button"
            @click="stopTaskNow(record)"
            :disabled="record.status !== 'RUNNING'"
            >中断执行</a-button
          >
        </span>
      </a-table>
    </a-modal>
    <a-modal
      :maskClosable="false"
      title="下载文件列表"
      v-model="fileListShow"
      width="1000px"
      :afterClose="fileListClosed"
      :footer="null"
    >
      <a-tabs v-model="fileActive">
        <a-tab-pane tab="重要文件" key="1"></a-tab-pane>
        <a-tab-pane tab="临时文件" key="2"></a-tab-pane>
      </a-tabs>
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(fileListTableColumns)"
        :columns="fileListTableColumns"
        :dataSource="fileListTableData"
        :pagination="false"
        size="small"
        :rowKey="(record) => record.id"
        :loading="fileListLoadingTable"
      >
        <span slot="lineCountLabel" slot-scope="text, record">
          <a-tooltip placement="left">
            <div slot="title">
              {{ record.lineCount }}
            </div>
            <span> {{ text }} </span>
          </a-tooltip>
        </span>
        <span slot="action" slot-scope="text, record">
          <a-button
            type="link"
            size="small"
            @click="downloadClick(record)"
            :disabled="record.expired"
            >下载</a-button
          >
          <a-divider type="vertical" />
          <a-button type="link" size="small" @click="shareClick(record)"
            >分享</a-button
          >
          <a-divider type="vertical" />
          <a-button
            type="link"
            size="small"
            @click="checkFileContent(record)"
            :disabled="
              record.fileName.split('.')[
                record.fileName.split('.').length - 1
              ] == 'zip'
            "
            >查看</a-button
          >
          <a-divider type="vertical" />
          <a-button type="link" size="small" @click="copyPath(record)"
            >复制路径</a-button
          >
        </span>
      </a-table>
    </a-modal>

    <a-modal
      :maskClosable="false"
      width="30%"
      title="分享设置"
      v-model="innerVisible"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }">
        <a-form-item label="密码">
          <a-switch
            v-model="passwordSwitch"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="password == ''"
          />
          <template v-if="passwordSwitch">
            <a-input
              v-model="password"
              placeholder="请输入8位以上密码"
              style="width: 250px; margin: 0 5px"
            />
            <a-button @click="random" type="link">随机</a-button>
          </template>
        </a-form-item>
        <a-form-item label="过期时间">
          <a-select v-model="expireSeconds" placeholder="请选择过期时间">
            <a-select-option
              v-for="item in expireSecondList"
              :key="item.id"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="文件空间">
          <a-select v-model="fileSpaceId" placeholder="请选择">
            <a-select-option
              v-for="item in fileSpaceList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="备注">
          <a-textarea v-model="remark" placeholder="请输入备注" :rows="4" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="innerVisible = false">取 消</a-button>
          <a-button type="primary" @click="shareConfirm">确 定</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      width="30%"
      title="复制链接"
      v-model="copyVisible"
      :footer="false"
      :maskClosable="false"
    >
      <a-textarea v-model="inviteCode" :rows="2" />
      <a-button
        type="primary"
        class="copy-code-button"
        v-copy="inviteCode"
        style="margin-top: 10px"
        >一键复制</a-button
      >
    </a-modal>

    <a-modal
      :maskClosable="false"
      width="700px"
      title="查看文件"
      v-model="checkFileContentShow"
      :footer="null"
    >
      <a-table
        v-if="fileType == 'xls' || fileType == 'xlsx'"
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(excelFileColumns)"
        :columns="excelFileColumns"
        :dataSource="excelFileList"
        :pagination="false"
        size="small"
        :rowKey="(record) => record.id"
      />

      <a-textarea
        v-else
        v-model="fileContent"
        :rows="20"
        style="margin-bottom: 10px"
      />
      <h4
        style="margin: 0 0 10px 0"
        v-if="
          fileContent.split('\n').length == 500 || excelFileList.length == 500
        "
      >
        最多只能显示五百行
      </h4>
    </a-modal>

    <a-modal
      width="500px"
      title="设置清理周期"
      v-model="setPipelineTaskCleanShow"
      :maskClosable="false"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="清理周期:">
          <a-select v-model="day" placeholder="请选择清理周期">
            <a-select-option
              v-for="(item, index) in dayList"
              :key="index"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="setPipelineTaskCleanShow = false">取 消</a-button>
          <a-button type="primary" @click="setPipelineTaskCleanSubmit"
            >确 定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "timedTask",
  data() {
    return {
      name: "",
      statusSelect: undefined,
      timedSubmitter: undefined,
      timedSubTaskSubmitter: undefined,
      submitterList: [],
      taskId: "",
      cronTaskShow: false,
      tableColumns: [
        {
          title: "序号",
          ellipsis: true,
          dataIndex: "id",
          align: "center",
          width: 100,
        },
        {
          title: "任务名称",
          ellipsis: true,
          dataIndex: "name",
          width: 250,
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
          width: 100,
        },
        {
          title: "cron表达式",
          ellipsis: true,
          dataIndex: "cron",
          width: 200,
          scopedSlots: { customRender: "cron" },
        },
        {
          title: "下次执行时间",
          ellipsis: true,
          dataIndex: "nextExecuteTime",
          align: "center",
          width: 170,
        },
        {
          title: "创建人",
          ellipsis: true,
          dataIndex: "userName",
          width: 100,
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
          align: "center",
          width: 120,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 340,
        },
      ],
      tableData: [],
      tablePagination: {},
      loadingTable: false,
      currentPage: 1,
      subtaskCurrentPage: 1,
      ids: "",
      timedTaskName: "",
      createShow: false,
      taskName: "",
      subtaskListTableData: [],
      subtaskListTableColumns: [
        {
          title: "序号",
          ellipsis: true,
          dataIndex: "id",
          align: "center",
          width: 80,
        },
        {
          title: "任务名称",
          ellipsis: true,
          dataIndex: "name",
          width: 400,
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
          align: "center",
          width: 100,
        },
        {
          title: "耗时",
          ellipsis: true,
          dataIndex: "executeSecond",
          scopedSlots: { customRender: "executeSecond" },
          align: "center",
          width: 100,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 250,
        },
      ],
      subtaskListTablePagination: {},
      subtaskListLoadingTable: false,
      subtaskListShow: false,
      fileListTableData: [],
      fileListLoadingTable: false,
      fileListShow: false,
      innerVisible: false,
      copyVisible: false,
      inviteCode: "",
      password: "",
      expireSeconds: "",
      passwordSwitch: false,
      expireSecondList: [
        { id: 1, label: "永不过期", value: -1 },
        { id: 2, label: "1天", value: 86400 },
        { id: 3, label: "2天", value: 172800 },
        { id: 4, label: "3天", value: 259200 },
        { id: 5, label: "7天", value: 604800 },
        { id: 6, label: "15天", value: 1296000 },
        { id: 7, label: "一个月", value: 2592000 },
        { id: 8, label: "31天", value: 2592000 },
        { id: 9, label: "45天", value: 3888000 },
        { id: 10, label: "6个月", value: 15552000 },
        { id: 11, label: "1年", value: 31536000 },
      ],
      fileSpaceId: -1,
      remark: "",
      fileSpaceList: [],
      fileListTableColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 90,
          align: "center",
        },
        {
          title: "文件名称",
          ellipsis: true,
          dataIndex: "fileName",
          width: 180,
        },
        {
          title: "文件大小",
          ellipsis: true,
          dataIndex: "fileSize",
          width: 100,
          align: "center",
        },
        {
          title: "行数",
          ellipsis: true,
          dataIndex: "lineCountLabel",
          scopedSlots: { customRender: "lineCountLabel" },
          width: 80,
          align: "center",
        },
        {
          title: "标签",
          ellipsis: true,
          dataIndex: "label",
          width: 80,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 220,
        },
      ],
      parentTaskId: 0,
      active: "1",
      fileActive: "1",
      jobId: "",
      fileType: "txt",
      checkFileContentShow: false,
      fileContent: "",
      excelFileList: [],
      excelFileColumns: [],
      updateCronShow: false,
      cronSelect: "",
      cron: "",
      updateId: "",
      id: "",
      cronList: [
        { id: 1, label: "每分钟同步一次", value: "0 * * * * ?" },
        { id: 2, label: "五分钟同步一次", value: "0 */5 * * * ?" },
        { id: 3, label: "十分钟同步一次", value: "0 */10 * * * ?" },
        { id: 4, label: "十五分钟同步一次", value: "0 */15 * * * ?" },
        { id: 5, label: "三十分钟同步一次", value: "0 */30 * * * ?" },
        { id: 6, label: "一小时同步一次", value: "0 0 * * * ?" },
        { id: 7, label: "自定义", value: "" },
      ],
      statusList: [],
      subTaskName: "",
      shareTimer: null,
      syncLoading: null,
      id: undefined,
      day: undefined,
      setPipelineTaskCleanShow: false,
      dayList: [
        { label: "一天", value: 1 },
        { label: "两天", value: 2 },
        { label: "三天", value: 3 },
        { label: "七天", value: 7 },
        { label: "十五天", value: 15 },
      ],
      searchTimer: null,
    };
  },
  computed: {
    cronFormat() {
      return (cron) => {
        let arr = this.cronList.filter((item) => {
          return item.value == cron && cron;
        });
        if (arr.length > 0) {
          return arr[0].label;
        }
        return cron;
      };
    },
  },
  watch: {
    active: {
      immediate: true,
      handler(newVal, oldVal) {
        this.getUserList(null, newVal);
      },
    },
    fileActive: {
      handler(newVal, oldVal) {
        switch (newVal) {
          case "1":
            this.getFileListTableData(this.jobId, true);
            break;
          case "2":
            this.getFileListTableData(this.jobId, false);
            break;
        }
      },
    },
    timedSubmitter: {
      handler(newVal) {
        localStorage.setItem("timedSubmitter", newVal);
      },
    },
    timedSubTaskSubmitter: {
      handler(newVal) {
        localStorage.setItem("timedSubTaskSubmitter", newVal);
      },
    },
  },
  methods: {
    searchUserList(name) {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
        this.searchTimer = null;
      }
      this.searchTimer = setTimeout(() => {
        this.getUserList(name);
      }, 300);
    },
    getUserList(name, active) {
      this.$http.get("/user/list", { name }).then((res) => {
        if (res.result === 200) {
          this.submitterList = res.data;
          this.id = null;
          this.parentTaskId = null;
          switch (active) {
            case "1":
              this.statusSelect = "";
              this.statusList = [
                {
                  code: "STOP",
                  desc: "已停止",
                },
                {
                  code: "WAITING",
                  desc: "等待中",
                },
                {
                  code: "",
                  desc: "全部",
                },
              ];

              if (
                localStorage.getItem("timedSubmitter") !== "undefined" &&
                localStorage.getItem("timedSubmitter")
              ) {
                this.timedSubmitter = localStorage.getItem("timedSubmitter");
              }
              this.query(1);
              break;
            case "2":
              this.statusSelect = undefined;
              if (
                localStorage.getItem("timedSubTaskSubmitter") !== "undefined" &&
                localStorage.getItem("timedSubTaskSubmitter")
              ) {
                this.timedSubTaskSubmitter = localStorage.getItem(
                  "timedSubTaskSubmitter"
                );
              }
              this.getStatus();
              this.subtaskList(null, 1);
              break;
          }
        }
      });
    },
    // 查询状态
    getStatus() {
      this.$http.get("/sysPipeline/statusList").then((res) => {
        if (res.result === 200) {
          this.statusList = res.data;
        }
      });
    },
    // 密码随机
    random() {
      this.password = this.$common.random();
    },
    // 分享确定
    shareConfirm() {
      this.syncLoading = this.$syncLoading({
        message: "分享链接生成中...",
      });
      this.getFileShareLink();
    },
    getFileShareLink() {
      let data = {
        id: this.jobId,
        fileId: this.fileId,
        password: this.password,
        expireSeconds: this.expireSeconds,
        fileSpaceId: this.fileSpaceId,
        remark: this.remark,
      };
      this.innerVisible = false;
      this.$http
        .get("/pipelineTask/getFileShareLink", data)
        .then((res) => {
          if (res.result === 200) {
            if (res.data.url) {
              this.copyVisible = true;
              this.inviteCode = res.data.url;
              this.syncLoading.close();
              this.$message.success("分享成功");
              clearInterval(this.shareTimer);
              this.shareTimer = null;
            } else if (!this.shareTimer) {
              this.shareTimer = setInterval(() => {
                this.getFileShareLink();
              }, 2000);
            }
          }
        })
        .catch(() => {
          this.syncLoading.close();
        });
    },
    checkFileContent(val) {
      this.fileContent = "";
      this.excelFileList.splice(0);
      this.excelFileColumns.splice(0);
      this.fileType = val.fileName.split(".")[1];
      this.syncLoading = this.$syncLoading({
        message: "获取文件内容中...",
      });
      this.$http
        .get("/pipelineFile/checkFileContent", { fileId: val.id })
        .then((res) => {
          if (res.result == 200) {
            if (this.fileType == "xlsx" || this.fileType == "xls") {
              res.data.forEach((item, index) => {
                let obj = {
                  id: index + 1,
                };
                item.forEach((value, key) => {
                  obj["column" + (key + 1)] = value;
                });
                this.excelFileList.push(obj);
              });

              res.data[0].forEach((value, index) => {
                this.excelFileColumns.push({
                  title: "列" + (index + 1),
                  ellipsis: true,
                  dataIndex: "column" + (index + 1),
                  width: 100,
                });
              });
            } else {
              this.fileContent = res.data[0].join("\n");
            }
            this.syncLoading.close();
            this.checkFileContentShow = true;
          }
        })
        .catch(() => {
          this.syncLoading.close();
        });
    },
    copyPath(val) {
      let data = {
        id: val.id,
      };
      this.$http.get("/pipelineFile/copyFilePath", data).then((res) => {
        if (res.result === 200) {
          this.$copyText(res.data).then(
            (e) => {
              this.$message.success("复制成功");
            },
            (e) => {
              this.$message.error("复制失败");
            }
          );
        }
      });
    },
    // 点击分享
    shareClick(val) {
      this.fileId = val.id;
      this.shareUrl = val.link;
      this.innerVisible = true;
      this.jobId = val.pipelineTaskId;
      this.passwordSwitch = false;
      this.password = "";
      this.remark = "";
      this.expireSeconds = 2592000;
      this.fileSpaceId = -1;
    },
    // 关闭下载文件
    fileListClosed() {
      this.fileListTableData = [];
    },
    // 点击下载
    downloadClick(val) {
      let path = location.protocol + process.env.VUE_APP_BASE_API;
      let href = path + "/pipelineFile/download?id=" + val.id;
      window.location.href = href;
    },
    // 查看
    cook_pipeline(val) {
      this.$router.push({
        path: "/panel",
        query: {
          id: val.id,
        },
      });
    },
    getSpaceList() {
      this.$http.get("/job/getSpaceList").then((res) => {
        if (res.result === 200) {
          let list = res.data;
          this.fileSpaceList = list;
        }
      });
    },
    file_download(val) {
      this.fileListShow = true;
      this.jobId = val.id;
      this.getSpaceList();
      this.getFileListTableData(val.id, true);
    },
    // 中断执行
    stopTaskNow(val) {
      let data = {
        id: val.id,
      };
      this.$http.json_post("/pipelineTask/interruptTask", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("操作成功");
          this.subtaskList();
        }
      });
    },
    getFileListTableData(pipelineLogId, importantFile) {
      this.fileListLoadingTable = true;
      let data = {
        pipelineLogId,
        importantFile,
      };
      this.$http.get("/pipelineFile/fileList", data).then((res) => {
        if (res.result === 200) {
          this.fileListTableData = res.data;
          this.fileListTableData.map((item) => {
            if (item.lineCount == -1) {
              this.$set(item, "lineCount", "未知");
              this.$set(item, "lineCountLabel", "未知");
            } else {
              this.$set(
                item,
                "lineCountLabel",
                this.$common.formatTotalCount(item.lineCount)
              );
            }
          });
          this.fileListLoadingTable = false;
        }
      });
    },
    allowClearSubChange(e) {
      if (e.target.value) {
        return;
      }
      this.subtaskList(null, 1);
    },
    // 子任务列表
    subtaskList(val, index) {
      this.subtaskListLoadingTable = true;
      if (index) {
        this.subtaskCurrentPage = index;
      }
      if (val) {
        this.subTaskName = "";
        this.parentTaskId = val.id;
        this.id = null;
      }
      let data = {
        pageNo: this.subtaskCurrentPage,
        pageSize: 10,
        name: this.subTaskName,
        id: this.id,
        pipelineTaskType: "SCHEDULED_TASK",
      };

      if (this.parentTaskId) {
        data.parentTaskId = this.parentTaskId;
      } else {
        data.status = this.statusSelect;
        data.submitter = this.timedSubTaskSubmitter;
      }
      this.$http.get("/pipelineTask/list", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          let ids = [];
          list.forEach((item) => {
            item.gmtCreated = this.$common.transformTime(item.gmtCreated);
            if (item.status === "RUNNING" || item.status === "WAITING") {
              ids.push(item.id);
            }
            switch (item.status) {
              case "QUEUEING":
                item.statusColor = "orange";
                item.statusLabel = "排队中";
                break;
              case "RUNNING":
                item.statusColor = "orange";
                item.statusLabel = "运行中";
                break;
              case "SUCCESS":
                item.statusColor = "green";
                item.statusLabel = "执行成功";
                break;
              case "ERROR":
                item.statusColor = "red";
                item.statusLabel = "执行失败";
                break;
              case "WAITING":
                item.statusColor = "orange";
                item.statusLabel = "等待执行";
                break;
              case "CANCELED":
                item.statusColor = "orange";
                item.statusLabel = "已取消";
                break;
              case "WAITING_AUDIT":
                item.statusColor = "orange";
                item.statusLabel = "等待审核";
                break;
              case "AUDIT_FAILURE":
                item.statusColor = "red";
                item.statusLabel = "审核失败";
                break;
              case "PART_READY":
                item.statusColor = "orange";
                item.statusLabel = "部分就绪";
                break;
              case "SCHEDULED":
                item.statusColor = "orange";
                item.statusLabel = "已定时";
                break;
              case "DRAFT":
                item.statusColor = "orange";
                item.statusLabel = "草稿";
                break;
            }
          });
          this.subtaskListTableData = list;
          if (this.parentTaskId) {
            this.subtaskListShow = true;
          }
          this.subtaskListTablePagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.subtaskCurrentPage_data(current),
          };
          this.subtaskListLoadingTable = false;
        }
      });
    },
    subtaskListClosed() {
      this.subtaskListTableData = [];
      this.parentTaskId = null;
      this.subTaskName = "";
    },
    create_submit() {
      let data = {
        name: this.taskName,
        model: "BATCH",
        pipelineTaskType: "SCHEDULED_TASK_TEMPLATE",
      };
      this.$http.json_post("/pipelineTask/initBlankTask", data).then((res) => {
        this.$router.push({
          path: "/panel",
          query: {
            id: res.data.pipelineTask.id,
          },
        });
      });
    },
    generateTaskName() {
      this.$http.get("/sysPipeline/generateName").then((res) => {
        if (res.result === 200) {
          this.taskName = res.data.name;
          this.createShow = true;
        }
      });
    },
    newTimedTask() {
      this.generateTaskName();
    },
    // 点击重新创建
    recreateClick(val) {
      this.query(1);
    },
    update_cron(val) {
      this.updateId = val.id;
      let select = this.cronList.some((item) => {
        return item.value == val.cron;
      });
      if (select) {
        this.cronSelect = val.cron;
        this.cron = "";
      } else {
        this.cronSelect = "";
        this.cron = val.cron;
      }
      this.updateCronShow = true;
    },
    updateCronSubmit() {
      let data = {
        id: this.updateId,
        cron: this.cronSelect,
      };
      if (this.cronSelect == "") {
        data.cron = this.cron.trim();
      }
      this.$http.json_post("/pipelineTask/updateTaskCron", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("修改成功");
          this.updateCronShow = false;
          this.query();
        }
      });
    },
    // 修改定时任务
    update_pipeline(val) {
      this.$router.push({
        path: "/panel",
        query: {
          id: val.id,
        },
      });
    },
    // 停止定时任务
    stop_pipeline(val) {
      let data = {
        id: val.id,
        pipelineTaskStatus: "STOP",
      };
      this.$http
        .json_post("/pipelineTask/modifyPipelineCronTask", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("停止成功");
            this.query();
          }
        });
    },
    // 启动定时任务
    up_pipeline(val) {
      let data = {
        id: val.id,
        pipelineTaskStatus: "WAITING",
      };
      this.$http
        .json_post("/pipelineTask/modifyPipelineCronTask", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("启动成功");
            this.query();
          }
        });
    },
    setPipelineTaskClean(val) {
      this.taskId = val.id;
      let data = {
        taskId: this.taskId,
      };
      this.$http.get("/pipelineTask/pipelineTaskClean", data).then((res) => {
        if (res.result === 200) {
          if (res.data) {
            this.day = res.data.day;
            this.id = res.data.id;
          } else {
            this.day = undefined;
            this.id = undefined;
          }
          this.setPipelineTaskCleanShow = true;
        }
      });
    },
    setPipelineTaskCleanSubmit() {
      let data = {
        taskId: this.taskId,
        day: this.day,
        id: this.id,
      };
      this.$http
        .json_post("/pipelineTask/modifyPipelineTaskClean", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.setPipelineTaskCleanShow = false;
          }
        });
    },
    // 删除任务
    delete_pipeline(val) {
      let data = {
        id: val.id,
      };
      this.$http.json_post("/pipelineTask/delete", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 查询
    query(index) {
      this.loadingTable = true;
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: 10,
        name: this.name,
        status: this.statusSelect,
        pipelineTaskType: "SCHEDULED_TASK_TEMPLATE",
        submitter: this.timedSubmitter,
      };
      this.$http.get("/pipelineTask/list", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          list.forEach((item) => {
            item.gmtCreated = this.$common.transformTime(item.gmtCreated);
            switch (item.status) {
              case "RUNNING":
                item.statusColor = "green";
                item.statusLabel = "运行中";
                break;
              case "STOP":
                item.statusColor = "red";
                item.statusLabel = "已停止";
                break;
              case "WAITING":
                item.statusColor = "orange";
                item.statusLabel = "等待中";
                break;
            }
          });
          this.tableData = list;
          this.tablePagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.currentPage_data(current),
          };
          this.loadingTable = false;
        }
      });
    },
    // 翻页
    currentPage_data(index) {
      this.query(index);
    },
    subtaskCurrentPage_data(index) {
      this.subtaskList(null, index);
    },
  },
  beforeDestroy() {
    if (this.shareTimer) {
      clearInterval(this.shareTimer);
      this.shareTimer = null;
    }
  },
};
</script>
