<template>
  <div class="portTime">
    <a-tabs v-model="activeName">
      <a-tab-pane tab="详细数据" key="1">
        <router-view name="second" v-if="activeName == '1'"></router-view>
      </a-tab-pane>
      <a-tab-pane tab="统计数据" key="2">
        <router-view name="second" v-if="activeName == '2'"></router-view>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
export default {
  name: "portTime",
  data() {
    return {
      activeName: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/portTime/portDetail") {
          this.activeName = "1";
        } else {
          this.activeName = "2";
        }
      },
    },
    activeName: {
      handler(newVal) {
        if (newVal === "1") {
          this.$router.replace("/portTime/portDetail");
        }
        if (newVal === "2") {
          this.$router.replace("/portTime/statisticalData");
        }
      },
    },
  },
};
</script>

<style></style>
