<template>
  <div class="marketingLog">
    <div class="searchCondition" style="margin-top: 20px">
      <div class="searchConditionItem">
        <span>客户编号：</span>
        <a-input
          v-model.trim="customerCode"
          placeholder="请输入客户编号"
          allowClear
          @change="allowClearChange"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
      </div>
    </div>
    <h3>外部语音拨打记录</h3>

    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(robotTelephoneInfoTableColumns)"
      :columns="robotTelephoneInfoTableColumns"
      :dataSource="robotTelephoneInfoTableData"
      :pagination="false"
      size="small"
      :rowKey="(record) => record.id"
      :loading="robotTelephoneInfoLoading"
    >
      <span slot="callMillis" slot-scope="text, record">
        {{ record.callMillis == -1 ? "-" : record.callMillis }}
      </span>

      <span slot="callStatus" slot-scope="text, record">
        <a-tag :color="record.callStatus == 0 ? 'green' : 'red'">
          {{ record.callStatus == 0 ? "接通" : "未接通" }}
        </a-tag>
      </span>
    </a-table>

    <h3>语音拨打记录</h3>

    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(sipCallInfoTableColumns)"
      :columns="sipCallInfoTableColumns"
      :dataSource="sipCallInfoTableData"
      :pagination="false"
      size="small"
      :rowKey="(record) => record.id"
      :loading="sipCallInfoLoading"
    >
      <span slot="callMillis" slot-scope="text, record">
        {{ record.callMillis == -1 ? "-" : record.callMillis }}
      </span>
    </a-table>

    <h3>短信发送记录(近一年)</h3>

    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(smsSendInfoTableColumns)"
      :columns="smsSendInfoTableColumns"
      :dataSource="smsSendInfoTableData"
      :pagination="false"
      size="small"
      :rowKey="(record) => record.id"
      :loading="smsSendInfoLoading"
    >
      <span slot="mock" slot-scope="text, record">
        {{ record.mock ? "是" : "否" }}
      </span>
    </a-table>

    <h3>黑名单记录</h3>

    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(blackListInfoTableColumns)"
      :columns="blackListInfoTableColumns"
      :dataSource="blackListInfoTableData"
      :pagination="false"
      size="small"
      :rowKey="(record) => record.id"
      :loading="blackListInfoLoading"
    >
    </a-table>
  </div>
</template>

<script>
export default {
  name: "marketingLog",
  data() {
    return {
      robotTelephoneInfoTableColumns: [
        {
          title: "客户编号",
          ellipsis: true,
          dataIndex: "customerCode",
          align: "center",
          width: 150,
        },
        {
          title: "平台",
          ellipsis: true,
          dataIndex: "platform",
          align: "center",
          width: 150,
        },
        {
          title: "拨打时长",
          ellipsis: true,
          dataIndex: "callMillis",
          scopedSlots: { customRender: "callMillis" },
          align: "center",
          width: 150,
        },
        {
          title: "拨打时间",
          ellipsis: true,
          dataIndex: "callStartAt",
          align: "center",
          width: 170,
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "callStatus",
          scopedSlots: { customRender: "callStatus" },
          align: "center",
          width: 100,
        },
        {
          title: "意愿度",
          ellipsis: true,
          dataIndex: "willingInfo",
          align: "center",
          width: 150,
        },
        {
          title: "主叫号码",
          ellipsis: true,
          dataIndex: "prefixNumber",
          align: "center",
          width: 150,
        },
      ],
      robotTelephoneInfoTableData: [],
      sipCallInfoTableColumns: [
        {
          title: "拨打时间",
          ellipsis: true,
          dataIndex: "callStartAt",
          align: "center",
          width: 170,
        },
        {
          title: "客户编号",
          ellipsis: true,
          dataIndex: "customerCode",
          align: "center",
          width: 150,
        },
        {
          title: "批次编号",
          ellipsis: true,
          dataIndex: "batchNumber",
          align: "center",
          width: 200,
        },
        {
          title: "用户登录名",
          ellipsis: true,
          dataIndex: "userLoginName",
          align: "center",
          width: 200,
        },
        {
          title: "客户端编码",
          ellipsis: true,
          dataIndex: "clientNumber",
          align: "center",
          width: 200,
        },
        {
          title: "呼叫状态",
          ellipsis: true,
          dataIndex: "callStatus",
          align: "center",
          width: 100,
        },
        {
          title: "通话时间",
          ellipsis: true,
          dataIndex: "callMillis",
          scopedSlots: { customRender: "callMillis" },
          align: "center",
          width: 100,
        },
        {
          title: "归属地-省",
          ellipsis: true,
          dataIndex: "customerProvince",
          align: "center",
          width: 100,
        },
        {
          title: "归属地-城市",
          ellipsis: true,
          dataIndex: "customerCity",
          align: "center",
          width: 100,
        },
        {
          title: "手机运营商",
          ellipsis: true,
          dataIndex: "customerPhoneOperator",
          align: "center",
          width: 100,
        },
        {
          title: "挂机时间",
          ellipsis: true,
          dataIndex: "callEndAt",
          align: "center",
          width: 100,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 170,
        },
      ],
      sipCallInfoTableData: [],
      smsSendInfoTableColumns: [
        {
          title: "发送时间",
          ellipsis: true,
          dataIndex: "sendTime",
          align: "center",
          width: 170,
        },
        {
          title: "客户编号",
          ellipsis: true,
          dataIndex: "customerCode",
          align: "center",
          width: 150,
        },
        {
          title: "签名",
          ellipsis: true,
          dataIndex: "sign",
          align: "center",
          width: 100,
        },
        {
          title: "发送状态",
          ellipsis: true,
          dataIndex: "sendStatus",
          align: "center",
          width: 100,
        },
        {
          title: "真实状态",
          ellipsis: true,
          dataIndex: "receiveRealStatus",
          align: "center",
          width: 100,
        },
        {
          title: "模拟",
          ellipsis: true,
          dataIndex: "mock",
          scopedSlots: { customRender: "mock" },
          align: "center",
          width: 50,
        },
        {
          title: "批次名称",
          ellipsis: true,
          dataIndex: "activityCode",
          align: "center",
          width: 200,
        },
        {
          title: "运营商",
          ellipsis: true,
          dataIndex: "phoneOperator",
          align: "center",
          width: 100,
        },
        {
          title: "省份",
          ellipsis: true,
          dataIndex: "province",
          align: "center",
          width: 100,
        },
        {
          title: "城市",
          ellipsis: true,
          dataIndex: "city",
          align: "center",
          width: 100,
        },
        {
          title: "用户名",
          ellipsis: true,
          dataIndex: "userLoginName",
          align: "center",
          width: 100,
        },
      ],
      smsSendInfoTableData: [],
      blackListInfoTableColumns: [
        {
          title: "客户编号",
          ellipsis: true,
          dataIndex: "customerCode",
          align: "center",
          width: 150,
        },
        {
          title: "来源",
          ellipsis: true,
          dataIndex: "source",
          align: "center",
          width: 200,
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
          align: "center",
          width: 300,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 170,
        },
      ],
      blackListInfoTableData: [],
      robotTelephoneInfoLoading: false,
      sipCallInfoLoading: false,
      smsSendInfoLoading: false,
      blackListInfoLoading: false,
      customerCode: "",
    };
  },
  mounted() {
    this.query();
  },
  methods: {
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    query() {
      let data = {
        customerCode: this.customerCode,
      };
      this.robotTelephoneInfoLoading = true;
      this.sipCallInfoLoading = true;
      this.smsSendInfoLoading = true;
      this.blackListInfoLoading = true;
      this.getRobotTelephoneInfo(data);
      this.getSipCallInfo(data);
      this.getSmsSendInfo(data);
      this.getBlackListInfo(data);
    },
    getRobotTelephoneInfo(data) {
      this.$http.get("/customerData/robotTelephoneInfo", data).then((res) => {
        if (res.result == 200) {
          this.robotTelephoneInfoTableData = res.data;
          this.robotTelephoneInfoLoading = false;
        }
      });
    },
    getSipCallInfo(data) {
      this.$http.get("/customerData/sipCallInfo", data).then((res) => {
        if (res.result == 200) {
          this.sipCallInfoTableData = res.data;
          this.sipCallInfoLoading = false;
        }
      });
    },
    getSmsSendInfo(data) {
      this.$http.get("/customerData/smsSendInfo", data).then((res) => {
        if (res.result == 200) {
          this.smsSendInfoTableData = res.data;
          this.smsSendInfoLoading = false;
        }
      });
    },
    getBlackListInfo(data) {
      this.$http.get("/customerData/blackListInfo", data).then((res) => {
        if (res.result == 200) {
          this.blackListInfoTableData = res.data;
          this.blackListInfoLoading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.marketingLog {
  h3 {
    margin-top: 20px;
  }
}
</style>
