<template>
  <div
    class="flow-menu"
    ref="tool"
    @click.stop="panelClick"
    @contextmenu="panelClick"
    @keydown="keyCheck"
    @mousedown.stop
    @mousemove.stop
  >
    <div class="search">
      <a-input
        v-model.trim="tag"
        placeholder="请输入搜索关键字"
        @change="searchNodeInfoList"
        @focus="focus"
        @blur="blur"
        ref="search"
      />
      <div
        class="absoluteMenu"
        v-if="absoluteMenuShow"
        :style="{ borderColor }"
      >
        <div class="borderHidden"></div>
        <div class="tab">
          <div
            :class="{ tabItem: true, active: active == 1 }"
            @mouseenter="active = 1"
          >
            <img src="../../assets/icon/icons_next.png" class="iconImg" />
          </div>
          <div
            :class="{ tabItem: true, active: active == group.id + 1 }"
            v-for="group in menuList"
            :key="group.id"
            @mouseenter="active = group.id + 1"
          >
            <img :src="group.icon" class="iconImg" />
          </div>
        </div>
        <div class="tabContent">
          <div
            :class="{ ef_node_menu_ul: true, flex_url: active !== 1 }"
            ref="ef_node_menu_ul"
          >
            <template v-if="active == 1">
              <template v-if="tag && !noData">
                <template v-for="group in menuList">
                  <h5
                    v-if="group.children.length > 0"
                    style="margin: 0; color: #cbcbcb"
                  >
                    {{ group.name }}节点列表
                  </h5>
                  <div style="margin-bottom: 5px">
                    <template v-for="node in group.children">
                      <draggable
                        @end="end"
                        @start="move(node)"
                        v-model="group.children"
                        :preventOnFilter="false"
                        :sort="false"
                        :disabled="false"
                        ghostClass="tt"
                        :forceFallback="true"
                      >
                        <div
                          :class="{
                            ef_node_menu_li: true,
                            active: index == node.index,
                          }"
                          :key="node.id"
                          @dblclick="move(node, true)"
                        >
                          <div class="tagNode" :ref="'tagNode' + node.index">
                            <i
                              class="nodeIcon"
                              :style="{ backgroundImage: `url(${node.icon})` }"
                            ></i>
                            <div class="nodeName">
                              {{ node.name }}
                            </div>
                          </div>
                        </div>
                      </draggable>
                    </template>
                  </div>
                </template>
              </template>
              <div class="noData" v-else>
                <i></i>
              </div>
            </template>
            <template v-else>
              <template v-if="menuList[active - 2].children.length > 0">
                <template v-for="node in menuList[active - 2].children">
                  <draggable
                    @end="end"
                    @start="move(node)"
                    v-model="menuList[active - 2].children"
                    :preventOnFilter="false"
                    :sort="false"
                    :disabled="false"
                    ghostClass="tt"
                    :forceFallback="true"
                    class="list_group"
                  >
                    <div
                      class="ef_node_menu_li"
                      :key="node.id"
                      @dblclick="move(node, true)"
                    >
                      <div>
                        <i
                          class="nodeIcon"
                          :style="{ backgroundImage: `url(${node.icon})` }"
                        ></i>
                      </div>
                      <p class="nodeName" :title="node.name">{{ node.name }}</p>
                    </div>
                  </draggable>
                </template>
              </template>
              <div class="noData" v-else>
                <i></i>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <a-divider type="vertical" v-if="processed" />
    <template v-if="processed">
      <div
        class="node-menu-ul-item"
        v-for="node in processList"
        :key="menuList.length + node.id"
      >
        <draggable
          @end="end"
          @start="move(node)"
          v-model="processList"
          :preventOnFilter="false"
          :sort="false"
          :disabled="false"
          ghostClass="tt"
          :forceFallback="true"
        >
          <div class="ef-node-pmenu" @dblclick="move(node, true)">
            <img :src="node.icon" class="iconImg" />
          </div>
        </draggable>
      </div>
    </template>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import lodash from "lodash";

export default {
  props: {
    menuList: Array,
    processList: Array,
    processed: Boolean,
  },
  data() {
    return {
      draggableOptions: {
        preventOnFilter: false,
        sort: false,
        disabled: false,
        ghostClass: "tt",
        // 不使用H5原生的配置
        forceFallback: true,
        // 拖拽的时候样式
      },
      // 默认打开的左侧菜单的id
      menuNode: {},
      groupList: [],
      timer: null,
      tag: "",
      active: 1,
      absoluteMenuShow: false,
      borderColor: "",
      index: 0,
    };
  },
  components: {
    draggable,
  },
  computed: {
    secondGroupList() {
      return (menuType) => {
        let arr = [];
        this.groupList.forEach((item) => {
          if (menuType == item.group.split("节点列表")[0]) {
            arr = item.secondGroup;
          }
        });
        return arr;
      };
    },
    noData() {
      return this.menuList.every((item) => {
        return item.children.length == 0;
      });
    },
    dataLength() {
      let length = 0;
      this.menuList.forEach((item) => {
        length += item.children.length;
      });
      return length;
    },
  },
  watch: {
    tag: {
      handler(newVal) {
        if (newVal == "") {
          this.index = 0;
          this.$emit("getNodeInfoList");
        }
      },
    },
    active: {
      handler(newVal, oldVal) {
        this.index = 0;
        this.$refs.ef_node_menu_ul.scrollTop = 0;
        if (oldVal !== 1 && this.tag) {
          this.searchNodeInfoList();
        }
      },
    },
    absoluteMenuShow: {
      handler(newVal) {
        if (!newVal) {
          this.index = 0;
          this.tag = "";
          this.$emit("resetEnterLeftAndTop");
        }
      },
    },
  },
  created() {
    this.getGroupList();
    document.body.addEventListener("click", () => {
      this.absoluteMenuShow = false;
    });
  },
  methods: {
    keyCheck(value) {
      if (value.which == 27) {
        this.absoluteMenuShow = false;
        this.$refs.search.blur();
      }
      if (!this.noData && this.active == 1) {
        if (value.which == 38 && this.index > 1) {
          this.index--;
        }
        if (value.which == 40 && this.index < this.dataLength) {
          this.index++;
        }
        let children = this.menuList.some((item) => {
          return item.children.length > 0;
        });
        if (this.index > 0 && children) {
          let offsetTop = this.$refs["tagNode" + this.index][0].offsetTop;
          let scrollTop = this.$refs.ef_node_menu_ul.scrollTop;
          if (offsetTop > scrollTop + 400) {
            this.$refs.ef_node_menu_ul.scrollTop = offsetTop + 35 - 400;
          } else if (offsetTop <= scrollTop + 35) {
            this.$refs.ef_node_menu_ul.scrollTop -= 100 + scrollTop - offsetTop;
          } else if (this.index == 1) {
            this.$refs.ef_node_menu_ul.scrollTop = 0;
          }
          if (value.which == 13 && this.index !== 0) {
            this.menuList.forEach((group) => {
              group.children.forEach((node) => {
                if (this.index == node.index) {
                  let menuNode = lodash.cloneDeep(node);
                  this.$emit("addNode", null, menuNode);
                }
              });
            });
          }
        }
      }
    },
    focus() {
      this.absoluteMenuShow = true;
      this.borderColor = "#409EFF";
    },
    blur() {
      this.borderColor = "#DCDFE6";
    },
    searchNodeInfoList() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        let group = this.menuList.filter((menu) => {
          return this.active == menu.id + 1;
        });
        this.$emit(
          "getNodeInfoList",
          null,
          group.length > 0 && this.tag ? group[0].name + "节点列表" : null,
          this.tag
        );
      }, 200);
    },
    panelClick() {
      this.$emit("panelClick");
    },
    getGroupList() {
      this.$http.get("/pipelineNodeInfo/groupList").then((res) => {
        if (res.result === 200) {
          this.groupList = res.data;
        }
      });
    },
    // 拖拽开始时触发
    move(item, dblclick) {
      this.panelClick();
      var type = item.type;
      this.menuNode = null;
      if (type == "PROCESS_INPUT") {
        this.menuNode = this.processList[0];
      } else if (type == "PROCESS_OUTPUT") {
        this.menuNode = this.processList[1];
      } else {
        this.menuNode = item;
      }
      if (dblclick) {
        this.end();
      }
    },
    // 拖拽结束时触发
    end(evt) {
      let menuNode = lodash.cloneDeep(this.menuNode);
      this.tag = "";
      this.absoluteMenuShow = false;
      this.$emit("addNode", evt, menuNode);
    },
    // 是否是火狐浏览器
    isFirefox() {
      var userAgent = navigator.userAgent;
      if (userAgent.indexOf("Firefox") > -1) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
.triangle {
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #ffffff;
  position: absolute;
  left: 10px;
  top: 21px;
  z-index: 9999;
}

.triangleBorder {
  width: 0;
  height: 0;
  border: 11px solid transparent;
  border-bottom-color: #bcbcbc;
  position: absolute;
  left: 9px;
  top: 19px;
  z-index: 9998;
}

.search {
  position: relative;
  margin-top: 2px;
  display: inline-block;
  &:deep().ant-input {
    line-height: 32px !important;
    height: 32px !important;
    border-radius: 0 !important;
    font-size: 13px;
    width: 178px;
  }
  .absoluteMenu {
    width: 522px;
    position: absolute;
    top: 33px;
    background-color: #fff;
    border: 1px solid #e1e1e1;
    z-index: 100;
    padding: 0 10px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

    .borderHidden {
      position: absolute;
      top: -1px;
      left: 0;
      height: 2px;
      width: 176px;
      background-color: #fff;
      z-index: 101;
    }
    .tab {
      display: flex;
      flex-wrap: nowrap;
      margin-bottom: 10px;
      .tabItem {
        padding: 0 16px;
        cursor: pointer;
        &:hover {
          border-bottom: 2px solid #409eff;
        }
      }
      .active {
        border-bottom: 2px solid #409eff;
      }
    }

    .ef_node_menu_ul {
      height: 400px;
      overflow-y: scroll;

      .noData {
        width: 100%;
        height: 100%;
        text-align: center;

        i {
          background-image: url("../../assets/icon/noData.png");
          background-size: 100% 100%;
          background-position: center;
          display: inline-block;
          width: 80px;
          height: 80px;
          margin-top: 150px;
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .ef_node_menu_li {
        color: #565758;
        text-align: center;
        border-radius: 5px;
        margin: 0;
        padding: 0;
        width: 100%;

        &:hover {
          /* 设置移动样式*/
          background-color: #f0f7ff;
        }

        .nodeIcon {
          width: 20px;
          height: 20px;
        }
      }
      .tagNode {
        display: flex;
        align-items: center;
        justify-content: left;
        margin-left: 10px;
        height: 35px;

        .nodeName {
          margin-left: 10px;
          color: #4a4a4a;
        }
      }

      .active {
        background-color: #f0f7ff;
      }
    }

    .list_group {
      width: 100px;
      height: 100px;
      padding: 20px 10px 0;
      display: inline-block;
      border-radius: 5px;
      cursor: move;

      &:hover {
        /* 设置移动样式*/
        background-color: #f0f7ff;
      }

      .ef_node_menu_li {
        width: 100%;

        .nodeIcon {
          width: 24px;
          height: 24px;
        }

        .nodeName {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: center;
        }
      }
    }
  }
}
</style>
