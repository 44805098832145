<template>
  <div class="umengTaskDetail">
    <div class="searchCondition">
      <div class="searchConditionItem">
        任务类型：
        <a-select v-model="taskType" placeholder="请选择任务类型" allowClear>
          <a-select-option
            v-for="(item, index) in taskTypeList"
            :key="index"
            :value="item.key"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        状态：
        <a-select v-model="status" placeholder="请选择状态" allowClear>
          <a-select-option
            v-for="(item, index) in statusList"
            :key="index"
            :value="item.key"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="add()" icon="plus">新增任务</a-button>
      </div>
    </div>

    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableData"
      :pagination="tablePagination"
      size="small"
      :rowKey="(record) => record.id"
      :loading="loadingTable"
    >
      <span slot="statusLabel" slot-scope="text, record">
        <a-tag :color="record.statusColor">{{ record.statusLabel }}</a-tag>
      </span>
      <span slot="action" slot-scope="text, record">
        <a-popconfirm
          placement="left"
          okText="确认"
          cancelText="取消"
          @confirm="cancelTask(record)"
          :disabled="record.status !== 'SUBMITTED'"
        >
          <template slot="title"
            >确定取消任务【{{ record.taskName }}】</template
          >
          <a-button
            type="link"
            size="small"
            :disabled="record.status !== 'SUBMITTED'"
          >
            取消任务
          </a-button>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      title="新增"
      v-model="addDataShow"
      width="500px"
      :maskClosable="false"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="任务名称:">
          <a-input placeholder="请输入任务名称" v-model="addData.taskName" />
        </a-form-item>
        <a-form-item label="任务类型:">
          <a-select
            v-model="addData.taskType"
            placeholder="请选择任务类型"
            @change="getDataSetList"
          >
            <a-select-option
              v-for="(item, index) in taskTypeList"
              :key="index"
              :value="item.key"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="appId:">
          <a-select v-model="addData.appId" placeholder="请选择appId">
            <a-select-option
              v-for="(item, index) in appIdList"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="数据集:">
          <a-select
            v-model="addData.datasetIdList"
            placeholder="请选择数据集"
            :disabled="!addData.taskType"
            mode="multiple"
          >
            <a-select-option
              v-for="item in datasetList"
              :key="item.id"
              :value="item.datasetId"
            >
              {{ item.datasetName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="备注:">
          <a-textarea
            v-model="addData.remarks"
            placeholder="请输入备注"
            :rows="4"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="addDataShow = false">取消</a-button>
          <a-button type="primary" @click="add_submit">确定</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "umengTaskDetail",
  data() {
    return {
      tableColumns: [
        {
          title: "序号",
          ellipsis: true,
          dataIndex: "id",
          align: "center",
          width: 100,
        },
        {
          title: "任务类型",
          ellipsis: true,
          dataIndex: "taskTypeLabel",
          width: 80,
        },
        {
          title: "任务名称",
          ellipsis: true,
          dataIndex: "taskName",
          width: 300,
        },
        {
          title: "状态",
          dataIndex: "statusLabel",
          scopedSlots: { customRender: "statusLabel" },
          width: 150,
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remarks",
          width: 400,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 150,
        },
      ],
      tableData: [],
      tablePagination: {},
      loadingTable: false,
      currentPage: 1,
      taskType: undefined,
      status: undefined,
      taskTypeList: [],
      statusList: [],
      datasetList: [],
      codeList: ["1001"],
      addDataShow: false,
      addData: {},
      appIdList: [],
    };
  },
  async mounted() {
    await this.getStatusList();
    this.getTaskTypeList();
    this.getLoadConfig();
  },
  methods: {
    async getStatusList() {
      await this.$http.get("/umeng/taskStateList").then((res) => {
        if (res.result == 200) {
          this.statusList = res.data;
        }
      });
    },
    getTaskTypeList() {
      this.$http.get("/umeng/taskTypeList").then((res) => {
        if (res.result == 200) {
          this.taskTypeList = res.data;
          this.query();
        }
      });
    },
    getLoadConfig() {
      this.$http.get("/loadConfig", { code: "UMENG_APP_ID" }).then((res) => {
        if (res.result == 200) {
          this.appIdList = res.data.data;
        }
      });
    },
    getDataSetList(taskType) {
      let data = {
        taskType: this.taskTypeList.filter((item) => {
          return item.key == taskType;
        })[0].label,
      };
      this.$http.get("/umeng/datasetListByType", data).then((res) => {
        if (res.result == 200) {
          this.datasetList = res.data;
        }
      });
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    query(index) {
      this.loadingTable = true;
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: 10,
        taskType: this.taskType,
        status: this.status,
      };
      this.$http.get("/umeng/taskPage", data).then((res) => {
        if (res.result == 200) {
          this.tableData = res.data.records;
          this.tableData.forEach((item) => {
            let arr = this.taskTypeList.filter((taskType) => {
              return taskType.key == item.taskType;
            });
            if (arr.length > 0) {
              this.$set(item, "taskTypeLabel", arr[0].label);
            }
            this.statusList.forEach((status) => {
              if (status.key == item.status) {
                this.$set(item, "statusLabel", status.label);
                switch (status.label) {
                  case "处理中":
                    this.$set(item, "statusColor", "orange");
                    break;
                  case "已取消":
                    this.$set(item, "statusColor", "red");
                    break;
                  case "失败":
                    this.$set(item, "statusColor", "red");
                    break;
                  case "可取消批量计算任务":
                    this.$set(item, "statusColor", "orange");
                    break;
                  case "下载计算结果":
                    this.$set(item, "statusColor", "green");
                    break;
                  case "延迟":
                    this.$set(item, "statusColor", "orange");
                    break;
                  case "完成":
                    this.$set(item, "statusColor", "green");
                    break;
                }
              }
            });
          });
          this.tablePagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.currentPage_data(current),
          };
          this.loadingTable = false;
        }
      });
    },
    currentPage_data(index) {
      this.query(index);
    },
    add() {
      this.addData = {
        taskType: this.taskTypeList[0].key,
        taskName: "",
        datasetIdList: [],
        remarks: "",
        appId: undefined,
      };
      this.getDataSetList(this.addData.taskType);
      this.addDataShow = true;
    },
    add_submit() {
      let data = { ...this.addData };
      data.datasetIds = data.datasetIdList.join(",");
      delete data.datasetIdList;
      this.$http.json_post("/umeng/addTask", data).then((res) => {
        if (res.result == 200) {
          this.$message.success("新增成功");
          this.query(1);
          this.addDataShow = false;
        }
      });
    },
    cancelTask(record) {
      let data = {
        bcId: record.bcId,
      };
      this.$http.post("/umeng/cancelTask", data).then((res) => {
        if (res.result == 200) {
          this.$message.success("取消成功");
          this.query(1);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.time {
  display: inline-block;
}
.el-cascader {
  margin-bottom: 0px !important;
  &:deep() .el-cascader__search-input {
    min-width: 5px;
  }
}
.card {
  color: black;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #e4e7ed;
  display: inline-block;
  line-height: 14px;
  cursor: pointer;
}
.card:first-child {
  border-right: none;
}
.checked {
  color: #409eff;
}
</style>
