<template>
  <div class="nodeListTab">
    <a-tabs v-model="activeName">
      <a-tab-pane tab="系统节点" key="1">
        <router-view name="three" v-if="activeName == '1'"></router-view>
      </a-tab-pane>
      <a-tab-pane tab="自定义节点" key="2">
        <router-view name="three" v-if="activeName == '2'"></router-view>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
export default {
  name: "nodeListTab",
  data() {
    return {
      activeName: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/pipelineListOrigin/nodeListTab/nodeList") {
          this.activeName = "1";
        } else if (
          newVal == "/pipelineListOrigin/nodeListTab/processNodeList"
        ) {
          this.activeName = "2";
        }
      },
    },
    activeName: {
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/pipelineListOrigin/nodeListTab/nodeList")
            return;
          this.$router.replace("/pipelineListOrigin/nodeListTab/nodeList");
        } else if (newVal === "2") {
          if (
            this.$route.path ==
            "/pipelineListOrigin/nodeListTab/processNodeList"
          )
            return;
          this.$router.replace(
            "/pipelineListOrigin/nodeListTab/processNodeList"
          );
        }
      },
    },
  },
};
</script>

<style></style>
