<template>
  <div class="loadingDialog" v-if="visible">
    <div class="loading">
      <div class="download-div">
        <a-icon type="loading" style="loadingClass" />
        <span class="download-text">{{ message }} {{ time }}秒</span>
      </div>
      <div
        style="width: 100%; padding: 0 10px; text-align: center"
        v-if="progressShow"
      >
        <span> 上传进度: </span>
        <a-progress
          :percent="percent"
          style="width: 66%; margin: 10px 0 0 10px"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "mLoading",
  data() {
    return {
      message: "请稍后...",
      visible: false,
      time: 0,
      timer: null,
      percent: 0,
      progressShow: false,
    };
  },
  watch: {
    visible: {
      immediate: true,
      handler(newVal) {
        if (this.timer) {
          clearInterval(this.timer);
          this.timer = null;
        }
        if (newVal) {
          this.timer = setInterval(() => {
            this.time++;
          }, 1000);
        }
      },
    },
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
.loadingDialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
}
.loadingDialog .loading {
  width: 350px;
  height: 130px;
  border-radius: 20px;
  display: flex;
  position: fixed;
  top: 40%;
  left: 50%;
  background: #fff;
  transform: translate(-50%, -50%);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  flex-wrap: wrap;
  align-items: center;
  padding: 37px 0;
}
.download-div {
  width: 100%;
  text-align: center;
  padding: 0px 10px;
}
.download-text {
  display: inline-block;
  position: relative;
  font-size: 17px;
  width: 200px;
  margin-left: 10px;
}
.loadingClass {
  font-size: 40px;
  color: #409eff;
}
</style>
