<template>
  <div class="statisticalData">
    <div class="searchCondition">
      <div class="searchConditionItem">
        <span>接口名称：</span>
        <a-select
          v-model="methodName"
          placeholder="请选择接口"
          allowClear
          :filterOption="false"
          @search="search"
          showSearch
          @focus="onFocus"
          @change="statistical_details"
          style="width: 300px"
        >
          <a-select-option
            v-for="(item, index) in tableData"
            :key="index"
            :value="item.methodName"
          >
            {{ item.className }} - {{ item.methodName }}
          </a-select-option>
        </a-select>
      </div>
    </div>
    <div
      id="myChart"
      style="width: 90%; height: 330px; margin: 50px auto"
    ></div>
  </div>
</template>

<script>
// 引入基本模板
import * as echarts from "echarts";
// 引入折线图组件
require("echarts/lib/chart/bar");
export default {
  name: "statisticalData",
  data() {
    return {
      tableData: [],
      currentPage: 1,
      total: 0,
      methodName: "",
      className: "",
      time: [],
      statisticalDetailsShow: false,
      title: "",
      timer: null,
    };
  },
  mounted() {
    this.query();
  },
  methods: {
    onFocus() {
      this.query();
    },
    statistical_details() {
      if (this.methodName) {
        var className = "";
        this.tableData.forEach((item) => {
          if (this.methodName == item.methodName) {
            className = item.className;
          }
        });
        let data = {
          methodName: this.methodName,
          className: className,
        };
        this.$http
          .get("/monitor/methodData", data, "/apiPrefix")
          .then((res) => {
            if (res.result === 200) {
              let myChart = echarts.init(document.getElementById("myChart"));
              // 绘制图表
              myChart.setOption({
                title: {
                  text: "接口调用统计",
                },
                color: ["#91cc75", "#ee6666"],
                tooltip: {
                  trigger: "axis",
                },
                legend: {
                  data: ["总调度次数", "平均调度耗时(毫秒)"],
                },
                grid: {
                  left: "3%",
                  right: "4%",
                  bottom: "3%",
                  containLabel: true,
                },
                xAxis: {
                  type: "category",
                  boundaryGap: false,
                  data: res.data.dayList,
                },
                yAxis: [
                  {
                    type: "value",
                    name: "总调度次数",
                    splitLine: {
                      show: false,
                    },
                    nameLocation: "middle",
                    nameGap: 50,
                  },
                  {
                    type: "value",
                    name: "平均调度耗时(毫秒)",
                    splitLine: {
                      show: false,
                    },
                    nameLocation: "middle",
                    nameGap: 50,
                  },
                ],
                series: [
                  {
                    name: "总调度次数",
                    type: "line",
                    data: res.data.totalList,
                    smooth: true,
                  },
                  {
                    name: "平均调度耗时(毫秒)",
                    type: "line",
                    data: res.data.timeList,
                    yAxisIndex: 1,
                    smooth: true,
                  },
                ],
              });
            }
          });
      } else {
        this.$message.warning("请先选择接口");
      }
    },
    search(methodName) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.query(methodName);
      }, 200);
    },
    // 查询
    query(methodName) {
      let data = {
        pageNo: this.currentPage,
        pageSize: 10,
        methodName: methodName,
      };
      this.$http.get("/monitor/methodPage", data, "/apiPrefix").then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          this.tableData = list;
          this.total = res.data.total;
          if (!methodName) {
            this.methodName = this.tableData[0].methodName;
          }
          this.statistical_details();
        }
      });
    },
  },
};
</script>
