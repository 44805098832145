import axios from "axios";
import router from "@/router";
import { message } from "ant-design-vue";
const qs = require("qs");

let path = location.protocol + process.env.VUE_APP_BASE_API;
let api2 = location.protocol + process.env.VUE_APP_UPLOAD_API;

// axios基本配置
// axios.defaults.timeout = 50000
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
axios.defaults.withCredentials = true;
// http request 拦截器
axios.interceptors.request.use(
  (config) => {
    return config;
  },
  (err) => {
    // console.log("err",err);
    return Promise.reject(err);
  }
);

// http response 拦截器
axios.interceptors.response.use(
  (response) => {
    errcode(response.data.code);
    return response.data;
  },
  (error) => {
    if (error && error.response) {
      console.log("error:" + error.response.status);
      responseError(error);
    }
    return Promise.reject(error);
  }
);

//错误执行提示
const responseError = function (err) {
  switch (err.response.status) {
    case 400:
      message.error({ content: err.response.data.message, duration: 5 });
      break;
    case 401:
      // err.message = '401:未授权，请登录'
      let url = window.location.href,
        href = path + "/alpha/sso/redirect?redirectUri=" + encodeURI(url);
      window.location.href = href;
      break;
    case 403:
      // err.message = '503:拒绝访问'
      message.error("403:没有权限");
      break;
    case 404:
      // err.message = `404:请求地址出错: ${err.response.config.url}`
      message.error({ content: err.response.data.error, duration: 5 });
      break;
      break;
    case 408:
      // err.message = '408:请求超时'
      break;
    case 500:
      // err.message = '500:服务器内部错误'
      break;
    case 501:
      err.message = "501:服务未实现";
      break;
    case 502:
      err.message = "502:网关错误";
      break;
    case 503:
      err.message = "503:服务不可用";
      break;
    case 504:
      err.message = "504:网关超时";
      break;
    case 505:
      err.message = "505:HTTP版本不受支持";
      break;
    default:
  }
};

const http = {
  /**
   * post 请求方法
   * @param url
   * @param data
   * @returns {Promise}
   */
  post(url, data, url2) {
    if (url2) {
      url = api2 + url;
    } else {
      if (url.indexOf("/rz") !== -1) {
        url = path.split("/api")[0] + url;
      } else {
        url = path + url;
      }
    }
    return new Promise((resolve, reject) => {
      axios.post(url, qs.stringify(data)).then(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  /**
   * get 请求方法
   * @param url
   * @param data
   * @returns {Promise}
   */
  get(url, data, url2) {
    if (url2) {
      url = api2 + url;
    } else {
      if (url.indexOf("/rz") !== -1) {
        url = path.split("/api")[0] + url;
      } else {
        url = path + url;
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          params: data,
        })
        .then(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  },
  /**
   * post 请求方法  请求类型为application/json
   * @param url
   * @param data
   * @returns {Promise}
   */
  json_post(url, data, url2) {
    if (url2) {
      url = api2 + url;
    } else {
      if (url.indexOf("/rz") !== -1) {
        url = path.split("/api")[0] + url;
      } else {
        url = path + url;
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .post(url, data, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        })
        .then(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  },
  blobDownload(url, data) {
    url = path + url;
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          params: data,
          responseType: "blob",
        })
        .then(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  },
  files(url, params, onUploadProgress) {
    url = path + url;
    var data = new FormData();
    for (var key in params) {
      data.append(key, params[key]);
    }
    return new Promise((resolve, reject) => {
      axios
        .post(url, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress,
        })
        .then(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  },
};

function errcode(code) {
  if (code == 2000) {
    message.error("登录过期,请重新登录!");
    router.push({
      path: "/",
    });
  }
}

export default http;
