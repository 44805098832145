<template>
  <div class="userList">
    <div class="searchCondition" style="margin-top: 20px">
      <div class="searchConditionItem">
        <span>应用：</span>
        <a-input
          v-model.trim="applicationSearch"
          placeholder="请输入应用"
          allowClear
          @change="allowClearChange"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchConditionItem">
        <span>埋点：</span>
        <a-input
          v-model.trim="keySearch"
          placeholder="请输入埋点"
          allowClear
          @change="allowClearChange"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchConditionItem">
        <span>埋点值：</span>
        <a-input
          v-model.trim="valueSearch"
          placeholder="请输入埋点值"
          allowClear
          @change="allowClearChange"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchConditionItem">
        <span>创建时间：</span>
        <a-range-picker
          v-model="time"
          value-format="YYYY-MM-DD"
          format="YYYY-MM-DD"
          style="width: 250px"
        ></a-range-picker>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
      </div>
    </div>

    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableData"
      :pagination="tablePagination"
      size="small"
      :rowKey="(record) => record.id"
      :loading="loadingTable"
    >
    </a-table>
  </div>
</template>

<script>
export default {
  name: "buriedLog",
  data() {
    return {
      tableColumns: [
        {
          title: "序号",
          ellipsis: true,
          dataIndex: "id",
          align: "center",
          width: 100,
        },
        {
          title: "应用",
          ellipsis: true,
          dataIndex: "application",
          width: 200,
        },
        {
          title: "埋点",
          ellipsis: true,
          dataIndex: "key",
          width: 100,
        },
        {
          title: "埋点值",
          ellipsis: true,
          dataIndex: "value",
          width: 100,
        },
        {
          title: "网页",
          ellipsis: true,
          dataIndex: "referer",
          width: 200,
        },
        {
          title: "uvCookie",
          ellipsis: true,
          dataIndex: "uvCookie",
          width: 200,
        },
        {
          title: "ip",
          ellipsis: true,
          dataIndex: "ip",
          width: 200,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 170,
        },
      ],
      tableData: [],
      loadingTable: false,
      tablePagination: {},
      currentPage: 1,
      applicationSearch: "",
      keySearch: "",
      valueSearch: "",
      time: [],
    };
  },
  mounted() {
    this.query(1);
  },
  methods: {
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 查询
    query(index) {
      this.loadingTable = true;
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: 10,
        application: this.applicationSearch,
        key: this.keySearch,
        value: this.valueSearch,
      };
      if (this.time !== null) {
        data.startTime = this.time[0];
        data.endTime = this.time[1];
      }
      this.getUserList(data);
    },
    // 查询用户列表
    getUserList(data) {
      this.$http
        .get("/trackingEventLog/trackingEventLogPage", data)
        .then((res) => {
          if (res.result === 200) {
            this.tableData = res.data.records;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.currentPage_data(current),
            };
            this.loadingTable = false;
          }
        });
    },
    // 翻页
    currentPage_data(index) {
      this.query(index);
    },
  },
};
</script>
