<template>
  <div class="flowHelp">
    <a-tabs tab-position="left" class="tab-help">
      <a-tab-pane key="1" tab="文件读取">
        <a-divider orientation="left">fileLoad</a-divider>
        <div>fileLoad</div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="数据库读取">
        <a-divider orientation="left">databaseLoad</a-divider>
        <div>
          select DATE_FORMAT(date_sub(curdate(),interval 1 day),'%Y-%m-%d
          18:01:00') 获取当前日期前一天的某个时间点
        </div>
        <div>
          select DATE_FORMAT(curdate(),'%Y-%m-%d 18:01:00')
          获当前日期的某个时间点
        </div>
      </a-tab-pane>
      <a-tab-pane key="3" tab="号码生成">
        <a-divider orientation="left">randomPhone</a-divider>
        <div>randomPhone</div>
      </a-tab-pane>
      <a-tab-pane key="4" tab="文件排序">
        <a-divider orientation="left">sort-顺序</a-divider>
        <div>
          <img src="../../../public/img/sort.png" alt="" />
        </div>
        <a-divider orientation="left">sort-倒序</a-divider>
        <div>
          <img src="../../../public/img/sort_r.png" alt="" />
        </div>
        <a-divider orientation="left">sort-去重</a-divider>
        <div>
          <img src="../../../public/img/sort_u.png" alt="" />
        </div>
      </a-tab-pane>
      <a-tab-pane key="5" tab="文件压缩">
        <a-divider orientation="left">zip</a-divider>
        <div>zip</div>
      </a-tab-pane>
      <a-tab-pane key="6" tab="文件解压">
        <a-divider orientation="left">unzip</a-divider>
        <div>unzip</div>
      </a-tab-pane>
      <a-tab-pane key="7" tab="内容替换">
        <a-divider orientation="left">replace</a-divider>
        <div>Replace</div>
      </a-tab-pane>
      <a-tab-pane key="8" tab="文件乱序">
        <a-divider orientation="left">shuf</a-divider>
        <div>Shuf</div>
      </a-tab-pane>
      <a-tab-pane key="9" tab="内容截取">
        <a-divider orientation="left">cut</a-divider>
        <div>内容截取</div>
      </a-tab-pane>
      <a-tab-pane key="10" tab="md5加密">
        <a-divider orientation="left">phone2md5</a-divider>
        <div>
          <img src="../../../public/img/md5.png" alt="" />
        </div>
      </a-tab-pane>
      <a-tab-pane key="11" tab="长度范围">
        <a-divider orientation="left">lengthFilter</a-divider>
        <div>[1-22][23-33][34-99]</div>
      </a-tab-pane>
      <a-tab-pane key="12" tab="内容过滤">
        <a-divider orientation="left">contentFilter</a-divider>
        <div>内容过滤</div>
      </a-tab-pane>
      <a-tab-pane key="13" tab="文件合并">
        <a-divider orientation="left">merge</a-divider>
        <div>
          <img src="../../../public/img/merge.png" alt="" />
        </div>
      </a-tab-pane>
      <a-tab-pane key="14" tab="文件取交集">
        <a-divider orientation="left">intersection</a-divider>
        <div>
          <img src="../../../public/img/intersection.png" alt="" />
        </div>
      </a-tab-pane>
      <a-tab-pane key="15" tab="文件去重">
        <a-divider orientation="left">diff</a-divider>
        <div>diff</div>
      </a-tab-pane>
      <a-tab-pane key="16" tab="优先级去重">
        <a-divider orientation="left">priorityDiff</a-divider>
        <div>
          1,2,3 三个文件做优先级去重, 先保留1号文件，
          再从2号文件剔除1号已包含的文件，
          再从3号文件剔除1号或2号已包含的文件，依次类推。序号越小，优先级越高
        </div>
      </a-tab-pane>
      <a-tab-pane key="17" tab="md52Phone">
        <a-divider orientation="left">md52Phone</a-divider>
        <div>
          <img src="../../../public/img/md52phone.png" alt="" />
        </div>
      </a-tab-pane>
      <a-tab-pane key="18" tab="customerCode2Phone">
        <a-divider orientation="left">customerCode2Phone</a-divider>
        <div>
          <img src="../../../public/img/customerCode2phone.png" alt="" />
        </div>
      </a-tab-pane>
      <a-tab-pane key="19" tab="phone2CustomerCode">
        <a-divider orientation="left">phone2CustomerCode</a-divider>
        <div>
          <img src="../../../public/img/phone2customerCode.png" alt="" />
        </div>
      </a-tab-pane>
      <a-tab-pane key="20" tab="随机取数">
        <a-divider orientation="left">shufSelector</a-divider>
        <div>ShufSelector</div>
      </a-tab-pane>
      <a-tab-pane key="21" tab="phone2Guishudi">
        <a-divider orientation="left">phone2Guishudi</a-divider>
        <div>phone2Guishudi</div>
      </a-tab-pane>
      <a-tab-pane key="22" tab="复制输出">
        <a-divider orientation="left">parallel</a-divider>
        <div>parallel</div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
export default {};
</script>

<style>
.flowHelp {
  height: 600px;
}
.flowHelp .tab-help {
  height: 600px;
}
</style>
