<template>
  <div class="umengDataSet">
    <div class="searchCondition">
      <div class="searchConditionItem">
        数据集类型：
        <a-select v-model="datasetType" placeholder="请选择任务类型" allowClear>
          <a-select-option
            v-for="(item, index) in datasetTypeList"
            :key="index"
            :value="item.key"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        <span>数据集名称：</span>
        <a-input
          v-model.trim="datasetName"
          placeholder="请输入数据集名称"
          allowClear
          @change="allowClearChange"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="add()">新增数据集</a-button>
      </div>
    </div>

    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableData"
      :pagination="tablePagination"
      size="small"
      :rowKey="(record) => record.id"
      :loading="loadingTable"
    >
      <span slot="action" slot-scope="text, record">
        <a-button type="link" size="small" @click="getFileTableData(1, record)"
          >文件列表</a-button
        >
        <a-divider type="vertical" />
        <a-popconfirm
          placement="left"
          okText="确认"
          cancelText="取消"
          @confirm="uploadDataset(record)"
          :disabled="record.datasetId !== null && record.datasetId !== '0'"
        >
          <template slot="title"
            >确定上传数据集{{ record.datasetName }}</template
          >
          <a-button
            type="link"
            size="small"
            :disabled="record.datasetId !== null && record.datasetId !== '0'"
            :loading="record.datasetId == 0"
          >
            上传数据集
          </a-button>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      title="新增"
      v-model="addDataShow"
      width="500px"
      :maskClosable="false"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="数据集名称:">
          <a-input
            placeholder="请输入数据集名称"
            v-model="addData.datasetName"
          />
        </a-form-item>
        <a-form-item label="数据集类型:">
          <a-select
            v-model="addData.datasetType"
            placeholder="请选择数据集类型"
          >
            <a-select-option
              v-for="(item, index) in datasetTypeList"
              :key="index"
              :value="item.key"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="回溯日期:" v-if="addData.datasetType == 'DATE'">
          <a-date-picker
            v-model="addData.historyDate"
            placeholder="选择回溯日期"
            value-format="YYYY-MM-DD"
            format="YYYY-MM-DD"
            :disabledDate="disabledDate"
            :allowClear="false"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="addDataShow = false">取消</a-button>
          <a-button type="primary" @click="add_submit">确定</a-button>
        </div>
      </template>
    </a-modal>
    <!-- 文件列表 -->
    <a-modal
      title="文件列表"
      v-model="datasetDetailShow"
      width="1000px"
      :maskClosable="false"
    >
      <div class="searchCondition">
        <div class="searchButton">
          <a-button type="primary" @click="getFileTableData(1)" icon="search"
            >查询</a-button
          >
          <a-button
            type="primary"
            @click="upload()"
            :disabled="uploadData.datasetId !== null"
            >上传文件</a-button
          >
        </div>
      </div>
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(fileTableColumns)"
        :columns="fileTableColumns"
        :dataSource="fileTableData"
        :pagination="fileTablePagination"
        size="small"
        :rowKey="(record) => record.id"
        :loading="loadingFileTable"
      >
        <span slot="action" slot-scope="text, record">
          <a-popconfirm
            placement="left"
            okText="确认"
            cancelText="取消"
            @confirm="delete_file(record)"
            :disabled="uploadData.datasetId !== null"
          >
            <template slot="title">确认是否删除{{ record.name }}</template>
            <a-button
              type="link"
              size="small"
              style="color: #ff4d4f"
              :disabled="uploadData.datasetId !== null"
            >
              删除
            </a-button>
          </a-popconfirm>
        </span>
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="datasetDetailShow = false">关闭</a-button>
        </div>
      </template>
    </a-modal>
    <!-- 上传文件 -->
    <a-modal
      title="上传文件"
      v-model="uploadShow"
      width="600px"
      :maskClosable="false"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="类型:" required>
          <a-select v-model="uploadType" placeholder="请选择类型">
            <a-select-option
              v-for="(item, index) in fileLoadList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="上传文件:" required v-if="uploadType == 'UPLOAD'">
          <a-upload-dragger
            :fileList="fileList"
            :remove="handleRemove"
            :beforeUpload="beforeUpload"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox" />
            </p>
            <p class="ant-upload-text">单击或拖动文件到此区域进行上传</p>
            <p class="ant-upload-hint">只支持单个上传</p>
          </a-upload-dragger>
        </a-form-item>
        <a-form-item label="本地文件路径:" required v-else>
          <a-input v-model.trim="path" placeholder="请输入本地文件路径" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="uploadShow = false">取消</a-button>
          <a-button type="primary" @click="upload_submit" :loading="loading"
            >提交</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "umengDataSet",
  data() {
    return {
      tableColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 90,
          align: "center",
        },
        {
          title: "数据集类型",
          ellipsis: true,
          dataIndex: "datasetTypeLabel",
          width: 100,
          align: "center",
        },
        {
          title: "数据集名称",
          ellipsis: true,
          dataIndex: "datasetName",
          width: 400,
        },
        {
          title: "回溯日期",
          ellipsis: true,
          dataIndex: "historyDate",
          align: "center",
          width: 150,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 250,
        },
      ],
      tableData: [],
      tablePagination: {},
      loadingTable: false,
      pageNo: 1,
      datasetName: "",
      datasetType: undefined,
      datasetTypeList: [],
      addData: {},
      addDataShow: false,
      datasetDetailShow: false,
      fileTableColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 100,
          align: "center",
        },
        {
          title: "文件路径",
          ellipsis: true,
          dataIndex: "path",
          width: 550,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 100,
        },
      ],
      fileTableData: [],
      fileTablePagination: {},
      filePageNo: 1,
      loadingFileTable: false,
      uploadData: {},
      uploadShow: false,
      fileList: [],
      uploadType: "LOCAL_FILE",
      path: "",
      loading: false,
      dataSetLoading: false,
      fileLoadList: [
        {
          label: "文件上传",
          value: "UPLOAD",
        },
        {
          label: "本地文件",
          value: "LOCAL_FILE",
        },
      ],
      timer: null,
    };
  },
  created() {
    this.getDatasetTypeList();
  },
  methods: {
    getDatasetTypeList() {
      this.$http.get("/umeng/datasetTypeList").then((res) => {
        if (res.result == 200) {
          this.datasetTypeList = res.data;
          this.query();
        }
      });
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    query(index) {
      this.loadingTable = true;
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        datasetName: this.datasetName,
      };
      this.$http.get("/umeng/datasetPage", data).then((res) => {
        if (res.result == 200) {
          this.tableData = res.data.records;
          this.tableData.forEach((item) => {
            let arr = this.datasetTypeList.filter((datasetType) => {
              return datasetType.key == item.datasetType;
            });
            if (arr.length > 0) {
              this.$set(item, "datasetTypeLabel", arr[0].label);
            }
          });
          let uploadArr = this.tableData.filter((item) => {
            return item.datasetId == "0";
          });
          if (uploadArr.length > 0 && !this.timer) {
            this.timer = setInterval(() => {
              this.query();
            }, 2000);
          }

          if (uploadArr.length == 0 && this.timer !== null) {
            clearInterval(this.timer);
            this.timer = null;
          }
          this.tablePagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: res.data.pageSize,
            current: res.data.pageNo,
            total: res.data.total,
            onChange: (current) => this.currentPage_data(current),
          };
          this.loadingTable = false;
        }
      });
    },
    currentPage_data(index) {
      this.query(index);
    },
    add() {
      this.addData = {
        datasetType: this.datasetTypeList[0].key,
        datasetName: "",
        historyDate: moment().subtract(3, "days").format("YYYY-MM-DD"),
      };
      this.addDataShow = true;
    },
    disabledDate(current) {
      const currentDate = new Date(); // 获取当前日期
      const minDate = new Date(2020, 5, 1); // 2020年6月1日
      const maxDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - 3
      ); // 当前时间的四天前

      // 如果当前日期早于最小日期或晚于最大日期，则禁用
      return current < minDate || current > maxDate;
    },
    add_submit() {
      let data = { ...this.addData };
      if (data.datasetType !== "DATE") {
        delete data.historyDate;
      }
      this.$http.json_post("/umeng/addDataset", data).then((res) => {
        if (res.result == 200) {
          this.$message.success("新增成功");
          this.query(1);
          this.addDataShow = false;
        }
      });
    },
    allowClearFileChange(e) {
      if (e.target.value) {
        return;
      }
      this.getFileTableData(1);
    },
    getFileTableData(index, record) {
      this.loadingFileTable = true;
      if (record) {
        this.uploadData = {
          id: record.id,
          datasetId: record.datasetId,
        };
      }
      if (index) {
        this.filePageNo = index;
      }
      let data = {
        pageNo: this.filePageNo,
        pageSize: 10,
        datasetId: this.uploadData.id,
      };
      this.$http.get("/umeng/filePage", data).then((res) => {
        if (res.result == 200) {
          this.fileTableData = res.data.records;
          this.fileTablePagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: res.data.pageSize,
            current: res.data.pageNo,
            total: res.data.total,
            onChange: (current) => this.currentFilePage_data(current),
          };
          this.loadingFileTable = false;
          this.datasetDetailShow = true;
        }
      });
    },
    currentFilePage_data(index) {
      this.getFileTableData(index);
    },
    upload() {
      this.fileList.splice(0);
      this.uploadType = "LOCAL_FILE";
      this.path = "";
      this.uploadShow = true;
    },
    // 上传文件数据处理
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      this.fileList = this.fileList.slice(-1);
      return false;
    },
    upload_submit() {
      this.loading = true;
      if (this.fileList.length == 0 && this.uploadType == "UPLOAD") {
        this.$message.success({
          type: "info",
          message: "请先上传附件！",
        });
        return;
      }

      let data = {
        uploadType: this.uploadType,
        datasetId: this.uploadData.id,
      };
      if (data.uploadType == "UPLOAD") {
        data.file = this.fileList[0];
      } else {
        data.path = this.path;
      }

      this.$http.files("/umeng/fileUpload", data).then((res) => {
        if (res.result == 200) {
          this.uploadShow = false;
          this.getFileTableData(1);
          this.$message.success("上传成功");
          this.loading = false;
        }
      });
    },
    delete_file(record) {
      let data = {
        id: record.id,
      };
      this.$http
        .json_post("/umeng/deleteUmengDatasetFile", data)
        .then((res) => {
          if (res.result == 200) {
            this.getFileTableData();
            this.$message.success("删除成功");
          }
        });
    },
    uploadDataset(record) {
      this.dataSetLoading = true;
      let data = {
        id: record.id,
      };
      this.$http.json_post("/umeng/uploadDataset", data).then((res) => {
        if (res.result == 200) {
          this.query();
          this.$message.success("上传成功");
          this.dataSetLoading = false;
        }
      });
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
};
</script>

<style lang="scss" scoped>
.el-cascader {
  margin-bottom: 0px !important;
  &:deep() .el-cascader__search-input {
    min-width: 5px;
  }
}
</style>
