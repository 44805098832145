<template>
  <div class="formTaskTab">
    <a-tabs v-model="activeName">
      <a-tab-pane key="1" tab="单任务表单">
        <router-view
          name="second"
          type="single_task_form"
          v-if="activeName == '1'"
        ></router-view>
      </a-tab-pane>
      <a-tab-pane key="2" tab="批量任务表单">
        <router-view
          name="second"
          type="multi_task_form"
          v-if="activeName == '2'"
        ></router-view>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
export default {
  name: "formTaskTab",
  data() {
    return {
      activeName: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/formTask/singleFormTaskTab") {
          this.activeName = "1";
        } else if (newVal == "/formTask/multipleFormTaskTab") {
          this.activeName = "2";
        }
      },
    },
    activeName: {
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/formTask/singleFormTaskTab") return;
          this.$router.replace("/formTask/singleFormTaskTab");
        }
        if (newVal === "2") {
          if (this.$route.path == "/formTask/multipleFormTaskTab") return;
          this.$router.replace("/formTask/multipleFormTaskTab");
        }
      },
    },
  },
};
</script>

<style></style>
