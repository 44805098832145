<template>
  <div class="taskList">
    <a-tabs v-model="activeName">
      <a-tab-pane key="1" tab="人工任务">
        <router-view name="second" v-if="activeName == '1'"></router-view>
      </a-tab-pane>
      <a-tab-pane key="2" tab="API任务">
        <router-view name="second" v-if="activeName == '2'"></router-view>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
export default {
  name: "taskList",
  data() {
    return {
      activeName: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/taskList/etaTaskList") {
          this.activeName = "1";
        } else if (newVal == "/taskList/etaApiTaskList") {
          this.activeName = "2";
        }
      },
    },
    activeName: {
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/taskList/etaTaskList") return;
          this.$router.replace("/taskList/etaTaskList");
        }
        if (newVal === "2") {
          if (this.$route.path == "/taskList/etaApiTaskList") return;
          this.$router.replace("/taskList/etaApiTaskList");
        }
      },
    },
  },
};
</script>

<style></style>
