import Vue from "vue";
import mLoading from "../pages/mLoading.vue";

const LoadingConstructor = Vue.extend(mLoading);

LoadingConstructor.prototype.close = function () {
  this.visible = false;
  this.message = "";
  this.progressShow = false;
  this.percent = 0;
};

LoadingConstructor.prototype.update = function ({ message, percent }) {
  if (message) {
    this.message = message;
  }
  if (percent || percent == 0) {
    this.percent = percent;
  }
};

const syncLoading = (options) => {
  let instance = new LoadingConstructor({
    el: document.createElement("div"),
    data: options,
  });

  document.body.appendChild(instance.$el);
  Vue.nextTick(() => {
    instance.visible = true;
  });

  return instance;
};

export default {
  install() {
    if (!Vue.$syncLoading) {
      Vue.$syncLoading = syncLoading;
    }
    Vue.mixin({
      created() {
        this.$syncLoading = Vue.$syncLoading;
      },
    });
  },
};
