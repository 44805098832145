<template>
  <div class="portCall">
    <div class="searchCondition" style="margin-top: 20px">
      <div class="searchConditionItem">
        <span>接口：</span>
        <a-input
          v-model.trim="api"
          placeholder="请输入接口"
          allowClear
          @change="allowClearChange"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchConditionItem">
        <span>accessKey：</span>
        <a-input
          v-model.trim="accessKey"
          placeholder="请输入accessKey"
          allowClear
          @change="allowClearChange"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchConditionItem">
        <span>创建时间：</span>
        <a-range-picker
          v-model="time"
          value-format="YYYY-MM-DD"
          format="YYYY-MM-DD"
          style="width: 370px"
        ></a-range-picker>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
      </div>
    </div>

    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableData"
      :pagination="false"
      size="small"
      :loading="loading"
      :rowKey="(record) => record.id"
    >
    </a-table>
    <my-pagination
      :tableList="tableData"
      @queryTotal="query(null, true)"
      @queryOne="query(1)"
      @currentPageNo="currentPageNo"
      @currentPageSize="currentPageSize"
      class="myPagination"
    ></my-pagination>
  </div>
</template>

<script>
import myPagination from "../pages/myPagination.vue";

export default {
  name: "portCall",
  components: {
    myPagination,
  },
  data() {
    return {
      tableColumns: [
        {
          title: "序号",
          ellipsis: true,
          dataIndex: "id",
          width: 120,
        },
        {
          title: "接口",
          ellipsis: true,
          dataIndex: "api",
          width: 200,
        },
        {
          title: "accessKey",
          ellipsis: true,
          dataIndex: "accessKey",
          width: 200,
        },
        {
          title: "参数",
          ellipsis: true,
          dataIndex: "param",
          width: 200,
        },
        {
          title: "结果",
          ellipsis: true,
          dataIndex: "result",
          align: "center",
          width: 50,
        },
        {
          title: "ip",
          ellipsis: true,
          dataIndex: "ip",
          align: "center",
          width: 150,
        },
      ],
      tableData: [],
      currentPage: 1,
      api: "",
      accessKey: "",
      time: [],
      loading: false,
      pageSize: 10,
    };
  },
  mounted() {
    this.query(1);
  },
  methods: {
    currentPageNo(pageNo) {
      this.query(pageNo);
    },
    currentPageSize(pageSize) {
      this.pageSize = Number(pageSize);
      this.query(1);
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 查询
    query(index, queryCount) {
      if (index) {
        this.currentPage = index;
      }
      this.loading = true;
      let data = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        api: this.api,
        accessKey: this.accessKey,
      };
      if (this.time.length > 0) {
        data.startTime = this.time[0] ? this.time[0] + " 00:00:00" : null;
        data.endTime = this.time[1] ? this.time[1] + " 23:59:59" : null;
      }
      if (queryCount) {
        this.getUserList(data, queryCount);
      } else {
        this.getUserList(data, false);
      }
    },
    // 查询用户列表
    // ""
    getUserList(data, queryCount) {
      data.queryCount = queryCount;
      this.$http.get("/api/log/page", data, "/apiPrefix").then((res) => {
        if (res.result === 200) {
          if (queryCount) {
            this.$message.success(`共${res.data.total}条数据`);
          } else {
            let list = res.data.records;
            list.forEach((item) => {
              item.gmtCreated = this.$common.transformTime(item.gmtCreated);
            });
            this.tableData = list;
          }
          this.loading = false;
        }
      });
    },
    // 翻页
    currentPage_data(index) {
      this.query(index);
    },
  },
};
</script>
<style scoped>
.myPagination {
  float: right;
  text-align: right;
  margin: 20px 0;
}
</style>
