<template>
  <div class="fiveOneTalkFetchData">
    <div class="searchCondition" style="margin-top: 20px">
      <div class="searchConditionItem">
        <span>app：</span>
        <a-input
          v-model.trim="app"
          placeholder="请输入app"
          allowClear
          @change="allowClearChange"
          @pressEnter="query()"
        />
      </div>
      <div class="searchConditionItem">
        <span>日期：</span>
        <a-range-picker
          v-model="date"
          value-format="YYYY-MM-DD"
          format="YYYY-MM-DD"
          style="width: 250px"
        ></a-range-picker>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query()" icon="search">查询</a-button>
        <a-button type="primary" @click="reset()" icon="reload">重置</a-button>
        <a-button
          type="primary"
          @click="synchronization()"
          :loading="loading"
          icon="sync"
          >同步</a-button
        >
      </div>
    </div>
    <div class="flex">
      <div class="table">
        <a-table
          :rowClassName="$common.rowClassColor"
          bordered
          :components="$common.getTitle(tableColumns)"
          :scroll="{
            x: true,
            y: tableHeight,
          }"
          :columns="tableColumns"
          :dataSource="tableData"
          :pagination="false"
          size="small"
          :loading="loadingTable"
          :rowKey="(record) => record.id"
        >
          <span slot="number" slot-scope="text, record">
            {{ record.number - record.fetchData }}
          </span>
          <span slot="fetchData" slot-scope="text, record">
            <a-input
              placeholder="请输入取数值"
              v-model="record.fetchData"
              @change="fetchDataChange(record)"
            />
          </span>
        </a-table>
        <div style="text-align: right; margin-top: 10px; font-weight: 500">
          总量:{{ count }}
        </div>
      </div>
      <div class="form">
        <a-textarea
          :rows="15"
          style="width: 90%; margin: auto; display: block"
          v-model="fetchDataText"
          disabled
        />
        <a-button
          type="primary"
          style="margin: 20px auto; display: block; width: 100px"
          v-copy="fetchDataText"
          >复制</a-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "fiveOneTalkFetchData",
  data() {
    return {
      tableColumns: [
        {
          title: "日期",
          ellipsis: true,
          dataIndex: "date",
          align: "center",
          width: 120,
        },
        {
          title: "app",
          ellipsis: true,
          dataIndex: "app",
          width: 200,
        },
        {
          title: "数量",
          ellipsis: true,
          dataIndex: "number",
          scopedSlots: { customRender: "number" },
          align: "center",
          width: 100,
        },
        {
          title: "取数",
          ellipsis: true,
          dataIndex: "fetchData",
          scopedSlots: { customRender: "fetchData" },
          align: "center",
        },
      ],
      tableData: [],
      date: [],
      app: "",
      fetchDataText: "",
      tableHeight: 0,
      loading: false,
      loadingTable: false,
      count: 0,
    };
  },
  created() {
    this.query();
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight * 0.8;
    });
  },
  methods: {
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query();
    },
    query() {
      this.loadingTable = true;
      let data = {
        app: this.app,
      };
      if (this.date) {
        data.startDate = this.date[0];
        data.endDate = this.date[1];
      }
      this.$http.get("/logicView13/list", data).then((res) => {
        if (res.result == 200) {
          this.tableData = res.data;
          this.tableData.forEach((item) => {
            this.$set(item, "fetchData", "0");
          });
          let arr = JSON.parse(sessionStorage.getItem("fetchDataList"));
          if (arr) {
            if (arr.length > 0) {
              arr.forEach((i) => {
                this.tableData.forEach((j) => {
                  if (i.id == j.id) {
                    this.$set(j, "fetchData", i.fetchData);
                  }
                });
              });
            }
          }
          this.loadingTable = false;
          this.getFetchDataText();
        }
      });
    },
    reset() {
      sessionStorage.removeItem("fetchDataList");
      this.query();
    },
    synchronization() {
      this.loading = true;
      this.$http
        .get("/logicView13/syn")
        .then((res) => {
          this.loading = false;
          if (res.result == 200) {
            this.$message.success("同步成功");
            this.query();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    fetchDataChange(val) {
      if (val.fetchData) {
        if (!/^\d+$/.test(val.fetchData)) {
          this.$message.warning("请输入数字");
          this.$set(val, "fetchData", "0");
          return;
        }
        if (val.number < val.fetchData * 1) {
          this.$message.warning("取数值不能大于数量");
          this.$set(val, "fetchData", val.number);
        }
      }
      this.getFetchDataText();
    },
    getFetchDataText() {
      let arr = this.tableData.filter((item) => {
        return item.fetchData && item.fetchData !== 0 && item.fetchData !== "0";
      });
      this.fetchDataText = "";
      this.count = 0;
      arr.forEach((item) => {
        this.fetchDataText +=
          item.app + "," + item.fetchData + "," + item.date + "\n";
        this.count += item.fetchData * 1;
      });
      this.fetchDataText = this.fetchDataText.substr(
        0,
        this.fetchDataText.length - 1
      );
      sessionStorage.setItem("fetchDataList", JSON.stringify(arr));
    },
  },
};
</script>

<style lang="scss" scoped>
.fiveOneTalkFetchData {
  padding-bottom: 20px;
  .flex {
    display: flex;
    flex-wrap: nowrap;
    .table {
      width: 50%;
    }
    .form {
      width: 50%;
    }
  }
}
</style>
