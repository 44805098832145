<template>
  <div class="tdFetchData">
    <div class="reminder" v-if="model == 'AI00166'">
      MD5(oaid原文),oaidmd5 <br />
      imei,imeimd5
    </div>
    <div class="searchCondition">
      <div class="searchConditionItem">
        <span>批次名称：</span>
        <a-input
          v-model.trim="batchName"
          placeholder="请输入名称"
          allowClear
          @change="allowClearChange"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchConditionItem">
        <span>任务状态：</span>
        <a-select
          v-model="state"
          placeholder="请选择任务状态"
          allowClear
          @change="query(1)"
        >
          <a-select-option
            v-for="item in stateList"
            :key="item.code"
            :value="item.code"
            >{{ item.desc }}</a-select-option
          >
        </a-select>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="add" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span
        slot="batchName"
        slot-scope="text, record"
        style="display: flex; width: 100%"
      >
        <template v-if="record.batchNameShow">
          <a-input
            v-model.trim="record.batchName"
            placeholder="请输入名称"
            style="width: 200px"
          />
          <a-button
            type="link"
            class="button"
            style="padding: 0; margin-left: 5px"
            @click.stop="editBatchNameSubmit(record)"
          >
            <a-icon type="check" />
          </a-button>
          <a-button
            type="link"
            class="button"
            style="padding: 0; margin-left: 5px"
            @click.stop="unEditBatchName(record)"
          >
            <a-icon type="close" />
          </a-button>
        </template>
        <template v-else>
          <div style="line-height: 32px">
            {{ text }}
          </div>
          <a-button
            type="link"
            class="button"
            @click.stop="editBatchName(record)"
            style="padding: 0; margin-left: auto"
          >
            <a-icon type="edit" />
          </a-button>
        </template>
      </span>
      <span slot="stateDesc" slot-scope="text, record">
        <a-tag :color="record.stateColor">{{ record.stateDesc }}</a-tag>
        <a-tooltip placement="left" v-if="record.stateDesc == '失败'">
          <div slot="title">{{ record.errorMsg }}</div>
          <a-icon
            type="info-circle"
            v-if="record.stateDesc == '失败'"
            style="margin-left: 5px"
          />
        </a-tooltip>
      </span>
      <span slot="action" slot-scope="text, record">
        <a-button
          type="link"
          size="small"
          @click="updateState(record)"
          :disabled="record.state !== 1 && record.state !== 2"
          >刷新状态</a-button
        >
        <a-divider type="vertical" />
        <a-button type="link" size="small" @click="copy(record)"
          >重新创建</a-button
        >
        <a-divider type="vertical" />
        <a-button
          type="link"
          size="small"
          :disabled="record.state !== 4"
          @click="download(record)"
          >下载文件</a-button
        >
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      title="新增"
      v-model="addKeyVisible"
      :maskClosable="false"
      class="action-class"
      width="500px"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="批次名称:" required>
          <a-input
            v-model="updateData.batchName"
            placeholder="请输入批次名称"
          />
        </a-form-item>
        <a-form-item label="设备号文件:" required v-if="model == 'AI00166'">
          <a-input
            v-model="updateData.packageNames"
            placeholder="请输入设备号文件"
          />
        </a-form-item>
        <a-form-item label="运营商:">
          <a-checkbox-group
            v-model="updateData.carrier"
            :options="operatorsOptions"
          >
          </a-checkbox-group>
        </a-form-item>
        <a-form-item label="归属地:">
          <div>
            <div
              :class="{ card: true, checked: !updateData.excludeArea }"
              @click="updateData.excludeArea = false"
            >
              包含
            </div>
            <div
              :class="{ card: true, checked: updateData.excludeArea }"
              @click="updateData.excludeArea = true"
            >
              排除
            </div>
          </div>
          <el-cascader
            v-model="updateData.region"
            :options="options"
            :props="props"
            filterable
            :show-all-levels="false"
            allowClear
            collapse-tags
          ></el-cascader>
          <a-button type="link" @click="fastTyping()" style="margin-left: 5px"
            >快速输入</a-button
          >
          <div>吉林市、重庆市 需要带“市”</div>
        </a-form-item>
        <a-form-item label="包名:" required v-if="model == 'AI00160'">
          <a-textarea
            v-model="updateData.packageNames"
            placeholder="请输入包名"
            :rows="5"
          />
        </a-form-item>
        <a-form-item label="年龄:">
          <a-select
            v-model="updateData.age"
            placeholder="请选择年龄"
            mode="multiple"
          >
            <a-select-option
              v-for="item in ageList"
              :key="item.code"
              :value="item.code"
              >{{ item.desc }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="性别">
          <a-radio-group v-model="updateData.sex">
            <a-radio value="">不限</a-radio>
            <a-radio value="01">男</a-radio>
            <a-radio value="02">女</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="活跃度:" v-if="model == 'AI00160'">
          <a-select v-model="updateData.activeScope" placeholder="请选择活跃度">
            <a-select-option
              v-for="item in activeScopeList"
              :key="item.code"
              :value="item.code"
              >{{ item.desc }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="去重规则:" required>
          <a-input v-model="updateData.ruleDk" placeholder="请输入去重规则" />
        </a-form-item>
        <a-form-item label="去重周期:">
          <a-select v-model="updateData.period" placeholder="请选择去重周期">
            <a-select-option
              v-for="item in periodList"
              :key="item"
              :value="item"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="ftp文件路径:" required>
          <a-select
            v-model="updateData.filePath"
            placeholder="请选择ftp文件路径"
            mode="multiple"
          >
            <a-select-option
              v-for="(item, index) in ftpList"
              :key="index"
              :value="item.value"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="黑名单ftp路径:">
          <a-select
            v-model="updateData.blacklistFilePath"
            placeholder="请选择黑名单ftp路径"
            mode="multiple"
          >
            <a-select-option
              v-for="(item, index) in blackFtpList"
              :key="index"
              :value="item.value"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="数据条数:" required v-if="model == 'AI00160'">
          <a-input
            v-model="updateData.submitCount"
            placeholder="请输入数据条数"
          />
        </a-form-item>
        <a-form-item label="业务类型:" required v-else>
          <a-select v-model="updateData.category" placeholder="请选择业务类型">
            <a-select-option
              v-for="(item, index) in categoryList"
              :key="index"
              :value="item"
              >{{ item }}</a-select-option
            >
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >提交</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      :maskClosable="false"
      title="快速输入"
      v-model="dialogVisible"
      width="600px"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="快速输入:">
          <a-textarea :rows="2" placeholder="请输入内容" v-model="textarea" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="dialogVisible = false">取 消</a-button>
          <a-button type="primary" @click.stop="fastTyping_submit()"
            >确 定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "tdFetchData",
  props: ["model"],
  data() {
    return {
      batchName: "",
      state: undefined,
      stateList: [],
      addKeyVisible: false,
      updateData: {},
      options: [],
      props: { multiple: true, emitPath: false, checkStrictly: true },
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          dataIndex: "id",
          ellipsis: true,
          width: 100,
          align: "center",
        },
        {
          title: "批次名称",
          dataIndex: "batchName",
          ellipsis: true,
          width: 250,
          scopedSlots: { customRender: "batchName" },
        },
        {
          title: "任务id",
          dataIndex: "taskId",
          ellipsis: true,
          width: 300,
        },
        {
          title: "去重规则",
          dataIndex: "ruleDk",
          ellipsis: true,
          width: 100,
        },
        {
          title: "请求条数",
          dataIndex: "submitCount",
          ellipsis: true,
          width: 100,
        },
        {
          title: "返回条数",
          dataIndex: "resultCount",
          ellipsis: true,
          width: 100,
        },
        {
          title: "运营商",
          dataIndex: "carrier",
          ellipsis: true,
          width: 150,
        },
        {
          title: "任务状态",
          dataIndex: "stateDesc",
          ellipsis: true,
          scopedSlots: { customRender: "stateDesc" },
          width: 110,
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
          ellipsis: true,
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 220,
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      ageList: [
        { code: "", desc: "不限" },
        { code: "01", desc: "17岁及以下" },
        { code: "02", desc: "18-24岁" },
        { code: "03", desc: "25-29岁" },
        { code: "04", desc: "30-34岁" },
        { code: "05", desc: "35-39岁" },
        { code: "06", desc: "40-44岁" },
        { code: "07", desc: "45-49岁" },
        { code: "08", desc: "50岁及以上" },
      ],
      activeScopeList: [
        { code: "01", desc: "近1个月活跃" },
        { code: "02", desc: "近3个月新安装" },
        { code: "03", desc: "近3个月安装" },
        { code: "04", desc: "近6个月安装" },
        { code: "05", desc: "近1年安装" },
      ],
      periodList: [7, 30, 60, 90, 180],
      operatorsOptions: ["中国移动", "中国联通", "中国电信"],
      dialogVisible: false,
      textarea: "",
      ftpList: [],
      blackFtpList: [],
      categoryList: [],
    };
  },
  mounted() {
    if (this.model == "AI00166") {
      this.getCategoryList();
    }
    this.getStateList();
    this.getFtpList();
    this.getBlackFtpList();
    this.getArea();
  },
  methods: {
    getCategoryList() {
      this.$http.get("/loadConfig", { code: "TD_CATEGORY" }).then((res) => {
        if (res.result == 200) {
          this.categoryList = res.data.data;
        }
      });
    },
    getFtpList() {
      this.$http.get("/loadConfig", { code: "TD_FTP" }).then((res) => {
        if (res.result == 200) {
          this.ftpList = res.data.data;
        }
      });
    },
    getBlackFtpList() {
      this.$http.get("/loadConfig", { code: "TD_BLACK_FTP" }).then((res) => {
        if (res.result == 200) {
          this.blackFtpList = res.data.data;
        }
      });
    },
    // 获取state集合
    getStateList() {
      this.$http.get("/td/taskStateList").then((res) => {
        if (res.result === 200) {
          this.stateList = res.data;
          this.query(1);
        }
      });
    },
    getArea() {
      this.$http.get("/getProvinceAndCity").then((res) => {
        if (res.result === 200) {
          this.options.splice(0);
          let data = res.data;
          for (let i in data) {
            let a = {};
            a.value = i;
            a.label = i;
            a.children = [];
            if (data[i] && data[i].length > 0) {
              for (let j of data[i]) {
                let b = {};
                b.value = i + ":" + j;
                b.label = i + " " + j;
                a.children.push(b);
              }
            }
            this.options.push(a);
          }
        }
      });
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        batchName: this.batchName,
        state: this.state,
        model: this.model,
      };
      this.loadingTable = true;
      this.$http
        .get("/td/tdTaskPage", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item) => {
              this.getStateDesc(item);
              item.batchNameShow = false;
            });
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    getStateDesc(item) {
      switch (item.state) {
        case 0:
          item.stateDesc = "等待提交";
          item.stateColor = "green";
          break;
        case 1:
          item.stateDesc = "创建成功";
          item.stateColor = "green";
          break;
        case 2:
          item.stateDesc = "运行中";
          item.stateColor = "orange";
          break;
        case 3:
          item.stateDesc = "拉取任务数据";
          item.stateColor = "orange";
          break;
        case 4:
          item.stateDesc = "完成";
          item.stateColor = "green";
          break;
        case 5:
          item.stateDesc = "失败";
          item.stateColor = "red";
          break;
        case 6:
          item.stateDesc = "超时";
          item.stateColor = "red";
          break;
      }
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    unEditBatchName(val) {
      this.$set(val, "batchNameShow", false);
      this.$set(val, "batchName", this.watchBatchName);
    },
    editBatchName(val) {
      this.tableDataSource.forEach((item) => {
        if (item.batchNameShow) {
          this.$set(item, "batchNameShow", false);
          this.$set(item, "batchName", this.watchBatchName);
        }
      });
      this.$set(val, "batchNameShow", true);
      this.watchBatchName = val.batchName;
    },
    editBatchNameSubmit(record) {
      let data = {
        id: record.id,
        batchName: record.batchName,
      };
      this.$http.json_post("/td/updateBatchName", data).then((res) => {
        if (res.result == 200) {
          this.$message.success("修改成功");
          this.query();
        }
      });
    },
    // 新增
    add() {
      this.addKeyVisible = true;
      if (this.model == "AI00160") {
        this.updateData = {
          batchName:
            moment().format("YYYYMMDDHHmm") +
            "-" +
            this.generateRandomLetters(4) +
            ".txt",
          carrier: ["中国移动", "中国联通", "中国电信"],
          region: [],
          packageNames: "",
          age: [""],
          sex: "",
          activeScope: this.activeScopeList[0].code,
          ruleDk: "",
          period: this.periodList[0],
          filePath: [this.ftpList[0].value],
          blacklistFilePath: [this.blackFtpList[0].value],
          excludeArea: false,
          submitCount: "",
        };
      } else {
        this.updateData = {
          batchName:
            moment().format("YYYYMMDDHHmm") +
            "-" +
            this.generateRandomLetters(4) +
            ".txt",
          carrier: ["中国移动", "中国联通", "中国电信"],
          region: [],
          packageNames: "",
          age: [""],
          sex: "",
          ruleDk:
            moment().format("YYYYMMDDHHmm") +
            "-" +
            this.generateRandomLetters(6),
          period: this.periodList[0],
          filePath: [this.ftpList[0].value],
          blacklistFilePath: [this.blackFtpList[0].value],
          excludeArea: false,
          category: this.categoryList[0],
        };
      }
    },
    generateRandomLetters(length) {
      const alphabet = "abcdefghijklmnopqrstuvwxyz0123456789";
      let randomString = "";
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * alphabet.length);
        randomString += alphabet.charAt(randomIndex);
      }
      return randomString;
    },
    copy(record) {
      this.addKeyVisible = true;
      if (this.model == "AI00160") {
        this.updateData = {
          batchName:
            moment().format("YYYYMMDDHHmm") +
            "-" +
            this.generateRandomLetters(4) +
            ".txt",
          carrier: record.carrier.split(","),
          region: record.provinceCity ? record.provinceCity.split(",") : [],
          packageNames: record.packageNames,
          age: record.age.split(","),
          sex: record.sex,
          activeScope: record.activeScope,
          ruleDk: record.ruleDk,
          period: record.period,
          filePath: record.filePath.split(","),
          blacklistFilePath: record.blacklistFilePath.split(","),
          excludeArea: record.excludeArea,
          submitCount: record.submitCount,
        };
      } else {
        this.updateData = {
          batchName:
            moment().format("YYYYMMDDHHmm") +
            "-" +
            this.generateRandomLetters(4) +
            ".txt",
          carrier: record.carrier.split(","),
          region: record.provinceCity ? record.provinceCity.split(",") : [],
          packageNames: record.packageNames,
          age: record.age ? record.age.split(",") : [""],
          sex: record.sex ? record.sex : "",
          ruleDk: record.ruleDk,
          period: record.period,
          filePath: record.filePath.split(","),
          blacklistFilePath: record.blacklistFilePath.split(","),
          excludeArea: record.excludeArea,
          category: record.category,
        };
      }
    },
    // 确定新增
    add_submit() {
      if (
        !this.updateData.batchName ||
        !this.updateData.packageNames ||
        !this.updateData.ruleDk ||
        this.updateData.filePath.length == 0 ||
        (this.model == "AI00160" && !this.updateData.submitCount)
      ) {
        this.$message.warning("请填写必填项");
        return;
      }
      this.$confirm({
        title: "提示",
        content: (h) => <div style="color:red;">是否提交任务? 请确认</div>,
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          this.loading = true;
          let data = { ...this.updateData };
          data.model = this.model;
          data.age = data.age.join(",");
          if (data.model == "AI00166") {
            delete data.activeScope;
            delete data.submitCount;
          } else {
            delete data.category;
          }
          data.carrier = data.carrier.join(",");
          data.filePath = data.filePath.join(",");
          data.blacklistFilePath = data.blacklistFilePath.join(",");
          this.$http
            .json_post("/td/addTdTask", data)
            .then((res) => {
              if (res.result === 200) {
                this.addKeyVisible = false;
                this.loading = false;
                this.$message.success("提交成功");
                this.query();
              }
            })
            .catch(() => {
              this.loading = false;
            });
        },
        onCancel: () => {},
      });
    },
    fastTyping() {
      let arr = [];
      this.updateData.region.forEach((item) => {
        if (item.indexOf(":") !== -1) {
          arr.push(item.split(":")[1]);
        } else {
          arr.push(item);
        }
      });
      this.textarea = arr.join(",");
      this.dialogVisible = true;
    },
    fastTyping_submit() {
      this.textarea = this.textarea.replace(/，/g, ",");
      this.textarea = this.textarea.replace(/ /g, ",");
      this.textarea = this.textarea.replace(/\|/g, ",");
      this.textarea = this.textarea.replace(/\｜/g, ",");
      let textarea = this.textarea.split(",");
      textarea = this.removeTrim(textarea);
      textarea.map((areaItem, index) => {
        this.options.map((item) => {
          item.children.map((childrenItem) => {
            if (childrenItem.value.split(":")[1] == areaItem) {
              textarea[index] = childrenItem.value;
            }
          });
        });
      });

      this.$nextTick(() => {
        this.$set(this.updateData, "region", textarea);
      });
      this.dialogVisible = false;
      this.$message.success("设置成功");
    },
    removeTrim(array) {
      for (var i = 0; i < array.length; i++) {
        if (array[i] == "") {
          array.splice(i, 1);
          i = i - 1;
        }
      }
      return array;
    },
    updateState(record) {
      let data = { taskId: record.taskId };
      this.loadingTable = true;
      this.$http.get("/td/queryTaskDetailById", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("刷新成功");
          this.query();
        }
      });
    },
    download(record) {
      this.syncLoading = this.$syncLoading({
        message: "下载文件中...",
      });
      let data = { taskId: record.taskId };
      this.$http.blobDownload("/td/fileDownload", data).then((res) => {
        this.blobDownloads(res, record.batchName);
      });
    },
    blobDownloads(data, fileName) {
      if (!data) {
        return;
      }
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
      this.syncLoading.close();
      this.$message.success("下载成功");
    },
  },
};
</script>
<style lang="scss" scoped>
.el-cascader {
  margin-bottom: 0px !important;
  &:deep() .el-cascader__search-input {
    min-width: 5px;
  }
}
.card {
  color: black;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #e4e7ed;
  display: inline-block;
  line-height: 14px;
  cursor: pointer;
}
.card:first-child {
  border-right: none;
}
.checked {
  color: #409eff;
}
</style>
