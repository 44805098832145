import Vue from "vue";
//封装公共代码

const common = {};

//时间格式：yyyy-MM-dd HH:mm:ss
common.transformTime = function (time) {
  var time = new Date(time);
  var y = time.getFullYear();
  var m = time.getMonth() + 1;
  var d = time.getDate();
  var h = time.getHours();
  var mm = time.getMinutes();
  var s = time.getSeconds();
  function add0(m) {
    return m < 10 ? "0" + m : m;
  }
  return (
    y +
    "-" +
    add0(m) +
    "-" +
    add0(d) +
    " " +
    add0(h) +
    ":" +
    add0(mm) +
    ":" +
    add0(s)
  );
};

//时间格式：MM/dd HH:mm:ss
common.transformTimeM = function (time) {
  var time = new Date(time);
  var y = time.getFullYear();
  var m = time.getMonth() + 1;
  var d = time.getDate();
  var h = time.getHours();
  var mm = time.getMinutes();
  var s = time.getSeconds();
  function add0(m) {
    return m < 10 ? "0" + m : m;
  }
  return (
    add0(m) + "/" + add0(d) + " " + add0(h) + ":" + add0(mm) + ":" + add0(s)
  );
};

//时间格式：MM/dd
common.transformTimeMD = function (time) {
  var time = new Date(time);
  var y = time.getFullYear();
  var m = time.getMonth() + 1;
  var d = time.getDate();
  var h = time.getHours();
  var mm = time.getMinutes();
  var s = time.getSeconds();
  function add0(m) {
    return m < 10 ? "0" + m : m;
  }
  return add0(m).toString() + add0(d).toString();
};

//时间格式：yy/MM/dd
common.transformTimeYMD = function (time) {
  var time = new Date(time);
  var y = time.getFullYear();
  var m = time.getMonth() + 1;
  var d = time.getDate();
  var h = time.getHours();
  var mm = time.getMinutes();
  var s = time.getSeconds();
  function add0(m) {
    return m < 10 ? "0" + m : m;
  }
  return y + add0(m).toString() + add0(d).toString();
};

// 随机生成数字+大小写字母
common.randomStr = function (randomFlag, min, max) {
  // randomFlag：是否任意长度
  // min：任意长度最小位[固定位数]
  // max：任意长度最大位
  //生成3-32位随机串：randomStr(true, 3, 32)
  //生成43位随机串：randomStr(false, 43)
  let str = "",
    range = 6,
    index = null,
    arr = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];

  if (randomFlag) {
    // 随机产生
    range = Math.round(Math.random() * (max - min)) + min;
  }

  for (let i = 0; i < range; i++) {
    index = Math.round(Math.random() * (arr.length - 1));
    str += arr[index];
  }
  return str;
};

//Es6数组去重
common.unique = function (arr) {
  var x = new Set(arr);
  return [...x];
};

// 深度拷贝
common.deepClone = (source) => {
  const targetObj = source.constructor === Array ? [] : {}; // 判断复制的目标是数组还是对象
  for (const keys in source) {
    // 遍历目标
    if (source.hasOwnProperty(keys)) {
      if (source[keys] && typeof source[keys] === "object") {
        // 如果值是对象，就递归一下
        targetObj[keys] = source[keys].constructor === Array ? [] : {};
        targetObj[keys] = common.deepClone(source[keys]);
      } else {
        // 如果不是，就直接赋值
        targetObj[keys] = source[keys];
      }
    }
  }
  return targetObj;
};

// 插入到光标位置
common.insertInputTxt = (insertText, id, textarea) => {
  let text = insertText;
  let areaField = document.getElementById(id); // 拿到目标标签
  // IE浏览器
  if (document.selection) {
    areaField.focus();
    let sel = document.selection.createRange();
    sel.text = text;
  } else if (areaField.selectionStart || areaField.selectionStart === 0) {
    // 谷歌 Firefox 等
    let startPos = areaField.selectionStart;
    let endPos = areaField.selectionEnd;
    let restoreTop = areaField.scrollTop; // 获取滚动条高度
    //  textarea 是v-model的值
    // text 是 选择的要插入的值
    textarea =
      textarea.substring(0, startPos) +
      text +
      textarea.substring(endPos, textarea.length);
    if (restoreTop > 0) {
      areaField.scrollTop = restoreTop;
    }
    areaField.focus();
    areaField.selectionStart = startPos + text.length;
    areaField.selectionEnd = startPos + text.length;
  } else {
    textarea += text;
    areaField.focus();
  }
  return textarea;
};

common.random = () => {
  let arr = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ];
  let num = "";
  for (let i = 0; i < 12; i++) {
    num += arr[parseInt(Math.random() * arr.length)];
  }
  return num;
};

common.getTitle = (columns) => {
  const draggingMap = {};
  columns.forEach((col) => {
    const k = col.dataIndex || col.key;
    draggingMap[k] = col.width;
  });
  const draggingState = Vue.observable(draggingMap);
  const resizeableTitle = (h, props, children) => {
    let thDom = null;
    const { key, ...restProps } = props;
    let col;
    if (key == "selection-column") {
      return (
        <th {...restProps} class="resize-table-th">
          {children}
        </th>
      );
    } else {
      col = columns.find((item) => {
        const k = item.dataIndex || item.key;
        return k === key;
      });
    }
    if (col) {
      if (!col.width) {
        return <th {...restProps}>{children}</th>;
      }
      const onDrag = (x) => {
        draggingState[key] = 0;
        col.width = Math.max(x, 1);
      };
      const onDragstop = () => {
        draggingState[key] = thDom.getBoundingClientRect().width;
      };
      return (
        <th
          {...restProps}
          v-ant-ref={(r) => {
            thDom = r;
          }}
          width={draggingState[key]}
          class="resize-table-th"
        >
          {children}
          <vue-draggable-resizable
            key={col.dataIndex || col.key}
            class="table-draggable-handle"
            w={10}
            x={col.width || draggingState[key]}
            z={1}
            axis="x"
            draggable={true}
            resizable={false}
            onDragging={onDrag}
            onDragstop={onDragstop}
          ></vue-draggable-resizable>
        </th>
      );
    }
  };
  let components = {
    header: {
      cell: resizeableTitle,
    },
  };
  return components;
};

common.formatTotalCount = (num) => {
  if (num < 10000) {
    return num;
  } else if (num < 100000000) {
    let newNum = parseFloat((num / 10000).toFixed(1));
    return newNum + "w";
  } else {
    let newNum = parseFloat((num / 100000000).toFixed(1));
    return newNum + "e";
  }
};

// 表格隔行变色
common.rowClassColor = (record, index) => {
  let className = "light-row";
  if (index % 2 === 1) className = "dark-row";
  return className;
};

common.timeConsuming = (executeSecond) => {
  let time = executeSecond;
  var hours = Math.floor(time / 3600);
  var minutes = Math.floor(((time % 86400) % 3600) / 60);
  var seconds = Math.floor(((time % 86400) % 3600) % 60);

  // 补零函数，使得数字始终为两位数
  const pad = (num) => (num < 10 ? "0" + num : num);

  return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
};

common.getFileExtension = (filename) => {
  const index = filename.lastIndexOf(".");
  return index !== -1 ? filename.substring(index + 1) : "";
};

// 拖拽文件获取fileList
common.dragFileList = async (e) => {
  const items = [...e.dataTransfer.items];
  let fileList = [];
  const promises = [];

  for (let i = 0; i < items.length; i++) {
    const item = items[i].webkitGetAsEntry();
    if (item) {
      if (item.isFile) {
        promises.push(
          new Promise((resolve) => {
            item.file((file) => {
              fileList.push(file);
              resolve();
            });
          })
        );
      } else if (item.isDirectory) {
        promises.push(readDirectory(item, fileList));
      }
    }
  }

  await Promise.all(promises);
  return fileList;

  function readDirectory(directoryEntry, fileList) {
    return new Promise((resolve) => {
      const reader = directoryEntry.createReader();
      reader.readEntries((entries) => {
        const subPromises = [];
        for (let i = 0; i < entries.length; i++) {
          const entry = entries[i];
          if (entry.isFile) {
            subPromises.push(
              new Promise((resolve) => {
                entry.file((file) => {
                  fileList.push(file);
                  resolve();
                });
              })
            );
          } else if (entry.isDirectory) {
            // 递归读取子文件夹
            subPromises.push(readDirectory(item, fileList));
          }
        }
        Promise.all(subPromises).then(resolve);
      });
    });
  }
};

export default common;
