<template>
  <div class="myPagination">
    <div class="paginationClass">
      <a-button class="btn-next" @click="queryTotal">查询总条数</a-button>
      <a-button class="btn-next" @click="queryOne" :disabled="disabledPrevBtn"
        >第一页</a-button
      >
      <a-button
        class="btn-next"
        icon="left"
        @click="handlePageNo('prev')"
        :disabled="disabledPrevBtn"
      >
        上一页
      </a-button>
      <div class="currentPage-class">{{ pageNo }}</div>
      <a-button
        class="btn-next"
        @click="handlePageNo('next')"
        :disabled="disabledNextBtn"
      >
        下一页<a-icon type="right" />
      </a-button>
      <a-dropdown :trigger="['click']">
        <a-button class="btn-next">
          {{ pageSize }}条/页 <a-icon type="down" />
        </a-button>
        <a-menu slot="overlay">
          <a-menu-item>
            <a-button type="link" size="small" @click="handlePageSize(10)">
              10条/页
            </a-button>
          </a-menu-item>
          <a-menu-item>
            <a-button type="link" size="small" @click="handlePageSize(20)">
              20条/页
            </a-button>
          </a-menu-item>
          <a-menu-item>
            <a-button type="link" size="small" @click="handlePageSize(50)">
              50条/页
            </a-button>
          </a-menu-item>
          <a-menu-item>
            <a-button type="link" size="small" @click="handlePageSize(100)">
              100条/页
            </a-button>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
  </div>
</template>
<script>
export default {
  name: "myPagination",
  props: {
    tableList: {
      // 表格数据
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
    };
  },
  computed: {
    // 禁用上一页
    disabledPrevBtn() {
      if (this.pageNo === 1) {
        return true;
      }
      return false;
    },
    // 禁用下一页
    disabledNextBtn() {
      if (this.tableList.length < this.pageSize) {
        return true;
      }
      return false;
    },
  },
  mounted() {},
  methods: {
    // 查询总条数
    queryTotal() {
      this.$emit("queryTotal");
    },
    // 查询第一页
    queryOne() {
      this.pageNo = 1;
      this.$emit("queryOne", 1);
    },
    // 翻页
    handlePageNo(key) {
      if (key === "prev") {
        this.pageNo--;
      }
      if (key === "next") {
        this.pageNo++;
      }
      this.$emit("currentPageNo", this.pageNo);
    },
    // 条数
    handlePageSize(pageSize) {
      this.pageNo = 1;
      this.pageSize = pageSize;
      this.$emit("currentPageSize", this.pageSize);
    },
  },
};
</script>
<style scoped>
.paginationClass {
  position: relative;
  display: flex;
}
.paginationClass .currentPage-class {
  height: 28px;
  line-height: 28px;
  font-size: 13px;
  color: #1890ff;
  padding: 0 8px;
}
.paginationClass .btn-next {
  padding: 0;
  margin: 0 5px;
  background-color: #f5f7fa;
  min-width: 30px;
  border-radius: 2px;
  background-size: 16px;
  height: 28px;
  line-height: 28px;
  vertical-align: top;
  box-sizing: border-box;
  font-size: 13px;
  border: none;
  padding: 0 12px;
}
</style>
