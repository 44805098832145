<template>
  <a-modal
    v-model="dialogVisible"
    :width="width"
    :afterClose="closed"
    :maskClosable="false"
  >
    <template slot="title">
      <h4
        style="display: inline-block"
        v-if="type == 'line' || isTest || viewOnly"
      >
        {{ title }}
      </h4>
      <h4
        style="display: inline-block"
        v-if="type !== 'line' && !isTest && !inputShow && !viewOnly"
        @click="inputShow = true"
      >
        {{ title }}
      </h4>
      <a-input
        @change="inputChange"
        v-if="inputShow"
        v-model="title"
        @blur="inputShow = false"
        ref="titleInput"
        placeholder="请输入标题"
        style="width: 200px"
      />
      <a-tooltip placement="left" v-if="menuData.menuType !== 'line'">
        <div slot="title" v-html="nodeData.nodeInfoRemark"></div>
        <a-icon
          type="question-circle"
          style="color: #1879ff; font-size: 14px; margin-left: 5px"
        />
      </a-tooltip>
    </template>
    <div v-if="menuData.menuType == 'line'">
      <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
        <template v-if="fromShow">
          <a-form-item :label="fromPriorityDiffShow ? '优先级' : 'from'">
            <a-select
              v-model="fromLabelList"
              placeholder="请选择"
              allowClear
              v-if="fromOptionsList.length > 0"
              :disabled="viewOnly"
              style="width: 200px"
              mode="multiple"
            >
              <a-select-option
                v-for="item in fromOptionsList"
                :key="item.value"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
            <a-input
              @change="inputChange"
              v-else
              :disabled="viewOnly"
              v-model="fromLabel"
              :placeholder="
                '请输入' + (fromPriorityDiffShow ? '优先级' : 'from')
              "
              allowClear
            />
          </a-form-item>
        </template>
        <template v-if="toShow">
          <a-form-item label="to" v-if="toOptionsList.length > 0">
            <a-select
              v-model="toLabelList"
              placeholder="请选择"
              allowClear
              :disabled="viewOnly"
              style="width: 200px"
              mode="multiple"
            >
              <a-select-option
                v-for="item in toOptionsList"
                :key="item.value"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item :label="tagShow ? 'tag' : '优先级'" v-else>
            <a-input
              @change="inputChange"
              :disabled="viewOnly"
              v-model="toLabel"
              :placeholder="'请输入' + (tagShow ? 'tag' : '优先级')"
            />
          </a-form-item>
        </template>
      </a-form>
    </div>
    <div class="test_unit_div" v-else>
      <div
        class="prevFileContent"
        v-if="prevFileContentShow && prevFileContentList.length > 0"
      >
        <div class="item_title">前置节点文件内容</div>
        <template v-for="(item, index) in prevFileContentList">
          <div :key="index" v-html="item.fileContent"></div>
          <br />
        </template>
      </div>
      <div
        class="test_unit_title"
        v-if="nodeData.extraInfoShow"
        :class="{
          test_unit_title_2: isTest,
          test_unit_title_3:
            prevFileContentShow && prevFileContentList.length > 0,
        }"
      >
        <div class="item_title" v-if="isTest">条件</div>
        <div>
          <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }">
            <a-form-item label="读取选择:" v-if="type === 'fileLoad'">
              <a-select
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.uploadType"
                placeholder="请选择读取方式"
                style="width: 200px"
                @change="uploadTypeChange"
              >
                <a-select-option
                  v-for="(item, index) in fileLoadList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              label="上传文件:"
              required
              v-if="
                type === 'fileLoad' &&
                nodeData.extraInfo.uploadType !== 'TMP_STORE' &&
                nodeData.extraInfo.uploadType !== 'FILE_SET' &&
                nodeData.extraInfo.uploadType !== 'FTP'
              "
            >
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-if="nodeData.extraInfo.uploadType === 'LOCAL_FILE'"
                v-model="nodeData.extraInfo.path"
                placeholder="请输入内容"
              />
              <a-upload-dragger
                v-else-if="nodeData.extraInfo.uploadType === 'UPLOAD'"
                ref="upload"
                :customRequest="customRequest"
                :remove="handleRemove"
                :beforeUpload="beforeUpload"
                :fileList="fileList"
              >
                <p class="ant-upload-drag-icon">
                  <a-icon type="inbox" />
                </p>
                <p class="ant-upload-text">单击或拖动文件到此区域进行上传</p>
                <p class="ant-upload-hint">只支持单个上传</p>
              </a-upload-dragger>
              <a-select
                v-else-if="nodeData.extraInfo.uploadType === 'FILE_LIST'"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.id"
                placeholder="请选择文件"
                showSearch
                allowClear
                :filterOption="false"
                @change="fileChange"
                @search="searchFileList"
                @blur="searchFileList()"
              >
                <a-select-option
                  v-for="item in fileLoadFileList"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              label="文件集:"
              required
              v-if="
                type === 'fileLoad' &&
                nodeData.extraInfo.uploadType == 'FILE_SET'
              "
            >
              <a-select
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.id"
                placeholder="请选择文件集"
                showSearch
                :filterOption="false"
                @change="fileCollectChange"
                @search="searchFileCollectList"
                @blur="searchFileCollectList()"
              >
                <a-select-option
                  v-for="item in fileCollectList"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              required
              v-if="
                type === 'fileLoad' &&
                nodeData.extraInfo.uploadType == 'FILE_SET'
              "
            >
              <span slot="label">
                数量限制
                <a-tooltip placement="left">
                  <div slot="title">
                    按照上传时间从后往前排,优先取最近上传的文件,15表示取最近15天的数据,2-15表示取2到15天的数据(跳过了第一天)
                  </div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-switch
                v-model="nodeData.extraInfo.fileCollectLimitCountShow"
                @change="() => (nodeData.extraInfo.fileCollectLimitCount = '')"
                :disabled="viewOnly"
                class="greenSwitch"
                checked-children="限定文件"
                un-checked-children="全部文件"
              />
              <template v-if="nodeData.extraInfo.fileCollectLimitCountShow">
                <a-input
                  @change="inputChange"
                  :disabled="viewOnly"
                  v-model="nodeData.extraInfo.fileCollectLimitCount"
                  placeholder="请输入有效期"
                  style="width: 70%; margin: 0 8px"
                />
                <a-button
                  type="link"
                  :disabled="
                    !nodeData.extraInfo.fileCollectLimitCount ||
                    !nodeData.extraInfo.id ||
                    viewOnly
                  "
                  @click="getPreviewSourceFileList"
                  >预览</a-button
                >
              </template>
            </a-form-item>
            <a-form-item
              label="标签:"
              required
              v-if="
                type === 'fileLoad' &&
                nodeData.extraInfo.uploadType == 'TMP_STORE'
              "
            >
              <a-select
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.tmpStoreCode"
                placeholder="请选择读取tag"
                style="width: 200px"
                @change="tmpStoreTagChange"
                showSearch
                optionFilterProp="children"
              >
                <a-select-option
                  v-for="(item, index) in tmpStoreList"
                  :key="index"
                  :value="item.code"
                >
                  {{ item.tag }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <template
              v-if="
                type === 'fileLoad' && nodeData.extraInfo.uploadType == 'FTP'
              "
            >
              <a-form-item label="ftp账号:" required>
                <a-select
                  :disabled="viewOnly"
                  v-model="nodeData.extraInfo.ftpId"
                  placeholder="请选择ftp账号"
                  style="width: 200px; margin-right: 5px"
                  showSearch
                  :filterOption="false"
                  @search="searchFtpServerList"
                  @change="getFtpFileList"
                >
                  <a-select-option
                    v-for="item in ftpServerList"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="ftp文件:" required>
                <a-tree-select
                  :disabled="viewOnly || !nodeData.extraInfo.ftpId"
                  v-model="nodeData.extraInfo.ftpPathList"
                  style="width: 100%"
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                  :treeData="ftpFileList"
                  placeholder="请选择ftp文件"
                  multiple
                  showSearch
                  allowClear
                  :loadData="onLoadData"
                />
              </a-form-item>
              <a-form-item label="追加文件名:">
                <a-switch
                  checked-children="是"
                  un-checked-children="否"
                  v-model="nodeData.extraInfo.appendFileName"
                  :disabled="viewOnly"
                />
              </a-form-item>
            </template>
            <a-form-item required v-if="type == 'fileStore'">
              <span slot="label">
                标签
                <a-tooltip placement="left">
                  <div slot="title">
                    标签用于标记文件，用标签A存放文件，就可以用标签A读取到该文件
                  </div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.tag"
                placeholder="请输入读取tag"
              />
            </a-form-item>
            <a-tabs
              v-if="type === 'databaseLoad'"
              v-model="nodeData.extraInfo.active"
            >
              <a-tab-pane tab="单批次读取" key="1"></a-tab-pane>
              <a-tab-pane tab="多表读取" key="2"></a-tab-pane>
              <a-tab-pane tab="多批次读取" key="3"></a-tab-pane>
            </a-tabs>
            <a-form-item
              label="数据库:"
              required
              v-if="
                type === 'databaseLoad' ||
                type == 'sqlBatchQuery' ||
                type == 'dataBaseUpdate' ||
                type == 'databaseSelect' ||
                type == 'databaseSelectColumnSupplement' ||
                type == 'database2Maxwell'
              "
              :label-col="{ span: 5 }"
              :wrapper-col="{ span: 19 }"
            >
              <a-select
                v-model="nodeData.extraInfo.datasourceId"
                placeholder="请选择数据库"
                :disabled="viewOnly"
                showSearch
                :filterOption="false"
                @search="searchPipelineDataSourceList"
                @blur="searchPipelineDataSourceList()"
                @change="dataSourceChange"
                style="width: 240px"
              >
                <a-select-option
                  v-for="item in dataSourceIdList"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              required
              v-if="type == 'database2Maxwell'"
              :label-col="{ span: 5 }"
              :wrapper-col="{ span: 19 }"
            >
              <span slot="label">
                表名
                <a-tooltip placement="left">
                  <div slot="title">
                    自定义表名可以输入 table_[0-9] 或 table_[1,2,3,7,8,9]
                  </div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-select
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.type"
                placeholder="请选择类型"
                style="width: 80px; margin-right: 10px"
              >
                <a-select-option
                  v-for="item in tableNameTypeList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
              <a-select
                v-model="nodeData.extraInfo.tableName"
                placeholder="请选择表"
                :disabled="viewOnly || !nodeData.extraInfo.datasourceId"
                showSearch
                style="width: 240px"
                v-if="nodeData.extraInfo.type == 'select'"
              >
                <a-select-option
                  v-for="(item, index) in tableList"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-else
                v-model="nodeData.extraInfo.tableName"
                placeholder="请输入自定义数据表"
                style="width: 300px"
              />
            </a-form-item>
            <a-form-item
              required
              v-if="type == 'database2Maxwell'"
              class="codemirrorFormItem"
              :label-col="{ span: 3 }"
              :wrapper-col="{ span: 20 }"
            >
              <span slot="label">
                sql条件
                <a-tooltip placement="left">
                  <div slot="title">
                    请输入where后面的条件语句,支持日期表达式，如：<br />
                    ${yyyy-MM-dd}<br />
                    ${yyyy-MM-dd:+1d}
                  </div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <codemirror
                v-if="dialogVisible"
                v-model="nodeData.extraInfo.condition"
                placeholder="请输入条件"
                :options="temOptions"
                class="code_style"
              >
              </codemirror>
            </a-form-item>
            <a-form-item
              label="数据表:"
              required
              v-if="type === 'databaseLoad' && nodeData.extraInfo.active == '2'"
              :label-col="{ span: 5 }"
              :wrapper-col="{ span: 19 }"
            >
              <a-select
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.tablesType"
                placeholder="请选择数据表"
                style="width: 15%"
              >
                <a-select-option
                  v-for="item in tablesTypeList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-if="nodeData.extraInfo.tablesType == 'custom'"
                v-model="nodeData.extraInfo.tables"
                placeholder="请输入自定义数据表"
                style="width: 80%; margin-left: 10px"
                allowClear
              />
            </a-form-item>
            <a-form-item
              label="更新模式:"
              required
              v-if="type === 'dataBaseUpdate'"
              :label-col="{ span: 5 }"
              :wrapper-col="{ span: 19 }"
            >
              <a-select
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.updateMode"
                placeholder="请选择更新模式"
                style="width: 240px"
              >
                <a-select-option
                  v-for="item in updateModeList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              label="查询模式:"
              required
              v-if="type === 'databaseSelect'"
              :label-col="{ span: 5 }"
              :wrapper-col="{ span: 19 }"
            >
              <a-select
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.mode"
                placeholder="请选择查询模式"
                style="width: 240px"
              >
                <a-select-option
                  v-for="item in modeList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              required
              v-if="
                type === 'databaseLoad' ||
                type == 'sqlBatchQuery' ||
                (type == 'dataBaseUpdate' &&
                  nodeData.extraInfo.updateMode == 'GENERATE_BATCH') ||
                (type == 'databaseSelect' &&
                  nodeData.extraInfo.mode == 'GENERATE_BATCH') ||
                type == 'databaseSelectColumnSupplement'
              "
              :label-col="{ span: 5 }"
              :wrapper-col="{ span: 19 }"
              class="codemirrorFormItem"
            >
              <span slot="label">
                sql
                <a-tooltip placement="left" v-if="type == 'sqlBatchQuery'">
                  <div slot="title">
                    sql：select count(id) from A where param = ‘${1}’ and param2
                    = ‘${2}’; <br />
                    分隔符：, <br />
                    输入：value,value2 <br />
                    格式化sql：select count(id) from A where param = ‘value’ and
                    param2 = ‘value2’;<br />
                    输出：value,value2,10
                  </div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
                <a-tooltip
                  placement="left"
                  v-if="type == 'databaseSelectColumnSupplement'"
                >
                  <div slot="title">select b,c from table where a='${1}'</div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <codemirror
                v-if="dialogVisible"
                v-model="nodeData.extraInfo.sql"
                :placeholder="
                  type === 'databaseLoad' && nodeData.extraInfo.active == '2'
                    ? '请输入sql, ${table}为表号占位符'
                    : '请输入sql'
                "
                :options="temOptions"
                class="code_style"
              >
              </codemirror>
            </a-form-item>
            <a-form-item
              required
              v-if="type === 'databaseSelectColumnSupplement'"
            >
              <span slot="label">
                未命中填充
                <a-tooltip placement="left">
                  <div slot="title">
                    当未查询到记录时,填充该字符串,为空则直接过滤未查询到的记录
                  </div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.fillingStrWhenNotFound"
                placeholder="请输入未命中填充"
                style="width: 240px"
              />
            </a-form-item>
            <a-form-item
              label="参数:"
              required
              v-if="type === 'databaseLoad' && nodeData.extraInfo.active == '3'"
              :label-col="{ span: 5 }"
              :wrapper-col="{ span: 19 }"
            >
              <a-textarea
                v-model="nodeData.extraInfo.param"
                placeholder="请输入参数"
                :rows="6"
                :disabled="viewOnly"
              />
            </a-form-item>
            <a-form-item
              label="分隔符:"
              required
              v-if="type === 'sqlBatchQuery'"
            >
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.split"
                placeholder="请输入分隔符"
                style="width: 120px"
              />
            </a-form-item>
            <a-form-item
              label="分隔符:"
              required
              v-if="
                (type === 'databaseLoad' && nodeData.extraInfo.active == '3') ||
                (type == 'dataBaseUpdate' &&
                  nodeData.extraInfo.updateMode == 'GENERATE_BATCH') ||
                (type == 'databaseSelect' &&
                  nodeData.extraInfo.mode == 'GENERATE_BATCH') ||
                type === 'shortUrlCreate' ||
                type == 'dataBaseInsert' ||
                type == 'dataStatistics' ||
                (type == 'contentFormat' &&
                  nodeData.extraInfo.type == 'format') ||
                type == 'sync2Clickhouse' ||
                type == 'columnSupplement' ||
                type == 'variableGenerate'
              "
            >
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.separator"
                placeholder="请输入分隔符"
                style="width: 120px"
              />
            </a-form-item>

            <template v-if="type == 'shortUrlCreate'">
              <a-form-item label="数据格式:">
                <h4>短链名称,长链,其他字段</h4>
              </a-form-item>
              <a-form-item label="域名:">
                <a-select
                  :disabled="viewOnly"
                  v-model="nodeData.extraInfo.domain"
                  placeholder="请选择域名"
                  style="width: 240px"
                >
                  <a-select-option
                    v-for="(item, index) in domainList"
                    :key="index"
                    :value="item"
                  >
                    {{ item }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </template>

            <template v-if="type == 'dataBaseInsert'">
              <a-form-item label="数据库:" required>
                <a-select
                  v-model="nodeData.extraInfo.datasourceId"
                  placeholder="请选择数据库"
                  :disabled="viewOnly"
                  showSearch
                  :filterOption="false"
                  @search="searchPipelineDataSourceList"
                  @blur="searchPipelineDataSourceList()"
                  @change="dataSourceChange"
                  style="width: 240px"
                >
                  <a-select-option
                    v-for="item in dataSourceIdList"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="表名:" required>
                <a-input
                  v-if="nodeData.extraInfo.tableNameInputShow"
                  @change="inputChange"
                  :disabled="viewOnly"
                  v-model="nodeData.extraInfo.tableName"
                  placeholder="请输入表名"
                  style="width: 240px"
                />
                <a-select
                  v-else
                  v-model="nodeData.extraInfo.tableName"
                  placeholder="请选择表"
                  :disabled="viewOnly || !nodeData.extraInfo.datasourceId"
                  @change="
                    getFieldList(
                      nodeData.extraInfo.datasourceId,
                      nodeData.extraInfo.datasourceName,
                      $event,
                      false,
                      true
                    )
                  "
                  showSearch
                  style="width: 240px"
                >
                  <a-select-option
                    v-for="(item, index) in tableList"
                    :key="index"
                    :value="item"
                  >
                    {{ item }}
                  </a-select-option>
                </a-select>
                <a-button
                  type="link"
                  @click="
                    nodeData.extraInfo.tableNameInputShow =
                      !nodeData.extraInfo.tableNameInputShow
                  "
                  style="margin-left: 10px"
                  :disabled="viewOnly || !nodeData.extraInfo.datasourceId"
                >
                  {{
                    nodeData.extraInfo.tableNameInputShow
                      ? "选择模式"
                      : "输入模式"
                  }}
                </a-button>
              </a-form-item>
              <a-table
                :rowClassName="$common.rowClassColor"
                :columns="fieldItemColumns"
                :dataSource="fieldItemList"
                :pagination="false"
                bordered
                size="small"
              >
                <span slot="valueTitle">
                  取值
                  <a-tooltip placement="left">
                    <div slot="title">
                      支持形参，如：<br />
                      ${yyyy-MM-dd}<br />
                      ${yyyy-MM-dd:+1d}<br />
                      ${1}获取输入数据第一列
                    </div>
                    <a-icon
                      type="question-circle"
                      style="color: #1879ff; font-size: 14px"
                    />
                  </a-tooltip>
                </span>
                <span slot="value" slot-scope="text, record">
                  <a-input
                    @change="inputChange"
                    :disabled="viewOnly"
                    v-model="record.value"
                    size="small"
                  />
                </span>
                <span slot="action" slot-scope="text, record, index">
                  <a-button
                    type="link"
                    @click="delete_field(index)"
                    :style="{ color: viewOnly ? '' : 'red' }"
                    :disabled="viewOnly"
                    >删除</a-button
                  >
                </span>
              </a-table>
              <div style="margin-top: 10px">
                <a-button
                  type="primary"
                  @click="fieldAdd()"
                  :disabled="viewOnly"
                  >添加</a-button
                >
              </div>
            </template>

            <template v-if="type == 'dataStatistics'">
              <a-form-item required>
                <span slot="label">
                  基准列
                  <a-tooltip placement="left">
                    <div slot="title">
                      支持多列,多个以逗号
                      (英文状态)分割,从1开始计数,-1表示没有基准列
                    </div>
                    <a-icon
                      type="question-circle"
                      style="color: #1879ff; font-size: 14px"
                    />
                  </a-tooltip>
                </span>
                <a-input
                  @change="inputChange"
                  :disabled="viewOnly"
                  v-model="nodeData.extraInfo.baseColumn"
                  placeholder="请输入基准列"
                  @blur="baseColumnBlur"
                />
              </a-form-item>
              <a-table
                :rowClassName="$common.rowClassColor"
                :columns="conditionColumns"
                :dataSource="conditionList"
                :pagination="false"
                bordered
                size="small"
              >
                <span slot="columnExpressionTitle">
                  表达式
                  <a-tooltip placement="left">
                    <div slot="title">
                      只有正整数会被当作数字类型不用加引号,其他要加引号<br />
                      多个条件满足其一 ${1} == 1 || ${2} == 1<br />
                      多个条件全部满足 ${1} == 1 && ${2} == 1
                    </div>
                    <a-icon
                      type="question-circle"
                      style="color: #1879ff; font-size: 14px"
                    />
                  </a-tooltip>
                </span>
                <span slot="columnRemarkTitle">
                  备注
                  <a-tooltip placement="left">
                    <div slot="title">仅展示用,可不填写</div>
                    <a-icon
                      type="question-circle"
                      style="color: #1879ff; font-size: 14px"
                    />
                  </a-tooltip>
                </span>
                <span slot="expression" slot-scope="text, record">
                  <a-input
                    @change="inputChange"
                    v-model="record.expression"
                    size="small"
                  />
                </span>
                <span slot="value" slot-scope="text, record">
                  <a-input
                    @change="inputChange"
                    v-model="record.value"
                    size="small"
                  />
                </span>
                <span slot="remark" slot-scope="text, record">
                  <a-input
                    @change="inputChange"
                    v-model="record.remark"
                    size="small"
                  />
                </span>
                <span slot="action" slot-scope="text, record, index">
                  <a-button
                    type="link"
                    @click="delete_condition(index)"
                    :style="{ color: viewOnly ? '' : 'red' }"
                    :disabled="viewOnly"
                    >删除</a-button
                  >
                </span>
              </a-table>
              <div style="margin-top: 10px">
                <a-button
                  type="primary"
                  @click="conditionAdd()"
                  :disabled="viewOnly"
                  >添加</a-button
                >
              </div>
            </template>

            <template v-if="type == 'contentFormat'">
              <a-form-item label="类型:">
                <a-select
                  :disabled="viewOnly"
                  v-model="nodeData.extraInfo.type"
                  placeholder="请选择类型"
                  @change="
                    () => {
                      if (nodeData.extraInfo.type == 'format') {
                        nodeData.extraInfo.separator = ',';
                      }
                    }
                  "
                  style="width: 150px"
                >
                  <a-select-option
                    v-for="item in typeList"
                    :key="item.value"
                    :value="item.value"
                  >
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item
                label="格式化:"
                required
                v-if="nodeData.extraInfo.type == 'format'"
              >
                <a-textarea
                  :disabled="viewOnly"
                  v-model="nodeData.extraInfo.format"
                  :rows="4"
                  placeholder="select * from user where name = ${1} and age = ${2}"
                />
              </a-form-item>
              <a-form-item v-if="nodeData.extraInfo.type == 'format'">
                <span slot="label">
                  转义处理
                  <a-tooltip placement="left">
                    <div slot="title">
                      插入数据库时，有时候需要将特殊字符进行转义
                    </div>
                    <a-icon
                      type="question-circle"
                      style="color: #1879ff; font-size: 14px"
                    />
                  </a-tooltip>
                </span>
                <a-checkbox
                  v-model="nodeData.extraInfo.handleSpecialCharacter"
                  :disabled="viewOnly"
                ></a-checkbox>
              </a-form-item>
            </template>

            <template v-if="type == 'sync2Clickhouse'">
              <a-form-item label="数据库:" required>
                <a-select
                  v-model="nodeData.extraInfo.datasourceId"
                  placeholder="请选择数据库"
                  :disabled="viewOnly"
                  showSearch
                  :filterOption="false"
                  @search="searchPipelineDataSourceList"
                  @blur="searchPipelineDataSourceList()"
                  style="width: 240px"
                >
                  <a-select-option
                    v-for="item in dataSourceIdList"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="表名:" required>
                <a-input
                  @change="inputChange"
                  :disabled="viewOnly"
                  v-model="nodeData.extraInfo.tableName"
                  placeholder="请输入表名"
                />
              </a-form-item>
              <a-form-item required>
                <span slot="label">
                  插入列
                  <a-tooltip placement="left">
                    <div slot="title">多个以逗号分割</div>
                    <a-icon
                      type="question-circle"
                      style="color: #1879ff; font-size: 14px"
                    />
                  </a-tooltip>
                </span>
                <a-input
                  @change="inputChange"
                  :disabled="viewOnly"
                  v-model="nodeData.extraInfo.columns"
                  placeholder="请输入插入列"
                />
              </a-form-item>
            </template>

            <template v-if="type == 'columnSupplement'">
              <a-table
                :rowClassName="$common.rowClassColor"
                :columns="supplementColumns"
                :dataSource="supplementList"
                :pagination="false"
                bordered
                size="small"
              >
                <span slot="index" slot-scope="text, record">
                  <a-input
                    @change="inputChange"
                    :disabled="viewOnly"
                    v-model="record.index"
                    size="small"
                  />
                </span>
                <span slot="contentTitle">
                  默认值
                  <a-tooltip placement="left">
                    <div slot="title">当前列没有数据时, 填充默认值</div>
                    <a-icon
                      type="question-circle"
                      style="color: #1879ff; font-size: 14px"
                    />
                  </a-tooltip>
                </span>
                <span slot="content" slot-scope="text, record">
                  <a-input
                    @change="inputChange"
                    :disabled="viewOnly"
                    v-model="record.content"
                    size="small"
                  />
                </span>
                <span slot="action" slot-scope="text, record, index">
                  <a-button
                    type="link"
                    @click="delete_supplement(index)"
                    :style="{ color: viewOnly ? '' : 'red' }"
                    :disabled="viewOnly"
                    >删除</a-button
                  >
                </span>
              </a-table>
              <div style="margin-top: 10px">
                <a-button
                  type="primary"
                  @click="supplementAdd()"
                  :disabled="viewOnly"
                  >添加</a-button
                >
              </div>
            </template>

            <template v-if="type == 'variableGenerate'">
              <a-table
                :rowClassName="$common.rowClassColor"
                :columns="variableColumns"
                :dataSource="nodeData.extraInfo.variableList"
                :pagination="false"
                bordered
                size="small"
              >
                <span slot="row" slot-scope="text, record">
                  <a-input
                    @change="inputChange"
                    :disabled="viewOnly"
                    v-model="record.row"
                    size="small"
                  />
                </span>
                <span slot="col" slot-scope="text, record">
                  <a-input
                    @change="inputChange"
                    :disabled="viewOnly"
                    v-model="record.col"
                    size="small"
                  />
                </span>
                <span slot="name" slot-scope="text, record">
                  <a-input
                    @change="inputChange"
                    :disabled="viewOnly"
                    v-model="record.name"
                    size="small"
                  />
                </span>
                <span slot="action" slot-scope="text, record, index">
                  <a-button
                    type="link"
                    @click="delete_variable(index)"
                    :style="{ color: viewOnly ? '' : 'red' }"
                    :disabled="viewOnly"
                    >删除</a-button
                  >
                </span>
              </a-table>
              <div style="margin-top: 10px">
                <a-button
                  type="primary"
                  @click="variableAdd()"
                  :disabled="viewOnly"
                  >添加</a-button
                >
              </div>
            </template>

            <a-form-item
              label="去重资源池:"
              required
              v-if="type === 'prioritySelector'"
            >
              <a-select
                v-model="nodeData.extraInfo.poolFileName"
                placeholder="请选择文件"
                showSearch
                :filterOption="false"
                allowClear
                @search="searchPipelineLogList"
                @change="allowClearChange"
                :disabled="viewOnly"
                style="width: 240px"
              >
                <a-select-option
                  v-for="item in fileOptionsList"
                  :key="item.id"
                  :value="item.name"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              label="取数数量:"
              required
              v-if="type === 'prioritySelector'"
            >
              <a-input
                @change="inputChange"
                v-model="nodeData.extraInfo.count"
                placeholder="请输入取数数量"
                allowClear
                :disabled="viewOnly"
              />
            </a-form-item>
            <a-form-item
              label="文件列表:"
              required
              v-if="type === 'prioritySelector'"
            >
              <a-button
                type="primary"
                @click="priorityFileAdd"
                :disabled="viewOnly"
                >添加文件</a-button
              >
            </a-form-item>
            <a-form-item
              v-for="(list, index) in priorityFileList"
              :key="index"
              required
              v-if="type === 'prioritySelector'"
              :wrapper-col="{ span: 24 }"
            >
              <a-select
                v-model="list.fileName"
                placeholder="请选择文件"
                showSearch
                :filterOption="false"
                allowClear
                @search="searchPipelineLogList"
                @change="allowClearChange"
                style="width: 45%; margin-left: 140px"
                :disabled="viewOnly"
              >
                <a-select-option
                  v-for="item in fileOptionsList"
                  :key="item.id"
                  :value="item.name"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
              <a-input
                @change="inputChange"
                v-model="list.priority"
                placeholder="优先级"
                allowClear
                style="width: 15%; margin: 0 10px"
                :disabled="viewOnly"
              />
              <a-button
                type="link"
                @click="delete_fileItem(index)"
                :disabled="viewOnly"
                :style="{ color: viewOnly ? '' : 'red' }"
                >删除</a-button
              >
            </a-form-item>
            <a-form-item label="生成条数:" v-if="type === 'randomPhone'">
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.count"
                placeholder="请输入条数"
                allowClear
              />
            </a-form-item>
            <a-form-item label="选择运营商:" v-if="type === 'randomPhone'">
              <a-checkbox-group
                v-model="nodeData.extraInfo.depressOperators"
                :options="operatorsOptions"
                :disabled="viewOnly"
              >
              </a-checkbox-group>
            </a-form-item>
            <a-form-item label="返回类型:" v-if="type === 'randomPhone'">
              <a-radio-group
                v-model="nodeData.extraInfo.returnType"
                :disabled="viewOnly"
              >
                <a-radio value="phone">明文手机号</a-radio>
                <a-radio value="md5">md5手机号</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item
              label="更新资源池:"
              required
              v-if="type === 'prioritySelector'"
            >
              <a-checkbox
                v-model="nodeData.extraInfo.appendPool"
                :disabled="viewOnly"
              ></a-checkbox>
            </a-form-item>
            <a-form-item
              label="文件列表:"
              required
              v-if="type === 'multipleFileFilter'"
            >
              <a-button
                type="primary"
                @click="multipleFileAdd"
                :disabled="viewOnly"
                >添加文件</a-button
              >
            </a-form-item>
            <a-form-item
              v-for="(multipleItem, index) in multipleFileList"
              :key="index"
              required
              v-if="type === 'multipleFileFilter'"
              :wrapper-col="{ span: 24 }"
            >
              <a-select
                v-model="multipleItem.value"
                placeholder="请选择文件"
                showSearch
                :filterOption="false"
                allowClear
                @search="searchPipelineLogList"
                @change="allowClearChange"
                :disabled="viewOnly"
                style="width: 400px; margin: 0 10px 0 140px"
              >
                <a-select-option
                  v-for="item in fileOptionsList"
                  :key="item.id"
                  :value="item.name"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
              <a-button
                type="link"
                @click="delete_multipleFileItem(index)"
                :disabled="viewOnly"
                :style="{ color: viewOnly ? '' : 'red' }"
                >删除</a-button
              >
            </a-form-item>
            <a-form-item label="过滤列:" v-if="type === 'quickFilter'">
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.columnIndex"
                placeholder="请输入过滤列"
              />
            </a-form-item>
            <a-form-item label="包含运营商:" v-if="type === 'quickFilter'">
              <a-checkbox-group
                v-model="nodeData.extraInfo.includeOperator"
                :options="operatorsOptions"
                :disabled="viewOnly"
              >
              </a-checkbox-group>
            </a-form-item>
            <a-form-item label="包含区域:" v-if="type === 'quickFilter'">
              <el-cascader
                v-model="includeArea"
                :options="options"
                :props="props"
                filterable
                :filterOption="false"
                :show-all-levels="false"
                allowClear
                collapse-tags
                size="small"
                :disabled="viewOnly"
              ></el-cascader>
              <i style="display: inline-block; margin-left: 20px"></i>
              <a-tooltip placement="left">
                <div slot="title">{{ includeArea }}</div>
                <a-icon
                  type="question-circle"
                  style="color: #1879ff; font-size: 14px"
                />
              </a-tooltip>
              <i style="display: inline-block; margin-left: 20px"></i>
              <a-button
                :disabled="viewOnly"
                type="link"
                @click="fastTyping('包含')"
                >快速输入</a-button
              >
            </a-form-item>
            <a-form-item label="排除区域:" v-if="type === 'quickFilter'">
              <el-cascader
                v-model="excludeArea"
                :options="options"
                :props="props"
                filterable
                :filterOption="false"
                :show-all-levels="false"
                allowClear
                collapse-tags
                size="small"
                :disabled="viewOnly"
              ></el-cascader>
              <i style="display: inline-block; margin-left: 20px"></i>
              <a-tooltip placement="left">
                <div slot="title">{{ excludeArea }}</div>
                <a-icon
                  type="question-circle"
                  style="color: #1879ff; font-size: 14px"
                />
              </a-tooltip>
              <i style="display: inline-block; margin-left: 20px"></i>
              <a-button type="link" @click="fastTyping('排除')"
                >快速输入</a-button
              >
            </a-form-item>
            <a-form-item v-if="type === 'quickFilter'">
              <span slot="label">
                包含号段
                <a-tooltip placement="left">
                  <div slot="title">
                    多个用逗号分割,例如:130,131,如果某个号段需要排除,前面加上!,例如130,!131
                  </div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.segments"
                placeholder="请输入包含号段"
              />
            </a-form-item>
            <a-form-item v-if="type === 'quickFilter'">
              <span slot="label">
                条数限制
                <a-tooltip placement="left">
                  <div slot="title">
                    达到指定条数后,会立即中断; 流式模式下不生效
                  </div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-switch
                v-model="nodeData.extraInfo.countLimitEnabled"
                @change="() => (nodeData.extraInfo.limitCount = '')"
                :disabled="viewOnly"
              />
              <i style="display: inline-block; margin-left: 20px"></i>
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.limitCount"
                v-show="nodeData.extraInfo.countLimitEnabled"
                placeholder="请输入条数"
                style="width: 84%"
              />
            </a-form-item>
            <a-form-item label="去重:" v-if="type === 'sort'">
              <a-radio-group
                v-model="nodeData.extraInfo.unique"
                :disabled="viewOnly"
              >
                <a-radio :value="true">是</a-radio>
                <a-radio :value="false">否</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="倒序:" v-if="type === 'sort'">
              <a-radio-group
                v-model="nodeData.extraInfo.reverse"
                :disabled="viewOnly"
              >
                <a-radio :value="true">是</a-radio>
                <a-radio :value="false">否</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="列:" v-if="type === 'sort'">
              <a-select
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.columnType"
                @change="columnTypeChange"
                style="width: 100px"
              >
                <a-select-option
                  v-for="item in fileColumnTypeList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
              <template v-if="nodeData.extraInfo.columnType == 'assign'">
                <a-input
                  @change="inputChange"
                  :disabled="viewOnly"
                  v-model="nodeData.extraInfo.separator"
                  placeholder="分隔符"
                  style="width: 80px; margin-left: 10px"
                />
                <a-input
                  @change="inputChange"
                  :disabled="viewOnly"
                  v-model="nodeData.extraInfo.index"
                  placeholder="列"
                  style="width: 60px; margin: 0 10px"
                />
                <a-tooltip placement="left">
                  <div slot="title">列是否是纯数字</div>
                  <a-checkbox
                    v-model="nodeData.extraInfo.isNumber"
                    :disabled="viewOnly"
                  />
                </a-tooltip>
              </template>
            </a-form-item>
            <a-form-item v-if="type === 'replace'">
              <span slot="label">
                被替换
                <a-tooltip
                  placement="left"
                  v-if="nodeData.extraInfo.orgContentType == 'custom'"
                >
                  <div slot="title">
                    被替换内容中如果有.,需在.前面加上转义符\
                  </div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-select
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.orgContentType"
                @change="orgContentTypeChange"
                style="width: 100px"
              >
                <a-select-option
                  v-for="item in contentTypeList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-if="nodeData.extraInfo.orgContentType == 'custom'"
                v-model="nodeData.extraInfo.orgContent"
                placeholder="请输入被替换内容"
                style="margin-left: 5px; width: 200px"
              />
            </a-form-item>
            <a-form-item label="替换:" v-if="type === 'replace'">
              <a-select
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.newContentType"
                @change="newContentTypeChange"
                style="width: 100px"
              >
                <a-select-option
                  v-for="item in contentTypeList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-if="nodeData.extraInfo.newContentType == 'custom'"
                v-model="nodeData.extraInfo.newContent"
                placeholder="请输入替换内容"
                style="margin-left: 5px; width: 200px"
              />
            </a-form-item>
            <a-form-item label="类型:" v-if="type === 'cut'">
              <a-select
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.cutType"
                placeholder="请选择类型"
                @change="cutTypeChange"
                style="width: 120px"
              >
                <a-select-option
                  v-for="item in cutTypeList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              v-if="type === 'cut' && nodeData.extraInfo.cutType === '分隔截取'"
              :wrapper-col="{ span: 24 }"
            >
              <div style="margin-left: 93px">
                分隔符:
                <a-input
                  @change="inputChange"
                  :disabled="viewOnly"
                  v-model="nodeData.extraInfo.separator"
                  placeholder="分隔符"
                  style="width: 120px; margin: 0 10px"
                />
                截取下标:
                <a-input
                  @change="inputChange"
                  :disabled="viewOnly"
                  v-model="nodeData.extraInfo.targetIndex"
                  placeholder="截取下标"
                  style="width: 120px; margin-left: 10px"
                />
              </div>
            </a-form-item>
            <a-form-item
              v-if="type === 'cut' && nodeData.extraInfo.cutType === '固定长度'"
              :wrapper-col="{ span: 24 }"
            >
              <div style="margin-left: 93px">
                从
                <a-input
                  @change="inputChange"
                  :disabled="viewOnly"
                  v-model="nodeData.extraInfo.startIndex"
                  placeholder="起始位置"
                  style="width: 100px; margin: 0 10px"
                />
                到
                <a-input
                  @change="inputChange"
                  :disabled="viewOnly"
                  v-model="nodeData.extraInfo.endIndex"
                  placeholder="结束位置"
                  style="width: 100px; margin-left: 10px"
                />
              </div>
            </a-form-item>
            <a-form-item
              label="参数:"
              v-if="type === 'cut' && nodeData.extraInfo.cutType === '自定义'"
            >
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.param"
                placeholder="请输入参数"
              />
            </a-form-item>
            <a-form-item label="日期格式:" v-if="type === 'columnDateFilter'">
              <a-select
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.type"
                placeholder="请选择日期格式"
                style="width: 150px"
              >
                <a-select-option
                  v-for="item in dateTypeList"
                  :key="item.id"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="日期范围起:" v-if="type === 'columnDateFilter'">
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.start"
                placeholder="请输入日期"
                style="width: 200px; margin-right: 10px"
              />
              <a-button type="link" @click="selectDate('start')"
                >选择日期</a-button
              >
            </a-form-item>
            <a-form-item label="日期范围止:" v-if="type === 'columnDateFilter'">
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.end"
                placeholder="请输入日期"
                style="width: 200px; margin-right: 10px"
              />
              <a-button type="link" @click="selectDate('end')"
                >选择日期</a-button
              >
            </a-form-item>
            <a-form-item label="过滤:" v-if="type === 'columnDateFilter'">
              <a-switch
                class="greenSwitch"
                checked-children="包含"
                un-checked-children="排除"
                v-model="nodeData.extraInfo.include"
                :disabled="viewOnly"
              />
            </a-form-item>
            <a-form-item v-if="type === 'columnDateFilter'">
              <span slot="label">
                日期所在列
                <a-tooltip placement="left">
                  <div slot="title">列序号从1开始计数</div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.index"
                placeholder="请输入日期所在列"
              />
            </a-form-item>
            <a-form-item
              label="数据集:"
              v-if="type === 'calculate'"
              style="margin-bottom: 0"
              :label-col="{ span: 2 }"
            >
              <a-button
                type="primary"
                size="small"
                @click="calculateKeyDataAdd"
                :disabled="viewOnly"
                >添加数据集</a-button
              >
            </a-form-item>
            <a-form-item
              v-for="(keyData, index) in calculateKeyDataList"
              :key="'keyData' + index"
              required
              v-if="type === 'calculate'"
              style="margin-bottom: 0"
              :wrapper-col="{ span: 24 }"
            >
              <a-input
                @change="inputChange"
                v-model="keyData.key"
                placeholder="key"
                style="width: 20%; margin: 0 5px 0 55px"
                :disabled="viewOnly"
                size="small"
              />
              <a-input
                @change="inputChange"
                v-model="keyData.function"
                placeholder="函数"
                style="width: 40%; margin-right: 5px"
                :disabled="viewOnly"
                size="small"
              />
              <a-select
                :disabled="viewOnly"
                v-model="keyData.validSeconds"
                placeholder="选择有效期"
                style="width: 15%; margin-right: 5px"
                size="small"
              >
                <a-select-option
                  v-for="item in validSecondsList"
                  :key="item.id"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
              <a-button
                type="link"
                @click="delete_calculateKeyData(index)"
                :disabled="viewOnly"
                :style="{ color: viewOnly ? '' : 'red' }"
                size="small"
                >删除</a-button
              >
            </a-form-item>
            <a-form-item
              label="条件:"
              v-if="type === 'calculate'"
              style="margin-bottom: 0"
              :label-col="{ span: 2 }"
            >
              <a-button
                type="primary"
                size="small"
                @click="calculateConditionAdd"
                :disabled="viewOnly"
                >添加条件</a-button
              >
            </a-form-item>
            <a-form-item
              v-for="(condition, index) in calculateConditionList"
              :key="'condition' + index"
              required
              v-if="type === 'calculate'"
              style="margin-bottom: 0"
              :wrapper-col="{ span: 24 }"
            >
              <span style="margin: 0 5px 0 55px">
                {{
                  index == calculateConditionList.length - 1
                    ? "否则输出"
                    : "如果"
                }}
              </span>
              <a-input
                @change="inputChange"
                v-model="condition.expression"
                placeholder="运算表达式"
                style="width: 35%; margin-right: 5px"
                v-if="index !== calculateConditionList.length - 1"
                size="small"
                :disabled="viewOnly"
              />
              <span
                v-if="index !== calculateConditionList.length - 1"
                style="margin-right: 5px"
              >
                ,输出
              </span>
              <a-input
                @change="inputChange"
                v-model="condition.tag"
                placeholder="输出标签"
                style="width: 13%; margin-right: 5px"
                size="small"
                :disabled="viewOnly"
              />
              <a-input
                @change="inputChange"
                v-model="condition.value"
                placeholder="输出值"
                style="width: 13%; margin-right: 5px"
                size="small"
                :disabled="viewOnly"
              />
              <a-button
                type="link"
                @click="delete_calculateCondition(index)"
                :disabled="viewOnly"
                v-if="
                  index !== calculateConditionList.length - 1 && index !== 0
                "
                :style="{ color: viewOnly ? '' : 'red' }"
                size="small"
                >删除</a-button
              >
            </a-form-item>
            <a-form-item
              label="过滤表头:"
              v-if="type === 'databaseLoad'"
              :label-col="{ span: 5 }"
              :wrapper-col="{ span: 19 }"
            >
              <a-checkbox
                v-model="nodeData.extraInfo.skipTitle"
                :disabled="viewOnly"
              ></a-checkbox>
            </a-form-item>
            <a-form-item
              v-if="
                type === 'databaseLoad' && nodeData.extraInfo.active !== '3'
              "
              :label-col="{ span: 5 }"
              :wrapper-col="{ span: 19 }"
            >
              <span slot="label">
                是否是maxwell
                <a-tooltip placement="left">
                  <div slot="title">只有txt格式的文件才生效</div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-checkbox
                v-model="nodeData.extraInfo.maxwell"
                :disabled="viewOnly"
              ></a-checkbox>
            </a-form-item>
            <a-form-item label="正则表达式:" v-if="type === 'contentFilter'">
              <a-textarea
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.regex"
                placeholder="请输入正则表达式"
                :rows="4"
              />
              <a-button type="link" @click="produceRegex">生成表达式</a-button>
            </a-form-item>
            <a-form-item label="分割类型:" v-if="type === 'fileSplit'">
              <a-select
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.splitType"
                placeholder="请选择分割类型"
                style="width: 150px"
              >
                <a-select-option
                  v-for="item in splitTypeList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="值:" v-if="type === 'fileSplit'">
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.splitNum"
                placeholder="请输入值"
              />
            </a-form-item>
            <a-form-item label="长度范围:" v-if="type === 'lengthFilter'">
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.scope"
                placeholder="请输入长度范围"
              />
            </a-form-item>
            <a-form-item label="例:" v-if="type === 'lengthFilter'">
              <div class="regexText">
                <div>[1-22][23-33][34-99]</div>
              </div>
            </a-form-item>
            <a-form-item label="被追加文件:" v-if="type === 'contentAppend'">
              <a-select
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.fileName"
                placeholder="请选择文件"
                showSearch
                :filterOption="false"
                allowClear
                @search="searchPipelineLogList"
                @change="allowClearChange"
                style="width: 240px"
              >
                <a-select-option
                  v-for="item in fileOptionsList"
                  :key="item.id"
                  :value="item.name"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="自身去重:" v-if="type === 'contentAppend'">
              <a-checkbox
                v-model="nodeData.extraInfo.unique"
                :disabled="viewOnly"
              ></a-checkbox>
            </a-form-item>
            <a-form-item v-if="type === 'contentAppend'">
              <span slot="label">
                覆盖源文件
                <a-tooltip placement="left">
                  <div slot="title">追加前先清空原文件, 这是一个危险操作</div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-checkbox
                v-model="nodeData.extraInfo.rewrite"
                :disabled="viewOnly"
              ></a-checkbox>
            </a-form-item>
            <a-form-item v-if="type === 'shell'" :wrapper-col="{ span: 24 }">
              <a-textarea
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.cmd"
                placeholder="请输入命令"
                :rows="15"
              />
              <h3 style="color: red">#1表示序号为 1的文件</h3>
            </a-form-item>
            <a-form-item label="数量:" v-if="type === 'shufSelector'">
              <a-select
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.type"
                placeholder="请选择类型"
                style="width: 100px; margin-right: 10px"
              >
                <a-select-option
                  v-for="item in shufSelectorTypeList"
                  :key="item.id"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>

              <a-input
                @change="inputChange"
                v-if="nodeData.extraInfo.type == 'constant'"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.count"
                placeholder="请输入数量"
                style="width: 150px"
              />
              <template v-else>
                <a-input
                  @change="inputChange"
                  :disabled="viewOnly"
                  v-model="nodeData.extraInfo.ratio"
                  placeholder="请输入数量"
                  style="width: 150px"
                />
                %
              </template>
            </a-form-item>
            <a-form-item label="类型:" v-if="type === 'blacklistFilter'">
              <a-select
                :disabled="viewOnly"
                v-model="blacklistFilterSource"
                placeholder="请选择类型"
                mode="multiple"
                style="width: 240px"
              >
                <a-select-option
                  v-for="item in blackNameList"
                  :key="item"
                  :value="item"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              label="时间范围:"
              v-if="type === 'fileCollectDistinct'"
            >
              <a-select
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.days"
                placeholder="请选择时间范围"
                style="width: 240px"
              >
                <a-select-option
                  v-for="item in daysList"
                  :key="item.id"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="参数:" v-if="type === 'phone2Guishudi'">
              <a-checkbox
                v-model="nodeData.extraInfo.needProvince"
                :disabled="viewOnly"
              >
                省
              </a-checkbox>
              <a-checkbox
                v-model="nodeData.extraInfo.needCity"
                :disabled="viewOnly"
              >
                市
              </a-checkbox>
              <a-checkbox
                v-model="nodeData.extraInfo.needOperator"
                :disabled="viewOnly"
              >
                运营商
              </a-checkbox>
            </a-form-item>
            <a-tabs
              v-if="type === 'fileSelect'"
              v-model="nodeData.extraInfo.active"
            >
              <a-tab-pane tab="文件列表文件" key="1"></a-tab-pane>
              <a-tab-pane tab="任务输出文件" key="2"></a-tab-pane>
            </a-tabs>
            <a-form-item
              label="选择任务:"
              v-if="type === 'fileSelect' && nodeData.extraInfo.active == '2'"
            >
              <a-select
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.taskId"
                placeholder="请选择任务"
                showSearch
                :filterOption="false"
                allowClear
                @search="searchFileGroupList"
                @blur="searchFileGroupList()"
                @change="getTaskFileList()"
              >
                <a-select-option
                  v-for="item in fileGroupList"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              label="选择文件:"
              v-if="type === 'fileSelect' && nodeData.extraInfo.active == '1'"
            >
              <a-select
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.fileId"
                placeholder="请选择文件"
                showSearch
                :filterOption="false"
                allowClear
                @search="searchPipelineLogList"
                @change="allowClearChange"
              >
                <a-select-option
                  v-for="item in fileOptionsList"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              label="选择文件:"
              v-if="type === 'fileSelect' && nodeData.extraInfo.active == '2'"
            >
              <a-select
                v-model="nodeData.extraInfo.fileIdList"
                placeholder="请选择文件"
                mode="multiple"
                collapse-tags
                :disabled="!nodeData.extraInfo.taskId || viewOnly"
              >
                <a-select-option
                  v-for="item in taskFileList"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.fileName }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="选择任务:" v-if="type === 'parseJobFileSelect'">
              <a-select
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.taskId"
                placeholder="请选择任务"
                showSearch
                :filterOption="false"
                allowClear
                @search="searchParseJobFileTask"
                @blur="searchParseJobFileTask()"
                @change="getParseJobFileList"
              >
                <a-select-option
                  v-for="item in parseJobFileTaskList"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ `${item.id}-${item.name}` }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="选择文件:" v-if="type === 'parseJobFileSelect'">
              <a-select
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.fileId"
                placeholder="请选择文件"
                allowClear
              >
                <a-select-option
                  v-for="item in parseJobFileList"
                  :key="item.fileId"
                  :value="item.fileId"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="请求编号:" v-if="type === 'parseJobPipeline'">
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.requestId"
                placeholder="请输入请求编号"
              />
            </a-form-item>
            <a-form-item label="accessKey:" v-if="type === 'parseJobPipeline'">
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.accessKey"
                placeholder="请输入accessKey"
              />
            </a-form-item>
            <a-form-item label="任务类型:" v-if="type === 'parseJobPipeline'">
              <a-select
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.taskType"
                placeholder="请选择任务类型"
                showSearch
                :filterOption="false"
                @search="taskListFilterOption"
                @change="loadDynamicApiData('availableCount')"
              >
                <a-select-option
                  v-for="item in taskList"
                  :key="item.id"
                  :value="item.en"
                >
                  {{ item.cn }}
                </a-select-option>
              </a-select>
              <span
                class="dropbox-ts"
                style="color: #52c41a"
                v-if="nodeData.extraInfo.availableCount !== ''"
              >
                剩余可用量: {{ nodeData.extraInfo.availableCount }}
              </span>
            </a-form-item>
            <a-form-item v-if="type === 'parseJobPipeline'">
              <span slot="label">
                限量控制
                <a-tooltip placement="left">
                  <div slot="title">
                    假设当前剩余可用量为100，当前文件为150，则只能撞100的量，有
                    50会被忽略掉
                  </div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-checkbox
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.autoAdjustDumpCount"
              ></a-checkbox>
            </a-form-item>
            <a-form-item label="类型:" v-if="type === 'zipMerge'">
              <a-select
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.type"
                placeholder="请选择类型"
                style="width: 120px"
              >
                <a-select-option
                  v-for="item in zipMergeTypeList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              v-if="type === 'columnAppend'"
              :wrapper-col="{ span: 19 }"
            >
              <span slot="label">
                追加内容
                <a-tooltip placement="left">
                  <div slot="title">顺序取值和随机取值用英文状态下逗号隔开</div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-select
                v-model="nodeData.extraInfo.type"
                placeholder="请选择类型"
                :disabled="viewOnly"
                style="width: 150px"
              >
                <a-select-option
                  v-for="item in columnAppendTypeList"
                  :key="item.id"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
              <template
                v-if="
                  nodeData.extraInfo.type !== 'DYNAMIC_COLUMN_CONNECT' &&
                  nodeData.extraInfo.type !== 'TAG'
                "
              >
                <a-input
                  @change="inputChange"
                  :disabled="viewOnly"
                  v-model="nodeData.extraInfo.content"
                  :placeholder="
                    nodeData.extraInfo.type == 'ORDER_ACCESS' ||
                    nodeData.extraInfo.type == 'RANDOM_ACCESS'
                      ? 'a,b,c,d,e'
                      : '请输入追加内容'
                  "
                  v-if="nodeData.extraInfo.type !== 'SERIAL_NUMBER_INCR'"
                  style="width: 250px; margin-left: 5px"
                />
                <template v-else>
                  <a-input
                    @change="inputChange"
                    :disabled="viewOnly"
                    v-model="nodeData.extraInfo.initValue"
                    placeholder="请输入初始值"
                    style="width: 140px; margin-left: 5px"
                  />
                  <a-input
                    @change="inputChange"
                    :disabled="viewOnly"
                    v-model="nodeData.extraInfo.step"
                    placeholder="请输入间隔"
                    style="width: 140px; margin-left: 5px"
                  />
                </template>
              </template>
            </a-form-item>
            <a-form-item
              required
              v-if="
                type == 'columnAppend' &&
                (nodeData.extraInfo.type == 'CALCULATE' ||
                  nodeData.extraInfo.type == 'CALCULATE_4F')
              "
            >
              <span slot="label">
                异常填充
                <a-tooltip placement="left">
                  <div slot="title">
                    例如分母为0导致计算异常时, 填充指定字符串
                  </div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.arithmeticExceptionFilling"
                placeholder="请输入异常填充"
                style="width: 120px"
              />
            </a-form-item>
            <a-form-item
              required
              v-if="
                (type === 'zipMerge' &&
                  nodeData.extraInfo.type == 'excel2txt') ||
                type == 'join' ||
                type == 'columnFilter' ||
                type == 'columnDistinct' ||
                type === 'columnRearrange' ||
                type === 'jsonFormat' ||
                type === 'textToExcel' ||
                type === 'excel2Txt' ||
                type === 'columnRandomOffset' ||
                type === 'columnConversion' ||
                type === 'columnDateFilter' ||
                (type == 'columnAppend' &&
                  nodeData.extraInfo.type !== 'CONTENT') ||
                type == 'columnGroupAndFetch'
              "
            >
              <span slot="label">
                分隔符
                <a-tooltip
                  placement="left"
                  v-if="
                    type === 'columnRearrange' ||
                    type === 'jsonFormat' ||
                    type === 'excel2Txt'
                  "
                >
                  <div slot="title">
                    <template v-if="type === 'columnRearrange'">
                      用此分隔符分割文件得到列,一般是逗号
                    </template>
                    <template v-if="type === 'jsonFormat'">
                      取值后以该分隔符连接
                    </template>
                    <template v-if="type === 'excel2Txt'">
                      转成文本后，用该分隔符连接多列
                    </template>
                    <template v-if="type === 'columnAppend'">
                      分隔符会自动添加，将新增的内容与原文连在一起
                    </template>
                  </div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-select
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.separatorType"
                placeholder="请选择分隔符"
                style="width: 100px"
                @change="
                  nodeData.extraInfo.separator =
                    nodeData.extraInfo.separatorType
                "
              >
                <a-select-option
                  v-for="item in separatorList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-if="nodeData.extraInfo.separatorType == ''"
                v-model="nodeData.extraInfo.separator"
                placeholder="请输入分隔符"
                style="width: 200px; margin-left: 10px"
              />
            </a-form-item>
            <a-form-item
              v-if="type === 'columnConversion' || type == 'columnRandomOffset'"
            >
              <span slot="label">
                下标
                <a-tooltip placement="left">
                  <div slot="title">列序号从1开始计数</div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.index"
                placeholder="请输入下标"
              />
            </a-form-item>
            <a-form-item label="类型:" v-if="type === 'columnConversion'">
              <a-select
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.type"
                placeholder="请选择类型"
                style="width: 45%; margin-right: 2%"
                @change="columnConversionTypeChange"
              >
                <a-select-option
                  v-for="(item, index) in columnTypeList"
                  :key="index"
                  :value="item.code"
                >
                  {{ item.desc }}
                </a-select-option>
              </a-select>
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.fields"
                placeholder="请输入fields"
                v-if="nodeData.extraInfo.type == 'JSON_VALUE'"
                style="width: 53%"
              />
            </a-form-item>
            <a-form-item
              v-if="
                type === 'columnConversion' &&
                nodeData.extraInfo.type == 'REPLACE'
              "
            >
              <span slot="label">
                文本替换旧值
                <a-tooltip placement="left">
                  <div slot="title">
                    支持正则,例如[1,3,5,7,9]表示会同时替换13579
                  </div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.replaceOld"
                placeholder="请输入文本替换旧值"
              />
            </a-form-item>
            <a-form-item
              label="文本替换新值:"
              v-if="
                type === 'columnConversion' &&
                nodeData.extraInfo.type == 'REPLACE'
              "
            >
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.replaceNew"
                placeholder="请输入文本替换新值"
              />
            </a-form-item>
            <a-form-item
              label="split取数分隔符:"
              v-if="
                type === 'columnConversion' &&
                nodeData.extraInfo.type == 'SPLIT'
              "
            >
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.splitSeparator"
                placeholder="请输入split取数分隔符"
              />
            </a-form-item>
            <a-form-item
              label="split取数下标:"
              v-if="
                type === 'columnConversion' &&
                nodeData.extraInfo.type == 'SPLIT'
              "
            >
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.splitIndex"
                placeholder="请输入split取数下标"
              />
            </a-form-item>
            <a-form-item
              v-if="
                type === 'columnConversion' &&
                nodeData.extraInfo.type == 'SUBSTRING'
              "
            >
              <span slot="label">
                表达式
                <a-tooltip placement="left">
                  <div slot="title">
                    1,3表示从第一个字符开始，截取三个字符
                    <br />
                    -3,3 表示取后位字符
                  </div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.splitExpression"
                placeholder="请输入表达式"
              />
            </a-form-item>
            <a-form-item
              label="补充到多少长度:"
              v-if="
                type === 'columnConversion' &&
                nodeData.extraInfo.type == 'COLUMN_SUPPLEMENT'
              "
            >
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.length"
                placeholder="请输入长度"
              />
            </a-form-item>
            <a-form-item
              label="补充字符:"
              v-if="
                type === 'columnConversion' &&
                nodeData.extraInfo.type == 'COLUMN_SUPPLEMENT'
              "
            >
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.chars"
                placeholder="请输入字符"
              />
            </a-form-item>
            <a-form-item
              v-if="
                type === 'columnConversion' &&
                nodeData.extraInfo.type == 'DATE_CALCULATE'
              "
            >
              <span slot="label">
                偏移量
                <a-tooltip placement="left">
                  <div slot="title">
                    例如, -30d, +20d 目前仅支持天级别的日期计算，
                    并要求日期格式为yyyy-MM-dd
                  </div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.offset"
                placeholder="请输入偏移量"
              />
            </a-form-item>
            <a-form-item label="列类型:" v-if="type === 'columnRandomOffset'">
              <a-select
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.columnType"
                placeholder="请选择列类型"
                style="width: 120px"
              >
                <a-select-option
                  v-for="(item, index) in randomOffsetColumnTypeList"
                  :key="index"
                  :value="item.type"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="范围:" v-if="type === 'columnRandomOffset'">
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.minOffset"
                placeholder="最小偏移量"
                style="width: 100px"
              />
              到
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.maxOffset"
                placeholder="最大偏移量"
                style="width: 100px"
              />
            </a-form-item>
            <a-form-item v-if="type === 'zipMerge'">
              <span slot="label">
                追加文件名
                <a-tooltip placement="left">
                  <div slot="title">开启后,每列后面都会带上当前文件名</div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-switch
                checked-children="追加"
                un-checked-children="不追加"
                v-model="nodeData.extraInfo.appendFileName"
                :disabled="viewOnly"
              />
            </a-form-item>
            <a-form-item
              label="移除头部行数:"
              v-if="type === 'zipMerge' || type === 'excel2Txt'"
            >
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.removeHeaderLineCount"
                placeholder="请输入移除头部行数"
              />
            </a-form-item>
            <a-form-item label="密码:" v-if="type === 'zipMerge'">
              <a-switch
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.passwordSwitch"
                active-color="#13ce66"
                inactive-color="#ff4949"
              />
              <template v-if="nodeData.extraInfo.passwordSwitch">
                <a-input
                  @change="inputChange"
                  :disabled="viewOnly"
                  v-model="nodeData.extraInfo.unzipPassword"
                  placeholder="请输入密码"
                  style="width: 250px; margin: 0 5px"
                />
                <a-button @click="random" type="link">随机</a-button>
              </template>
            </a-form-item>
            <a-form-item
              :label="'主文件' + (type == 'join' ? '(o1)' : '列') + '下标:'"
              v-if="type === 'join' || type === 'columnJoinFilter'"
            >
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.matchIndexOne"
                placeholder="请输入主文件下标"
                style="width: 160px"
              />
            </a-form-item>
            <a-form-item
              :label="'关联文件' + (type == 'join' ? '(o2)' : '列') + '下标:'"
              v-if="type === 'join' || type === 'columnJoinFilter'"
            >
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.matchIndexTwo"
                placeholder="请输入关联文件下标"
                style="width: 160px"
              />
            </a-form-item>
            <a-form-item
              v-if="type === 'join'"
              :label-col="{ span: 5 }"
              :wrapper-col="{ span: 19 }"
            >
              <span slot="label">
                输出格式
                <a-tooltip placement="left">
                  <div slot="title">
                    记两个文件分别为o1,o2,
                    输出o1的第一个列和o2的第三列可以表示成 -o1.1 -o2.3
                  </div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <div style="width: 80%; display: inline-block">
                <template v-if="nodeData.extraInfo.outputFormaSelectShow">
                  <template v-for="(item, index) in outputFormatList">
                    <a-tag
                      :key="item.id"
                      :color="item.color"
                      style="margin-top: 5px"
                      closable
                      @close="deleteOutputFormat(item)"
                    >
                      {{ item.label }}
                    </a-tag>
                  </template>
                  <a-button type="link" @click="setOutputFormat">选择</a-button>
                </template>
                <a-input
                  v-else
                  @change="inputChange"
                  :disabled="viewOnly"
                  v-model="nodeData.extraInfo.outputFormat"
                  placeholder="请输入输出格式"
                />
              </div>
              <div style="margin-left: 10px; display: inline-block">
                <a-button
                  type="link"
                  @click="
                    nodeData.extraInfo.outputFormaSelectShow =
                      !nodeData.extraInfo.outputFormaSelectShow
                  "
                >
                  {{
                    nodeData.extraInfo.outputFormaSelectShow
                      ? "选择模式"
                      : "输入模式"
                  }}
                </a-button>
              </div>
            </a-form-item>
            <a-form-item v-if="type === 'columnFilter'">
              <span slot="label">
                表达式
                <a-tooltip placement="left">
                  <div slot="title">
                    ${1}表示第一列, ${2}表示第二列以此类推, 表达式示例: <br />
                    示例1,第一列值在1000到2000之间: ${1} >=1000 && ${1} <=2000
                    <br />
                    示例2,第二列值等于SUCCESS: ${2} == 'SUCCESS' <br />
                    示例3,符合示例1或符合示例2: (${1} >=1000 && ${1} <=2000) ||
                    (${2} == 'SUCCESS')
                  </div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-textarea
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.expression"
                placeholder="请输入表达式"
                :rows="5"
              />
            </a-form-item>
            <a-form-item v-if="type === 'columnDistinct'">
              <span slot="label">
                排序列
                <a-tooltip placement="left">
                  <div slot="title">列序号从1开始计数</div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-radio-group
                v-model="nodeData.extraInfo.asc"
                :disabled="viewOnly"
                style="width: 150px"
              >
                <a-radio :value="true">升序</a-radio>
                <a-radio :value="false">降序</a-radio>
              </a-radio-group>
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.sortIndex"
                placeholder="请输入排序列"
                style="width: 200px; margin: 0 10px"
              />
              <a-tooltip placement="left">
                <div slot="title">是否为数值类型</div>
                <a-checkbox
                  :disabled="viewOnly"
                  v-model="nodeData.extraInfo.columnIsNumber"
                ></a-checkbox>
              </a-tooltip>
            </a-form-item>
            <a-form-item label="自定义排序" v-if="type === 'columnDistinct'">
              <a-checkbox
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.customerSortShow"
                @change="customerSortChange"
                style="vertical-align: top"
              ></a-checkbox>
              <a-textarea
                v-if="nodeData.extraInfo.customerSortShow"
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.customerSortString"
                placeholder="自定义排序"
                autoSize
                style="width: 90%; margin-left: 10px"
              />
            </a-form-item>
            <a-form-item v-if="type === 'columnDistinct'">
              <span slot="label">
                去重列
                <a-tooltip placement="left">
                  <div slot="title">
                    列序号从1开始计数 <br />
                    如果选择字符串类型, 排序如下: <br />
                    123 <br />
                    2 <br />
                    333 <br />
                    如果选择数值类型, 排序如下: <br />
                    2 <br />
                    123 <br />
                    333
                  </div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.distinctIndex"
                placeholder="请输入去重列"
                style="width: 200px"
              />
            </a-form-item>

            <div
              style="width: 600px; margin-left: 50px"
              v-if="
                type == 'columnAppend' &&
                nodeData.extraInfo.type == 'DYNAMIC_COLUMN_CONNECT'
              "
            >
              <a-table
                :rowClassName="$common.rowClassColor"
                :columns="dynamicColumnColumns"
                :dataSource="dynamicColumnData"
                :pagination="false"
                bordered
                size="small"
              >
                <span slot="columnTitle">
                  列
                  <a-tooltip placement="left">
                    <div slot="title">例如: ${1}, ${1}_abc</div>
                    <a-icon
                      type="question-circle"
                      style="color: #1879ff; font-size: 14px"
                    />
                  </a-tooltip>
                </span>
                <span slot="expressionTitle">
                  表达式
                  <a-tooltip placement="left">
                    <div slot="title">例如 ${1}!='0000-0000'</div>
                    <a-icon
                      type="question-circle"
                      style="color: #1879ff; font-size: 14px"
                    />
                  </a-tooltip>
                </span>
                <span slot="column" slot-scope="text, record">
                  <a-input
                    @change="inputChange"
                    v-model="record.column"
                    size="small"
                  />
                </span>
                <span slot="expression" slot-scope="text, record">
                  <a-input
                    @change="inputChange"
                    v-model="record.expression"
                    size="small"
                  />
                </span>
                <span slot="action" slot-scope="text, record, index">
                  <a-button
                    type="link"
                    @click="delete_dynamicColumn(index)"
                    :style="{ color: viewOnly ? '' : 'red' }"
                    :disabled="viewOnly"
                    >删除</a-button
                  >
                </span>
              </a-table>
              <div style="margin-top: 10px">
                <a-button
                  type="primary"
                  @click="dynamicColumnAdd()"
                  :disabled="viewOnly"
                  >添加</a-button
                >
              </div>
            </div>
            <a-form-item
              label="追加到行首/行尾:"
              v-if="
                type === 'columnAppend' && nodeData.extraInfo.type !== 'TAG'
              "
            >
              <a-switch
                class="greenSwitch"
                checked-children="追加到行首"
                un-checked-children="追加到行尾"
                v-model="nodeData.extraInfo.appendAtLineHead"
                :disabled="viewOnly"
              />
            </a-form-item>
            <template
              v-if="type === 'columnAppend' && nodeData.extraInfo.type == 'TAG'"
            >
              <a-form-item label="客户编号列:">
                <a-input
                  @change="inputChange"
                  :disabled="viewOnly"
                  v-model="nodeData.extraInfo.customerCodeIndex"
                  placeholder="请输入客户编号列"
                />
              </a-form-item>
              <a-form-item label="标签:">
                <a-select
                  :disabled="viewOnly"
                  v-model="columnAppendTagList"
                  placeholder="请选择标签"
                  mode="multiple"
                >
                  <a-select-option
                    v-for="(item, index) in tagTypeList"
                    :key="index"
                    :value="item.code"
                  >
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </template>
            <a-form-item v-if="type === 'limiter' || type == 'concurrentLimit'">
              <span slot="label">
                业务Key
                <a-tooltip placement="left" v-if="type == 'concurrentLimit'">
                  <div slot="title">只有设置为同一个 key才会相互限制</div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.key"
                placeholder="请输入业务Key"
              />
            </a-form-item>
            <a-form-item v-if="type === 'limiter' || type == 'concurrentLimit'">
              <span slot="label">
                {{ type === "limiter" ? "每日总量" : "同时运行并发数" }}
              </span>
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.count"
                placeholder="请输入每日总量"
                type="number"
              />
            </a-form-item>
            <a-form-item label="是否启用列去重:" v-if="type === 'diff'">
              <a-switch
                class="greenSwitch"
                checked-children="是"
                un-checked-children="否"
                v-model="nodeData.extraInfo.enableColumnDiff"
                :disabled="viewOnly"
              />
            </a-form-item>
            <a-form-item v-if="type === 'diff' || type === 'intersection'">
              <span slot="label">
                是否启用类型检验
                <a-tooltip placement="left">
                  <div slot="title">
                    开启后将检查输入的两个文件是否类型一致,
                    如果不一致(例如一个是phoneMd5,一个是customerCode)则直接报错
                  </div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-switch
                class="greenSwitch"
                checked-children="是"
                un-checked-children="否"
                v-model="nodeData.extraInfo.checkFileType"
                :disabled="viewOnly"
              />
            </a-form-item>
            <a-form-item
              label="被去重文件列分隔符:"
              v-if="type === 'diff' && nodeData.extraInfo.enableColumnDiff"
            >
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.targetDelimiter"
                placeholder="请输入被去重文件列分隔符"
              />
            </a-form-item>
            <a-form-item
              label="被去重文件列下标:"
              v-if="type === 'diff' && nodeData.extraInfo.enableColumnDiff"
            >
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.targetFieldIndex"
                placeholder="请输入被去重文件列下标"
              />
            </a-form-item>
            <a-form-item
              label="去重文件列分隔符:"
              v-if="type === 'diff' && nodeData.extraInfo.enableColumnDiff"
            >
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.poolDelimiter"
                placeholder="请输入去重文件列分隔符"
              />
            </a-form-item>
            <a-form-item
              label="去重文件列下标:"
              v-if="type === 'diff' && nodeData.extraInfo.enableColumnDiff"
            >
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.poolFieldIndex"
                placeholder="请输入去重文件列下标"
              />
            </a-form-item>
            <a-form-item label="分组分隔符:" v-if="type === 'columnGroup'">
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.delimiter"
                placeholder="请输入去重文件列下标"
              />
            </a-form-item>
            <a-form-item v-if="type === 'columnGroup'">
              <span slot="label">
                分组下标
                <a-tooltip placement="left">
                  <div slot="title">列序号从1开始计数</div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.groupIndex"
                placeholder="请输入分组下标"
              />
            </a-form-item>
            <a-form-item label="移除分组列:" v-if="type === 'columnGroup'">
              <a-switch
                class="greenSwitch"
                checked-children="移除"
                un-checked-children="保留"
                v-model="nodeData.extraInfo.removeColumn"
                :disabled="viewOnly"
              />
            </a-form-item>
            <a-form-item
              v-if="type === 'manualInput' || type === 'variableExtract'"
              :wrapper-col="{ span: 24 }"
            >
              <a-textarea
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.content"
                placeholder="请输入内容"
                :rows="15"
              />
            </a-form-item>
            <a-form-item label="请求编号:" v-if="type === 'httpSender'">
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.requestId"
                placeholder="请输入请求编号"
              />
            </a-form-item>
            <a-form-item label="接口地址:" v-if="type === 'httpSender'">
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.url"
                placeholder="请输入接口地址"
              />
            </a-form-item>
            <a-form-item label="状态码:" v-if="type === 'httpSender'">
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.status"
                placeholder="请输入状态码"
              />
            </a-form-item>
            <a-form-item label="接口提示:" v-if="type === 'httpSender'">
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.message"
                placeholder="请输入接口提示"
              />
            </a-form-item>
            <a-form-item
              label="请求编号:"
              v-if="type === 'httpReceiver' || type == 'apiLogToDzDkData'"
            >
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.requestId"
                placeholder="请输入请求编号"
              />
            </a-form-item>
            <a-form-item label="延迟类型:" v-if="type === 'delay'">
              <a-select
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.delayType"
                placeholder="请选择延迟类型"
                style="width: 120px"
              >
                <a-select-option
                  v-for="item in delayTypeList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              label="延迟秒数:"
              v-if="
                type === 'delay' &&
                nodeData.extraInfo.delayType == 'FIXED_SECONDS'
              "
            >
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.delaySecond"
                placeholder="请输入延迟秒数"
              />
            </a-form-item>
            <a-form-item
              label="执行时间:"
              v-if="
                type === 'delay' && nodeData.extraInfo.delayType == 'FIXED_DATE'
              "
            >
              <a-date-picker
                v-model="nodeData.extraInfo.executeDateTime"
                placeholder="选择日期"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
                :disabled="viewOnly"
              />
            </a-form-item>
            <a-form-item label="分隔符:" v-if="type === 'multipleColumn2One'">
              <a-button
                type="primary"
                @click="delimitersAdd"
                :disabled="viewOnly"
                >添加分隔符</a-button
              >
            </a-form-item>
            <a-form-item
              v-for="(delimiter, index) in delimitersList"
              :key="index"
              required
              v-if="type === 'multipleColumn2One'"
              :wrapper-col="{ span: 24 }"
            >
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="delimiter.value"
                placeholder="请输入分隔符"
                style="width: 200px; margin: 0 5px 0 135px"
              />
              <a-button
                type="link"
                @click="delete_delimiter(index)"
                :style="{ color: viewOnly ? '' : 'red' }"
                :disabled="viewOnly"
                >删除</a-button
              >
            </a-form-item>
            <a-form-item
              label="是否过滤空行:"
              v-if="type === 'multipleColumn2One'"
            >
              <a-checkbox
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.filterBlankLine"
              ></a-checkbox>
            </a-form-item>
            <a-form-item label="类型:" v-if="type === 'ftp'">
              <a-select
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.type"
                placeholder="请选择类型"
                @change="ftpTypeChange"
                style="width: 120px"
              >
                <a-select-option
                  v-for="item in ftpTypeList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="端口:" v-if="type === 'ftp'">
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.port"
                placeholder="请输入端口"
              />
            </a-form-item>
            <a-form-item label="主机:" v-if="type === 'ftp'">
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.host"
                placeholder="请输入host"
              />
            </a-form-item>
            <a-form-item
              label="文件名称:"
              required
              v-if="type === 'ftp' || type == 'uploadFileSystem'"
            >
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.fileName"
                placeholder="请输入文件名称"
              />
            </a-form-item>
            <a-form-item
              label="名称:"
              v-if="type === 'fileUploadCollect' || type == 'fileListUpload'"
            >
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.name"
                placeholder="请输入名称"
              />
            </a-form-item>
            <a-form-item
              label="文件集:"
              v-if="
                type === 'fileUploadCollect' || type == 'fileCollectDistinct'
              "
            >
              <a-select
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.collectId"
                placeholder="请选择文件集"
                showSearch
                :filterOption="false"
                @search="searchFileCollectList"
                @blur="searchFileCollectList()"
                style="width: 240px"
              >
                <a-select-option
                  v-for="item in fileCollectList"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              required
              label="文件空间"
              v-if="type === 'uploadFileSystem'"
            >
              <a-select
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.fileSpaceId"
                placeholder="请选择文件空间"
              >
                <a-select-option
                  v-for="item in fileSpaceList"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              required
              v-if="
                type === 'fileUploadCollect' ||
                type == 'fileListUpload' ||
                type == 'uploadFileSystem'
              "
            >
              <span slot="label">
                文件过期时间
                <a-tooltip placement="left" v-if="type === 'fileUploadCollect'">
                  <div slot="title">
                    超过指定时间后,文件将被删除,0天代表永不过期
                  </div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>

              <a-input
                v-if="expireDaysShow"
                v-model="nodeData.extraInfo.expireDays"
                addon-after="天"
                style="width: 120px; margin-right: 10px"
              />
              <a-switch
                checked-children="过期"
                un-checked-children="永不过期"
                v-model="expireDaysShow"
                :disabled="viewOnly"
                @change="expireDaysShowChange"
              />
            </a-form-item>
            <a-form-item label="用户名:" v-if="type === 'ftp'">
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.username"
                placeholder="请输入username"
              />
            </a-form-item>
            <a-form-item label="是否压缩:" v-if="type === 'uploadFileSystem'">
              <a-switch
                checked-children="是"
                un-checked-children="否"
                v-model="nodeData.extraInfo.zip"
                :disabled="viewOnly"
              />
            </a-form-item>
            <a-form-item
              label="密码:"
              v-if="
                type === 'ftp' || type === 'zip' || type == 'uploadFileSystem'
              "
            >
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.password"
                placeholder="请输入password"
              />
            </a-form-item>
            <a-form-item label="黑名单来源:" v-if="type === 'uploadBlacklist'">
              <a-select
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.source"
                placeholder="请选择类型"
                mode="multiple"
                style="width: 240px"
              >
                <a-select-option
                  v-for="item in blackNameList"
                  :key="item"
                  :value="item"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              label="备注:"
              v-if="type === 'uploadBlacklist' || type == 'uploadFileSystem'"
            >
              <a-textarea
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.remark"
                placeholder="请输入备注"
                :rows="5"
              />
            </a-form-item>
            <a-form-item label="上传路径:" v-if="type === 'ftp'">
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.path"
                placeholder="请输入路径"
              />
            </a-form-item>
            <a-form-item label="类型:" v-if="type === 'textToExcel'">
              <a-select
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.type"
                placeholder="请选择类型"
                @change="textToExcelTypeChange"
                style="width: 120px"
              >
                <a-select-option
                  v-for="item in textToExcelTypeList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item v-if="type === 'textToExcel'">
              <span slot="label">
                追加表头
                <a-tooltip placement="left">
                  <div slot="title">
                    用英文状态逗号分隔多列(如果列包含逗号, 前面加个\)
                  </div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-switch
                v-model="nodeData.extraInfo.enableTableHeadName"
                @change="() => (nodeData.extraInfo.tableHeadNames = '')"
                :disabled="viewOnly"
              />
              <i style="display: inline-block; margin-left: 20px"></i>
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.tableHeadNames"
                v-show="nodeData.extraInfo.enableTableHeadName"
                placeholder="请输入表头名"
                style="width: 84%"
              />
            </a-form-item>
            <a-form-item label="任务名称:" v-if="type === 'sipCallTaskCreate'">
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.taskName"
                placeholder="请输入任务名称"
              />
            </a-form-item>
            <a-form-item label="项目token:" v-if="type === 'sipCallTaskCreate'">
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.projectToken"
                placeholder="请输入项目token"
              />
            </a-form-item>
            <a-form-item v-if="type === 'alarm'" required>
              <span slot="label">
                内容
                <a-tooltip placement="left">
                  <div slot="title">
                    ${FILE_CONTENT} 获取输入文件内容作为提醒内容
                  </div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.content"
                placeholder="请输入内容"
              />
            </a-form-item>
            <a-form-item
              label="被提醒人:"
              v-if="type === 'alarm' || type === 'pauseNotify'"
              required
            >
              <a-select
                v-model="userLoginNameList"
                placeholder="请选择被提醒人"
                mode="multiple"
                showSearch
                :filterOption="false"
                allowClear
                @search="searchUserList"
                @blur="getUserList()"
                :disabled="viewOnly"
                style="width: 150px"
              >
                <a-select-option
                  v-for="item in userList"
                  :key="item.id"
                  :value="item.loginName"
                  >{{ item.userName }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="bucket:" v-if="type === 'ossUpload'">
              <a-select
                v-model="nodeData.extraInfo.bucketName"
                placeholder="请选择bucket"
                :disabled="viewOnly"
              >
                <a-select-option
                  v-for="(item, index) in bucketNameList"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item v-if="type === 'ossUpload'">
              <span slot="label">
                文件路径
                <a-tooltip placement="left">
                  <div slot="title">
                    可以使用输入文件名称，也可以自定义名称,假定原始文件名称为a.zip;
                    /dir/ -> /dir/a.zip (以斜线结尾) /dir/aaa.zip ->
                    /dir/aaa.zip
                  </div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.filePath"
                placeholder="请输入文件路径"
              />
            </a-form-item>
            <a-form-item label="存储类型:" v-if="type === 'ossUpload'">
              <a-select
                v-model="nodeData.extraInfo.storageType"
                placeholder="请选择存储类型"
                :disabled="viewOnly"
              >
                <a-select-option
                  v-for="(item, index) in storageTypeList"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="视图编号:" v-if="type === 'logicViewSyn'">
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.viewCode"
                placeholder="请输入视图编号"
              />
            </a-form-item>
            <a-form-item label="分组名称:" v-if="type === 'addShortUrlGroup'">
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.activityCode"
                placeholder="请输入分组名称"
              />
            </a-form-item>
            <a-form-item label="长链:" v-if="type === 'addShortUrlGroup'">
              <a-tooltip placement="left">
                <div slot="title">长链校验</div>
                <a-checkbox
                  v-model="nodeData.extraInfo.needLongUrlCheck"
                  @change="urlFormat = false"
                  style="position: absolute; left: 340px; top: 55px"
                />
              </a-tooltip>
              <a-tabs
                v-model="nodeData.extraInfo.active"
                @change="addShortUrlGroupCallback"
                style="width: 328px"
              >
                <a-tab-pane tab="长链" key="1">
                  <a-input
                    @change="inputChange"
                    :disabled="viewOnly"
                    v-model="nodeData.extraInfo.longUrl"
                    placeholder="请输入长链"
                    @blur="loadDynamicApiData('longUrlCheck')"
                  />
                  <span
                    class="dropbox-ts"
                    :style="{ color: longUrlExistYesOrNo ? '#52c41a' : 'red' }"
                    v-if="urlFormat"
                  >
                    {{
                      longUrlExistYesOrNo
                        ? "校验通过:" + templateName
                        : "长链未匹配到任何模板"
                    }}
                  </span>
                </a-tab-pane>
                <a-tab-pane tab="长链分组" key="2">
                  <a-select
                    :disabled="viewOnly"
                    v-model="nodeData.extraInfo.longUrlGroupId"
                    placeholder="请选择"
                  >
                    <a-select-option
                      v-for="item in longUrlGroupList"
                      :key="item.id"
                      :value="item.id"
                    >
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-tab-pane>
              </a-tabs>
            </a-form-item>
            <a-form-item label="域名:" v-if="type === 'addShortUrlGroup'">
              <a-select
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.domain"
                placeholder="请选择"
                style="width: 240px"
              >
                <a-select-option
                  v-for="(item, index) in domainList"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="业务类型:" v-if="type === 'addShortUrlGroup'">
              <a-select
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.category"
                placeholder="请选择"
                style="width: 240px"
              >
                <a-select-option
                  v-for="(item, index) in categoryList"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.key }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="空号检测:" v-if="type === 'addShortUrlGroup'">
              <a-checkbox
                v-model="nodeData.extraInfo.blankNumberCheck"
                :disabled="viewOnly"
              />
            </a-form-item>
            <a-form-item v-if="type === 'addShortUrlGroup'">
              <span slot="label">
                追加短链
                <a-tooltip placement="left">
                  <div slot="title">
                    在每个号码后面添加短链，开启空号检测实际会返回更多号码
                  </div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-checkbox
                v-model="nodeData.extraInfo.appendShortUrl"
                :disabled="viewOnly"
              />
            </a-form-item>
            <a-form-item v-if="type === 'addShortUrlGroup'" label="过期策略:">
              <a-select
                v-model="nodeData.extraInfo.expireRule"
                placeholder="请选择过期策略"
                @change="expireRuleChange"
              >
                <a-select-option
                  v-for="(item, index) in expireRuleTypeList"
                  :key="index"
                  :value="item.code"
                >
                  {{ item.desc }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              label="过期时间:"
              v-if="
                type === 'addShortUrlGroup' &&
                nodeData.extraInfo.expireRule !== 'UN_EXPIRE'
              "
              required
            >
              <a-input
                v-model="nodeData.extraInfo.expireTime"
                placeholder="请输入过期时间"
                style="width: 200px"
              >
                <a-select
                  slot="addonAfter"
                  v-model="nodeData.extraInfo.expireUnit"
                  style="width: 65px"
                >
                  <a-select-option value="月"> 月 </a-select-option>
                  <a-select-option value="天"> 天 </a-select-option>
                  <a-select-option value="小时"> 小时 </a-select-option>
                  <a-select-option value="分钟"> 分钟 </a-select-option>
                  <a-select-option value="秒"> 秒 </a-select-option>
                </a-select>
              </a-input>
            </a-form-item>
            <a-form-item
              label="过滤类型:"
              v-if="type === 'dataFilterBaseAction'"
            >
              <a-cascader
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.actionSelectList"
                :options="dataFilterAction"
                expand-trigger="hover"
                placeholder="请选择过滤类型"
                @change="loadDynamicApiData('description')"
              />

              <span class="dropbox-ts" v-if="dataFilterBaseActionDescription">
                补充列说明:{{ dataFilterBaseActionDescription }}
              </span>
            </a-form-item>
            <a-form-item v-if="type === 'inputFileDataCheck'">
              <span slot="label">
                列选择
                <a-tooltip placement="left">
                  <div slot="title">选择列(列下标从1开始计数)</div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-select
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.columnType"
                placeholder="请选择"
                style="width: 100px"
              >
                <a-select-option
                  v-for="(item, index) in inputFileDataCheckColumnTypeList"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
              <a-input
                @change="inputChange"
                v-if="nodeData.extraInfo.columnType == 'INDEX_COLUMNS'"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.columnIndex"
                placeholder="请输入列下标"
                style="width: 130px; margin-left: 10px"
              />
            </a-form-item>
            <a-form-item v-if="type === 'inputFileDataCheck'">
              <span slot="label">
                通过率
                <a-tooltip placement="left">
                  <div slot="title">
                    符合设定条件即为校验通过,通过率不足设定值,则格式检查失败
                  </div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.passRate"
                placeholder="请输入通过率"
                style="width: 60px"
              />
              >&nbsp;&nbsp;%
            </a-form-item>
            <template v-if="type == 'inputFileDataCheck'">
              <a-table
                :rowClassName="$common.rowClassColor"
                :columns="inputFileDataCheckConditionColumns"
                :dataSource="inputFileDataCheckConditionList"
                :pagination="false"
                bordered
                size="small"
              >
                <span slot="condition" slot-scope="text, record">
                  <a-select
                    :disabled="viewOnly"
                    v-model="record.condition"
                    placeholder="条件"
                    size="small"
                  >
                    <a-select-option
                      v-for="(
                        item, index
                      ) in inputFileDataCheckConditionItemList"
                      :key="index"
                      :value="item.value"
                    >
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </span>
                <span slot="value" slot-scope="text, record">
                  <a-input
                    @change="inputChange"
                    :disabled="viewOnly"
                    v-if="
                      record.condition == '字符长度' ||
                      record.condition == '有几列'
                    "
                    v-model="record.value"
                    size="small"
                  />
                </span>
                <span slot="action" slot-scope="text, record, index">
                  <a-button
                    type="link"
                    @click="delete_inputFileDataCheck(index)"
                    :style="{ color: viewOnly ? '' : 'red' }"
                    :disabled="viewOnly"
                    >删除</a-button
                  >
                </span>
              </a-table>
              <div style="margin-top: 10px">
                <a-button
                  type="primary"
                  @click="inputFileDataCheckAdd()"
                  :disabled="viewOnly"
                  >添加</a-button
                >
              </div>
            </template>
            <a-form-item
              label="任务名称:"
              v-if="type === 'uploadXinBa' || type == 'uploadQuDian'"
            >
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.taskName"
                placeholder="请输入任务名称"
              />
            </a-form-item>
            <a-form-item label="业务类型:" v-if="type === 'uploadXinBa'">
              <a-select
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.type"
                placeholder="请选择"
                @change="uploadXinBaTypeChange"
                style="width: 240px"
              >
                <a-select-option
                  v-for="(item, index) in accountList"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="任务模板:" v-if="type === 'uploadXinBa'">
              <a-select
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.taskTemplateId"
                placeholder="请选择"
                @change="taskTemplateIdChange"
                style="width: 240px"
              >
                <a-select-option
                  v-for="item in taskTempList"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.title }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="任务模板:" v-if="type === 'uploadQuDian'">
              <a-select
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.taskTemplateId"
                placeholder="请选择"
                @change="taskTemplateIdChange"
                style="width: 240px"
              >
                <a-select-option
                  v-for="item in templateList"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.templateName }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              label="上传短链:"
              v-if="type === 'uploadXinBa' || type == 'uploadQuDian'"
            >
              <a-checkbox
                v-model="nodeData.extraInfo.uploadShortUrl"
                :disabled="viewOnly"
              />
            </a-form-item>
            <a-form-item v-if="type === 'jsonFormat'">
              <span slot="label">
                字段
                <a-tooltip placement="left">
                  <div slot="title">
                    {"name": "zhangsan", "info": {"age": 18}} => name,info.age
                  </div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.fields"
                placeholder="请输入字段"
              />
            </a-form-item>
            <a-form-item v-if="type === 'columnRearrange'">
              <span slot="label">
                重排顺序
                <a-tooltip placement="left">
                  <div slot="title">
                    列序号从1开始计数,可以重复使用,用逗号分割
                  </div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.order"
                placeholder="请输入重排顺序"
              />
            </a-form-item>
            <a-form-item label="topic:" v-if="type === 'kafkaReceiver'">
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.topic"
                placeholder="请输入topic"
              />
            </a-form-item>
            <a-form-item label="压缩类型:" v-if="type === 'fileCompression'">
              <a-select
                v-model="nodeData.extraInfo.targetType"
                placeholder="请选择类型"
                :disabled="viewOnly"
              >
                <a-select-option
                  v-for="(item, index) in compressTypeList"
                  :key="index"
                  :value="item.tail"
                >
                  {{ item.type }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="压缩密码:" v-if="type === 'fileCompression'">
              <a-switch
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.passwordSwitch"
                active-color="#13ce66"
                inactive-color="#ff4949"
              />
              <template v-if="nodeData.extraInfo.passwordSwitch">
                <a-input
                  @change="inputChange"
                  :disabled="viewOnly"
                  v-model="nodeData.extraInfo.password"
                  placeholder="请输入密码"
                  style="width: 250px; margin: 0 5px"
                />
                <a-button @click="random" type="link">随机</a-button>
              </template>
            </a-form-item>
            <a-form-item
              label="文件列表:"
              required
              v-if="type === 'fileCompression'"
            >
              <a-button
                type="primary"
                @click="fileOrDirAdd"
                :disabled="viewOnly"
                >添加文件</a-button
              >
            </a-form-item>
            <a-form-item
              v-for="(item, index) in fileOrDirList"
              :key="index"
              required
              v-if="type === 'fileCompression'"
              :wrapper-col="{ span: 24 }"
            >
              <a-input
                @change="inputChange"
                v-model="item.value"
                placeholder="文件"
                allowClear
                style="width: 60%; margin: 0 10px 0 140px"
                :disabled="viewOnly"
              />
              <a-button
                type="link"
                @click="delete_fileOrDirItem(index)"
                :disabled="viewOnly"
                :style="{ color: viewOnly ? '' : 'red' }"
                >删除</a-button
              >
            </a-form-item>
            <a-form-item label="追加内容:" v-if="type === 'rowAppend'">
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.content"
                placeholder="请输入追加内容"
              />
            </a-form-item>
            <a-form-item v-if="type === 'rowAppend'">
              <span slot="label">
                追加行号
                <a-tooltip placement="left">
                  <div slot="title">
                    1 表示第一行，2表示第二行，-1表示最后一行，-2表示倒数第二行
                  </div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.rowNum"
                placeholder="请输入追加行号"
              />
            </a-form-item>
            <a-form-item label="是否去重:" v-if="type === 'merge'">
              <a-switch
                checked-children="是"
                un-checked-children="否"
                v-model="nodeData.extraInfo.distinct"
                :disabled="viewOnly"
              />
            </a-form-item>
            <a-form-item
              label="使用spark:"
              v-if="
                type === 'sort' ||
                type === 'intersection' ||
                type === 'merge' ||
                type === 'join'
              "
            >
              <a-checkbox
                v-model="nodeData.extraInfo.useSpark"
                :disabled="viewOnly"
              />
            </a-form-item>
            <a-form-item label="数据总量:" v-if="type === 'contentSupplement'">
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.total"
                placeholder="请输入数据总量"
              />
            </a-form-item>
            <a-form-item
              label="关注数据库:"
              required
              v-if="type == 'maxwellFilter'"
            >
              <a-select
                v-model="nodeData.extraInfo.datasourceId"
                placeholder="请选择数据库"
                :disabled="viewOnly"
                showSearch
                :filterOption="false"
                @search="searchPipelineDataSourceList"
                @blur="searchPipelineDataSourceList()"
                @change="dataSourceChange"
                style="width: 240px"
              >
                <a-select-option
                  v-for="item in dataSourceIdList"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="关注表:" v-if="type == 'maxwellFilter'">
              <a-select
                v-model="nodeData.extraInfo.table"
                placeholder="请选择表"
                :disabled="viewOnly || !nodeData.extraInfo.datasourceId"
                showSearch
                style="width: 240px"
              >
                <a-select-option
                  v-for="(item, index) in tableList"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              label="事件类型:"
              required
              v-if="type === 'maxwellFilter'"
            >
              <a-checkbox-group
                v-model="maxwellFilterTypeList"
                :options="typesOptions"
                :disabled="viewOnly"
              >
                <span slot="label" slot-scope="{ value }">{{ value }}</span>
              </a-checkbox-group>
            </a-form-item>
            <a-form-item required v-if="type === 'maxwellFilter'">
              <span slot="label">
                值过滤
                <a-tooltip placement="left">
                  <div slot="title">
                    新增和删除, 直接输入字段名取值, 更新后用 data.xx取值,
                    更新前用 old.xx取值
                  </div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-switch
                checked-children="开启"
                un-checked-children="关闭"
                v-model="nodeData.extraInfo.filterColumnSwitch"
                :disabled="viewOnly"
              />
              <template v-if="nodeData.extraInfo.filterColumnSwitch">
                <a-input
                  @change="inputChange"
                  :disabled="viewOnly"
                  v-model="nodeData.extraInfo.filterColumn"
                  style="width: 100px; margin: 0 5px"
                />
                等于
                <a-input
                  @change="inputChange"
                  :disabled="viewOnly"
                  v-model="nodeData.extraInfo.filterColumnValue"
                  style="width: 100px; margin-left: 5px"
                />
              </template>
            </a-form-item>
            <a-form-item
              label="输入类型:"
              v-if="type === 'smsCustomerCodeFetch'"
            >
              <a-radio-group
                v-model="nodeData.extraInfo.batchNameType"
                :disabled="viewOnly"
              >
                <a-radio :value="true">批次名称</a-radio>
                <a-radio :value="false">planId</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item
              label="数据类型:"
              v-if="type === 'smsCustomerCodeFetch'"
            >
              <a-radio-group
                v-model="nodeData.extraInfo.fetchSmsSendData"
                :disabled="viewOnly"
                @change="fetchSmsSendDataChange"
              >
                <a-radio :value="true">发送数据</a-radio>
                <a-radio :value="false">点击数据</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item
              label="状态过滤:"
              v-if="
                type === 'smsCustomerCodeFetch' &&
                nodeData.extraInfo.fetchSmsSendData
              "
            >
              <a-radio-group
                v-model="nodeData.extraInfo.sendStatus"
                :disabled="viewOnly"
              >
                <a-radio value="">全部状态</a-radio>
                <a-radio value="SUCCESS">发送成功</a-radio>
                <a-radio value="WAIT_SUBMIT">等待提交过滤</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item
              label="返回字段:"
              v-if="type === 'smsCustomerCodeFetch'"
            >
              <a-select
                :disabled="viewOnly"
                v-model="smsCustomerCodeFetchFieldList"
                placeholder="请选择"
                showSearch
                mode="multiple"
              >
                <a-select-option
                  v-for="(item, index) in smsCustomerCodeFetchFieldSelectList"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              label="结果去重:"
              v-if="type === 'smsCustomerCodeFetch'"
            >
              <a-switch
                checked-children="是"
                un-checked-children="否"
                v-model="nodeData.extraInfo.selfUnique"
                :disabled="viewOnly"
              />
            </a-form-item>
            <a-form-item
              v-if="type === 'repeatFilter' || type === 'repeatCountAnalysis'"
            >
              <span slot="label">
                分割文件数
                <a-tooltip placement="left">
                  <div slot="title">
                    假设设置为3，则将重复次数分为3个文件，分别是1,2,3-999
                  </div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.splitCount"
                placeholder="请输入分割文件数"
              />
            </a-form-item>
            <a-form-item v-if="type === 'tagFilter'">
              <span slot="label">
                标签内容
                <a-tooltip placement="left">
                  <div slot="title">
                    1 A<br />
                    1 B<br />
                    1 C<br />
                    1 D
                  </div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-textarea
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.tagContent"
                placeholder="请输入标签内容"
                :rows="5"
              />
            </a-form-item>
            <a-form-item label="过滤类型:" v-if="type === 'tagFilter'">
              <a-radio-group
                v-model="nodeData.extraInfo.include"
                :disabled="viewOnly"
              >
                <a-radio :value="true">包含输入标签</a-radio>
                <a-radio :value="false">排除输入标签</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item v-if="type === 'uploadMonitor'" label="监控编码:">
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.code"
                placeholder="请输入监控编码"
              />
            </a-form-item>
            <a-form-item v-if="type === 'uploadMonitor'" label="状态:">
              <a-radio-group v-model="nodeData.extraInfo.status">
                <a-radio
                  :value="item.value"
                  v-for="(item, index) in uploadMonitorStatusList"
                  :key="index"
                  >{{ item.label }}</a-radio
                >
              </a-radio-group>
            </a-form-item>
            <a-form-item v-if="type === 'uploadMonitor'" label="备注:">
              <a-textarea
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.remark"
                placeholder="请输入备注"
              />
            </a-form-item>
            <a-form-item
              v-if="type === 'distributionStatistics'"
              label="起始于:"
            >
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.start"
                placeholder="起始于"
              />
            </a-form-item>
            <a-form-item
              v-if="type === 'distributionStatistics'"
              label="终止于:"
            >
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.end"
                placeholder="终止于"
              />
            </a-form-item>
            <a-form-item v-if="type === 'distributionStatistics'" label="步长:">
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.step"
                placeholder="请输入步长"
              />
            </a-form-item>
            <a-form-item
              v-if="type === 'distributionStatistics'"
              label="单独区间:"
            >
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.specialGape"
                placeholder="请输入单独区间"
              />
            </a-form-item>
            <a-form-item v-if="type === 'columnGroupAndFetch'">
              <span slot="label">
                分组列
                <a-tooltip placement="left">
                  <div slot="title">支持多列，多个用逗号分割</div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.groupIndex"
                placeholder="请输入分组列"
              />
            </a-form-item>
            <a-form-item v-if="type === 'columnGroupAndFetch'" label="条数:">
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.count"
                placeholder="请输入条数"
              />
            </a-form-item>
            <a-form-item v-if="type === 'columnMatchAndFetch'">
              <span slot="label">
                主文件列
                <a-tooltip placement="left">
                  <div slot="title">多个用逗号分割</div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.mainFileColumns"
                placeholder="请输入主文件列"
              />
            </a-form-item>
            <a-form-item v-if="type === 'columnMatchAndFetch'">
              <span slot="label">
                匹配文件列
                <a-tooltip placement="left">
                  <div slot="title">
                    筛选条件, 多个用逗号分割，列数必须要和上面一致
                  </div>
                  <a-icon
                    type="question-circle"
                    style="color: #1879ff; font-size: 14px"
                  />
                </a-tooltip>
              </span>
              <a-input
                @change="inputChange"
                :disabled="viewOnly"
                v-model="nodeData.extraInfo.matchFileColumns"
                placeholder="请输入匹配文件列"
              />
            </a-form-item>

            <template v-if="type == 'ai00166'">
              <a-form-item label="批次名称:">
                <a-input
                  v-model="nodeData.extraInfo.batchName"
                  placeholder="请输入批次名称"
                  :disabled="viewOnly"
                />
              </a-form-item>
              <a-form-item label="运营商:">
                <a-checkbox-group
                  v-model="carrierList"
                  :options="operatorsOptions"
                  :disabled="viewOnly"
                >
                </a-checkbox-group>
              </a-form-item>
              <a-form-item label="归属地:">
                <div>
                  <div
                    :class="{
                      card: true,
                      checked: !nodeData.extraInfo.excludeArea,
                    }"
                    @click="nodeData.extraInfo.excludeArea = false"
                    :disabled="viewOnly"
                  >
                    包含
                  </div>
                  <div
                    :class="{
                      card: true,
                      checked: nodeData.extraInfo.excludeArea,
                    }"
                    @click="nodeData.extraInfo.excludeArea = true"
                    :disabled="viewOnly"
                  >
                    排除
                  </div>
                </div>
                <el-cascader
                  v-model="nodeData.extraInfo.region"
                  :options="options"
                  :props="props"
                  filterable
                  :show-all-levels="false"
                  allowClear
                  collapse-tags
                  :disabled="viewOnly"
                ></el-cascader>
                <a-button
                  type="link"
                  @click="
                    fastTyping(nodeData.extraInfo.excludeArea ? '排除' : '包含')
                  "
                  style="margin-left: 5px"
                  :disabled="viewOnly"
                  >快速输入</a-button
                >
                <div>吉林市、重庆市 需要带“市”</div>
              </a-form-item>
              <a-form-item label="年龄:">
                <a-select
                  v-model="ageList"
                  placeholder="请选择年龄"
                  mode="multiple"
                >
                  <a-select-option
                    v-for="item in ageSelectList"
                    :key="item.code"
                    :value="item.code"
                    >{{ item.desc }}</a-select-option
                  >
                </a-select>
              </a-form-item>
              <a-form-item label="性别">
                <a-radio-group v-model="nodeData.extraInfo.sex">
                  <a-radio value="">不限</a-radio>
                  <a-radio value="01">男</a-radio>
                  <a-radio value="02">女</a-radio>
                </a-radio-group>
              </a-form-item>
              <a-form-item label="去重规则:" required>
                <a-input
                  v-model="nodeData.extraInfo.ruleDk"
                  placeholder="请输入去重规则"
                  :disabled="viewOnly"
                />
              </a-form-item>
              <a-form-item label="去重周期:" required>
                <a-select
                  v-model="nodeData.extraInfo.period"
                  placeholder="请选择去重周期"
                  :disabled="viewOnly"
                >
                  <a-select-option
                    v-for="item in periodList"
                    :key="item"
                    :value="item"
                  >
                    {{ item }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="ftp文件路径:" required>
                <a-select
                  v-model="ftpFilePathList"
                  placeholder="请选择ftp文件路径"
                  mode="multiple"
                  :disabled="viewOnly"
                >
                  <a-select-option
                    v-for="(item, index) in ftpList"
                    :key="index"
                    :value="item.value"
                    >{{ item.label }}</a-select-option
                  >
                </a-select>
              </a-form-item>
              <a-form-item label="黑名单ftp路径:">
                <a-select
                  v-model="blacklistFilePathList"
                  placeholder="请选择黑名单ftp路径"
                  mode="multiple"
                  :disabled="viewOnly"
                >
                  <a-select-option
                    v-for="(item, index) in blackFtpList"
                    :key="index"
                    :value="item.value"
                    >{{ item.label }}</a-select-option
                  >
                </a-select>
              </a-form-item>
              <a-form-item label="业务类型:">
                <a-select
                  :disabled="viewOnly"
                  v-model="nodeData.extraInfo.category"
                  placeholder="请选择"
                >
                  <a-select-option
                    v-for="(item, index) in categoryList"
                    :key="index"
                    :value="item"
                  >
                    {{ item }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </template>

            <template v-if="type == 'httpApi'">
              <a-input-group compact>
                <a-select
                  :disabled="viewOnly"
                  v-model="nodeData.extraInfo.method"
                  placeholder="请选择"
                  style="width: 100px"
                >
                  <a-select-option
                    v-for="item in methodList"
                    :key="item.value"
                    :value="item.value"
                  >
                    {{ item.label }}
                  </a-select-option>
                </a-select>
                <a-input
                  @change="inputChange"
                  :disabled="viewOnly"
                  placeholder="请输入接口地址"
                  v-model="nodeData.extraInfo.url"
                  style="width: 50%"
                />
              </a-input-group>

              <a-tabs v-model="activeName">
                <a-tab-pane tab="Headers" key="first">
                  <div v-for="(item, index) in requestParameter" :key="index">
                    <a-input
                      @change="inputChange"
                      :disabled="viewOnly"
                      v-model="item.key"
                      placeholder="请输入变量名"
                      style="width: 40%"
                      size="small"
                    />
                    <a-input
                      @change="inputChange"
                      :disabled="viewOnly"
                      v-model="item.value"
                      placeholder="请输入变量值"
                      style="width: 40%; margin-left: 10px"
                      size="small"
                    />
                    <a-button
                      type="link"
                      @click="delete_Parameter(index)"
                      v-if="index != requestParameter.length - 1"
                      style="margin-left: 10px; font-size: 12px"
                      :style="{ color: viewOnly ? '' : 'red' }"
                      :disabled="viewOnly"
                      >删除</a-button
                    >
                  </div>
                </a-tab-pane>
                <a-tab-pane tab="URL Params" key="second">
                  <div v-for="(item, index) in requestHeader" :key="index">
                    <a-input
                      @change="inputChange"
                      :disabled="viewOnly"
                      v-model="item.key"
                      placeholder="请输入变量名"
                      style="width: 40%"
                      size="small"
                    />
                    <a-input
                      @change="inputChange"
                      :disabled="viewOnly"
                      v-model="item.value"
                      placeholder="请输入变量值"
                      style="width: 40%; margin-left: 10px"
                      size="small"
                    />
                    <a-button
                      type="link"
                      @click="delete_header(index)"
                      v-if="index != requestHeader.length - 1"
                      style="margin-left: 10px; font-size: 12px"
                      :style="{ color: viewOnly ? '' : 'red' }"
                      :disabled="viewOnly"
                      >删除</a-button
                    >
                  </div>
                </a-tab-pane>
                <a-tab-pane tab="body" key="third">
                  <div
                    v-for="(item, index) in requestBody"
                    :key="index"
                    style="font-size: 12px"
                  >
                    <div>
                      <a-input
                        @change="inputChange"
                        :disabled="viewOnly"
                        v-model="item.key"
                        placeholder="请输入变量名"
                        style="width: 40%"
                        size="small"
                      />
                      <a-input-group
                        compact
                        v-if="item.type == 'String'"
                        style="
                          display: inline-block;
                          width: 230px;
                          margin-left: 10px;
                        "
                      >
                        <a-select
                          v-model="item.type"
                          placeholder="请选择"
                          style="width: 100px"
                          :disabled="!item.key || viewOnly"
                          size="small"
                        >
                          <a-select-option
                            v-for="item in bodyTypeList"
                            :key="item.value"
                            :value="item.value"
                          >
                            {{ item.label }}
                          </a-select-option>
                        </a-select>
                        <a-input
                          @change="inputChange"
                          placeholder="请输入变量值"
                          v-model="item.value"
                          style="width: 130px"
                          :disabled="!item.key || viewOnly"
                          size="small"
                        />
                      </a-input-group>
                      <a-input-group
                        compact
                        v-if="item.type !== 'String' && item.type"
                        style="
                          display: inline-block;
                          width: 230px;
                          margin-left: 10px;
                        "
                      >
                        <a-select
                          v-model="item.type"
                          placeholder="请选择"
                          style="width: 100px"
                          :disabled="!item.key || viewOnly"
                          size="small"
                        >
                          <a-select-option
                            v-for="item in bodyTypeList"
                            :key="item.value"
                            :value="item.value"
                          >
                            {{ item.label }}
                          </a-select-option>
                        </a-select>
                        <a-input
                          @change="inputChange"
                          :disabled="viewOnly"
                          placeholder="请输入变量值"
                          v-model="item.value.length - 1"
                          style="width: 130px"
                          disabled
                          size="small"
                        />
                      </a-input-group>
                      <a-button
                        type="link"
                        @click="delete_body(index)"
                        v-if="index != requestBody.length - 1"
                        style="margin-left: 14px; font-size: 12px"
                        :style="{ color: viewOnly ? '' : 'red' }"
                        :disabled="viewOnly"
                        >删除</a-button
                      >
                    </div>
                    <div
                      v-if="item.type !== 'String' && item.type"
                      style="margin-left: 20px"
                    >
                      <div
                        v-for="(valueItem, valueIndex) in item.value"
                        :key="valueIndex"
                      >
                        <a-input
                          @change="inputChange"
                          v-model="valueItem.key"
                          placeholder="请输入变量名"
                          style="width: 40%"
                          size="small"
                          :disabled="item.type == 'Array' || viewOnly"
                        />
                        <a-input
                          @change="inputChange"
                          :disabled="viewOnly"
                          v-model="valueItem.value"
                          placeholder="请输入变量值"
                          style="width: 40%; margin-left: 10px"
                          size="small"
                        />
                        <a-button
                          type="link"
                          @click="delete_bodyObj(index, valueIndex)"
                          v-if="
                            valueIndex != item.value.length - 1 &&
                            item.type == 'Object'
                          "
                          style="margin-left: 10px; font-size: 12px"
                          :style="{ color: viewOnly ? '' : 'red' }"
                          >删除</a-button
                        >
                        <a-button
                          type="link"
                          @click="delete_bodyArr(index, valueIndex)"
                          v-if="
                            valueIndex != item.value.length - 1 &&
                            item.type == 'Array'
                          "
                          style="margin-left: 10px; font-size: 12px"
                          :style="{ color: viewOnly ? '' : 'red' }"
                          >删除</a-button
                        >
                      </div>
                    </div>
                  </div>
                </a-tab-pane>
              </a-tabs>
            </template>

            <template v-if="type == 'maxwell2Database'">
              <a-form-item label="原数据库名:">
                <a-select
                  v-model="nodeData.extraInfo.datasourceId"
                  placeholder="请选择原数据库名"
                  showSearch
                  :filterOption="false"
                  @search="searchPipelineDataSourceList"
                  @blur="searchPipelineDataSourceList()"
                  @change="dataSourceChange"
                  :disabled="viewOnly"
                >
                  <a-select-option
                    v-for="item in dataSourceIdList"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.databaseName }}</a-select-option
                  >
                </a-select>
              </a-form-item>
              <a-form-item label="原表名:">
                <a-select
                  :disabled="viewOnly"
                  v-model="nodeData.extraInfo.sourceTableType"
                  placeholder="请选择类型"
                  style="width: 100px; margin-right: 5px"
                >
                  <a-select-option
                    v-for="item in tableNameTypeList"
                    :key="item.value"
                    :value="item.value"
                  >
                    {{ item.label }}
                  </a-select-option>
                </a-select>
                <a-select
                  v-if="nodeData.extraInfo.sourceTableType == 'select'"
                  v-model="nodeData.extraInfo.sourceTableName"
                  placeholder="请选择原表名"
                  :disabled="!nodeData.extraInfo.datasourceId || viewOnly"
                  showSearch
                  @change="
                    tableNameChange(nodeData.extraInfo.datasourceId, $event)
                  "
                  style="width: calc(100% - 105px)"
                >
                  <a-select-option
                    v-for="(item, index) in tableList"
                    :key="index"
                    :value="item"
                  >
                    {{ item }}
                  </a-select-option>
                </a-select>
                <a-input
                  @change="inputChange"
                  :disabled="viewOnly"
                  v-else
                  v-model="nodeData.extraInfo.sourceTableName"
                  placeholder="请输入原表名"
                  style="width: calc(100% - 105px)"
                />
              </a-form-item>
              <a-form-item label="下游数据源:">
                <a-select
                  v-model="nodeData.extraInfo.sinkDataSourceId"
                  placeholder="请选择下游数据源"
                  showSearch
                  :filterOption="false"
                  @search="searchPipelineDataSourceList"
                  @blur="searchPipelineDataSourceList()"
                  @change="sinkDataSourceChange"
                  :disabled="viewOnly"
                >
                  <a-select-option
                    v-for="item in dataSourceIdList"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.databaseName }}</a-select-option
                  >
                </a-select>
              </a-form-item>
              <a-form-item label="下游表名:">
                <a-select
                  v-model="nodeData.extraInfo.sinkTableName"
                  placeholder="请选择下游表名"
                  :disabled="!nodeData.extraInfo.sinkDataSourceId || viewOnly"
                  showSearch
                  @change="
                    tableNameChange(
                      nodeData.extraInfo.sinkDataSourceId,
                      $event,
                      true
                    )
                  "
                >
                  <a-select-option
                    v-for="(item, index) in sinkTableList"
                    :key="index"
                    :value="item"
                  >
                    {{ item }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <div class="searchCondition">
                <div class="searchButton">
                  <a-button
                    type="primary"
                    :disabled="
                      !nodeData.extraInfo.sourceTableName ||
                      !nodeData.extraInfo.sinkTableName ||
                      viewOnly
                    "
                    @click="fieldMappingAdd"
                  >
                    添加
                  </a-button>
                </div>
              </div>

              <a-table
                :columns="fieldMappingColumns"
                :dataSource="fieldMappingList"
                :pagination="false"
                size="middle"
              >
                <span slot="sourceField" slot-scope="text, record">
                  <a-select
                    v-if="nodeData.extraInfo.sourceTableType == 'select'"
                    :disabled="viewOnly"
                    v-model="record.sourceField"
                    placeholder="请选择原字段"
                    style="width: 200px"
                    showSearch
                  >
                    <a-select-option
                      v-for="(item, index) in fieldSelectList"
                      :key="index"
                      :value="item.field"
                      >{{ item.field }}</a-select-option
                    >
                  </a-select>
                  <a-input
                    @change="inputChange"
                    :disabled="viewOnly"
                    v-else
                    v-model="record.sourceField"
                    placeholder="请输入原字段"
                    style="width: 200px"
                  />
                </span>
                <span slot="sinkField" slot-scope="text, record">
                  <a-select
                    v-model="record.sinkField"
                    placeholder="请选择下游字段"
                    style="width: 200px"
                    showSearch
                    :disabled="viewOnly"
                  >
                    <a-select-option
                      v-for="(item, index) in sinkFieldSelectList"
                      :key="index"
                      :value="item.field"
                      >{{ item.field }}</a-select-option
                    >
                  </a-select>
                </span>
                <span slot="action" slot-scope="text, record, index">
                  <a-button type="link" @click="fieldConvertEdit(record)">
                    转换规则配置
                  </a-button>
                  <a-divider type="vertical" />
                  <a-button
                    type="link"
                    style="color: #ff4d4f"
                    :disabled="viewOnly"
                    @click="delete_fieldMappingItem(index)"
                    >删除</a-button
                  >
                </span>
              </a-table>
            </template>

            <template v-if="type == 'createFormTask'">
              <a-form-item label="表单任务模板:">
                <a-select
                  v-model="nodeData.extraInfo.formId"
                  placeholder="请选择表单任务模板"
                  showSearch
                  optionFilterProp="children"
                  @change="getPipelineFormComponentList"
                >
                  <a-select-option
                    v-for="item in pipelineFormList"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <template v-if="pipelineFormComponentList.length > 0">
                <a-form-item
                  :required="formItem.required"
                  v-for="(formItem, index) in pipelineFormComponentList"
                  :key="formItem.id"
                >
                  <span slot="label">
                    {{ formItem.label }}
                    <a-tooltip placement="top" v-if="formItem.tips">
                      <div slot="title">
                        {{ formItem.tips }}
                      </div>
                      <a-icon
                        type="question-circle"
                        style="
                          color: #1879ff;
                          font-size: 14px;
                          margin-left: 5px;
                        "
                      />
                    </a-tooltip>
                  </span>
                  <a-input
                    v-if="formItem.componentType == 'INPUT'"
                    v-model.trim="nodeData.extraInfo.itemList[index].value"
                    :placeholder="
                      '请输入' +
                      formItem.label +
                      (formItem.variableFormat
                        ? '(' + formItem.variableFormat + ')'
                        : '')
                    "
                    style="width: 80%"
                    allowClear
                    :disabled="!formItem.editable"
                  />
                  <a-textarea
                    v-if="formItem.componentType == 'TEXT_SCOPE'"
                    v-model="nodeData.extraInfo.itemList[index].value"
                    :placeholder="
                      '请输入' +
                      formItem.label +
                      (formItem.variableFormat
                        ? '(' + formItem.variableFormat + ')'
                        : '')
                    "
                    style="width: 80%"
                    allowClear
                    :rows="5"
                    :disabled="!formItem.editable"
                  />
                  <a-select
                    v-model="nodeData.extraInfo.itemList[index].value"
                    v-if="
                      formItem.componentType == 'SELECT' ||
                      formItem.componentType == 'DB_SELECT' ||
                      formItem.componentType == 'DB_MULTIPLE_SELECT'
                    "
                    :placeholder="'请选择' + formItem.label"
                    :disabled="!formItem.editable"
                    showSearch
                    :mode="
                      formItem.componentType == 'DB_MULTIPLE_SELECT'
                        ? 'multiple'
                        : 'default'
                    "
                  >
                    <a-select-option
                      v-for="item in formItem.formOptionList"
                      :key="item.value"
                      :value="item.value"
                    >
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                  <a-date-picker
                    v-if="formItem.componentType == 'DATE_PICKER'"
                    v-model="nodeData.extraInfo.itemList[index].value"
                    :placeholder="'请选择' + formItem.label"
                    :disabled="!formItem.editable"
                    value-format="YYYY-MM-DD"
                    format="YYYY-MM-DD"
                  />
                  <a-date-picker
                    v-if="formItem.componentType == 'DATE_TIME_PICKER'"
                    v-model="nodeData.extraInfo.itemList[index].value"
                    showTime
                    :placeholder="'请选择' + formItem.label"
                    :disabled="!formItem.editable"
                    value-format="YYYY-MM-DD HH:mm:ss"
                    format="YYYY-MM-DD HH:mm:ss"
                    :picker-options="pickerOptions"
                  />
                </a-form-item>
              </template>
            </template>
          </a-form>
        </div>
      </div>
      <div
        v-if="isTest && !viewOnly"
        class="test_unit_content"
        :class="{
          test_unit_content_2: !nodeData.extraInfoShow,
          test_unit_content_3: nodeData.extraInfoShow,
        }"
      >
        <div class="item_1" v-if="menuIndex !== 1">
          <div class="item_title">{{ sourceTitle }}</div>
          <div class="item_1_1">
            <div
              class="item_textarea_2"
              v-if="menuIndex === 2 || menuIndex === 4"
            >
              <a-textarea
                :rows="13"
                placeholder="请输入内容"
                v-model="inputTextarea"
              />
            </div>
            <div class="item_textarea_3" v-if="menuIndex === 3">
              <a-textarea
                :rows="5"
                placeholder="请输入内容"
                v-model="inputTextarea"
              />
              <div class="item_title content_title">输入数据2</div>
              <a-textarea
                :rows="5"
                placeholder="请输入内容"
                v-model="inputTextarea2"
              />
            </div>
          </div>
        </div>
        <div>
          <a-icon class="item_i" type="arrow-right" />
        </div>
        <div
          class="item_2"
          :class="{
            item_3: menuIndex === 1 ? true : false,
          }"
        >
          <div class="item_title">{{ targetTitle }}</div>
          <div class="item_2_2">
            <div class="item_textarea_1" v-if="menuIndex === 1">
              <a-textarea :rows="13" readonly v-model="outputTextarea" />
            </div>
            <div
              class="item_textarea_2"
              v-if="menuIndex === 2 || menuIndex === 3"
            >
              <a-textarea :rows="13" readonly v-model="outputTextarea" />
            </div>
            <div class="item_textarea_3" v-if="menuIndex === 4">
              <a-textarea :rows="5" readonly v-model="outputTextarea" />
              <div class="item_title content_title">失败</div>
              <a-textarea :rows="5" readonly v-model="outputTextarea2" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <template slot="footer">
      <div style="display: flex; justify-content: center">
        <div
          style="
            font-weight: 500;
            font-size: 12px;
            position: absolute;
            left: 10px;
            bottom: 10px;
            color: #dbdbdb;
          "
        >
          <a-tooltip placement="left">
            <div slot="title">查看前置节点文件内容</div>
            <a-checkbox
              v-model="prevFileContentShow"
              @change="prevFileContentShowChange"
            />
          </a-tooltip>
          节点编号: {{ nodeData.id }}
        </div>
        <a-button @click="dialogVisible = false">{{
          viewOnly ? "关 闭" : "取 消"
        }}</a-button>
        <a-button
          type="primary"
          :disabled="
            !longUrlExistYesOrNo &&
            urlFormat &&
            nodeData.extraInfo.active == '1' &&
            type === 'addShortUrlGroup'
          "
          :loading="this.fileLoading"
          @click.stop="submit()"
          v-if="!viewOnly"
          >确 定</a-button
        >
      </div>
    </template>
    <a-modal
      title="快速输入"
      v-model="fastTypingDialogVisible"
      width="600px"
      :maskClosable="false"
    >
      <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="快速输入:">
          <a-textarea :rows="2" placeholder="请输入内容" v-model="textarea" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="fastTypingDialogVisible = false">取 消</a-button>
          <a-button type="primary" @click.stop="fastTyping_submit()"
            >确 定</a-button
          >
        </div>
      </template>
    </a-modal>
    <a-modal
      title="添加"
      v-model="editFieldShow"
      width="600px"
      :maskClosable="false"
    >
      <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 17 }">
        <a-form-item label="字段:" required>
          <a-select
            :disabled="viewOnly"
            v-model="fieldList"
            placeholder="请选择"
            showSearch
            mode="multiple"
          >
            <a-select-option
              v-for="item in fieldSelectList"
              :key="item.index"
              :value="item.field"
              :disabled="fieldDisabled(item.field)"
            >
              {{ item.field }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="editFieldShow = false">取 消</a-button>
          <a-button type="primary" @click.stop="editFieldSubmit()"
            >确 定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="生成表达式"
      v-model="produceRegexShow"
      width="600px"
      :maskClosable="false"
    >
      <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 17 }">
        <a-form-item label="条件:" required>
          <a-select
            v-model="regexSelect"
            placeholder="请选择条件"
            style="width: 150px"
          >
            <a-select-option
              v-for="(item, index) in regexSelectList"
              :key="index"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="关键字:" required>
          <div v-for="(item, index) in regexContentList" :key="index">
            <a-input
              @change="inputChange"
              v-model="item.value"
              placeholder="请输入关键字"
              style="width: 90%; margin-right: 5px"
            ></a-input>
            <a-button
              v-if="index == 0"
              type="link"
              @click="regexContentList.push({ value: '' })"
              >添加</a-button
            >
            <a-button
              v-else
              type="link"
              style="color: red"
              @click="regexContentList.splice(index, 1)"
              >删除</a-button
            >
          </div>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="produceRegexShow = false">取 消</a-button>
          <a-button type="primary" @click="produceRegexSubmit">确 定</a-button>
        </div>
      </template>
    </a-modal>

    <!-- 转换规则配置 -->
    <a-modal
      title="转换规则配置"
      v-model="convertRuleShow"
      :maskClosable="false"
      class="action-class"
      width="1000px"
    >
      <div class="searchCondition">
        <div class="searchButton">
          <a-button
            type="primary"
            @click="addFieldConvert"
            :disabled="viewOnly"
          >
            新增
          </a-button>
        </div>
      </div>
      <a-table
        :columns="fieldConvertColumns"
        :dataSource="fieldConvertList"
        :pagination="false"
        size="middle"
      >
        <span slot="convertType" slot-scope="text, record">
          {{ convertTypeDesc(text) }}
        </span>
        <span slot="extraInfo" slot-scope="text, record">
          <template v-for="(value, key) in record.extraInfo">
            {{ extraInfoKeyDesc(key) }}: {{ value }}
          </template>
        </span>
        <span slot="action" slot-scope="text, record, index">
          <a-button
            type="link"
            @click="editFieldConvert(record, index)"
            :disabled="viewOnly"
          >
            修改
          </a-button>
          <a-divider type="vertical" />
          <a-button
            type="link"
            style="color: #ff4d4f"
            @click="deleteFieldConvert(index)"
            :disabled="viewOnly"
            >删除</a-button
          >
        </span>
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="convertRuleShow = false">{{
            viewOnly ? "关闭" : "取消"
          }}</a-button>
          <a-button
            key="submit"
            type="primary"
            @click="convertRuleSubmit"
            v-if="!viewOnly"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 更新规则配置 -->
    <a-modal
      :title="updateFieldConvertTitle"
      v-model="updateFieldConvertShow"
      :maskClosable="false"
      class="action-class"
      width="500px"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="转换类型:">
          <a-select
            v-model="updateFieldConvertData.convertType"
            placeholder="请选择类型"
            @change="convertTypeChange"
          >
            <a-select-option
              v-for="(item, index) in convertTypeList"
              :key="index"
              :value="item.code"
            >
              {{ item.desc }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <template v-if="updateFieldConvertData.convertType == 'SUBSTRING'">
          <a-form-item label="开始结束索引:">
            <a-input
              @change="inputChange"
              v-model="updateFieldConvertData.extraInfo.beginIndex"
              placeholder="请输入开始索引"
              style="width: 45%; margin-right: 10px"
            />
            <a-input
              @change="inputChange"
              v-model="updateFieldConvertData.extraInfo.endIndex"
              placeholder="请输入结束索引"
              style="width: 45%"
            />
          </a-form-item>
        </template>
        <template v-else-if="updateFieldConvertData.convertType == 'REPLACE'">
          <a-form-item label="文本替换旧值:">
            <a-input
              @change="inputChange"
              v-model="updateFieldConvertData.extraInfo.replaceOld"
              placeholder="请输入文本替换旧值"
            />
          </a-form-item>
          <a-form-item label="文本替换新值:">
            <a-input
              @change="inputChange"
              v-model="updateFieldConvertData.extraInfo.replaceNew"
              placeholder="请输入文本替换新值"
            />
          </a-form-item>
        </template>
        <template v-else-if="updateFieldConvertData.convertType == 'SPLIT'">
          <a-form-item label="分割取数分隔符:">
            <a-input
              @change="inputChange"
              v-model="updateFieldConvertData.extraInfo.splitSeparator"
              placeholder="请输入分割取数分隔符"
            />
          </a-form-item>
          <a-form-item>
            <span slot="label">
              分割取数下标
              <a-tooltip placement="left">
                <div slot="title">可以输入多个，以逗号分隔，取多列</div>
                <a-icon
                  type="question-circle"
                  style="color: #1879ff; font-size: 14px"
                />
              </a-tooltip>
            </span>
            <a-input
              @change="inputChange"
              v-model="updateFieldConvertData.extraInfo.splitIndex"
              placeholder="请输入分割取数下标"
            />
          </a-form-item>
        </template>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="updateFieldConvertShow = false"
            >取消</a-button
          >
          <a-button
            key="submit"
            type="primary"
            @click="updateFieldConvertSubmit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <!-- 关联文件设置 -->
    <a-modal
      title="关联文件设置"
      v-model="setOutputFormatShow"
      :maskClosable="false"
      class="action-class"
      width="600px"
    >
      <h3 style="margin-bottom: 10px">输出格式:</h3>
      <div>
        <template v-for="(item, index) in outputFormatList">
          <a-tag
            :key="item.id"
            :color="item.color"
            style="margin-top: 5px"
            closable
            @close="deleteOutputFormat(item)"
          >
            {{ item.label }}
          </a-tag>
        </template>
        <a-tag style="margin-top: 5px" @click="outputFormatList.splice(0)">
          清空
        </a-tag>
      </div>
      <h3 style="margin: 10px 0">主文件:</h3>
      <div>
        <template v-for="index in o1Length">
          <a-tag
            :key="index"
            color="#2db7f5"
            @click="addOutputFormat('o1', index)"
          >
            第{{ index }}列
          </a-tag>
        </template>
        <a-tag style="margin-top: 5px" @click="o1Length++"> 添加 </a-tag>
      </div>
      <h3 style="margin: 10px 0">关联文件:</h3>
      <div>
        <template v-for="index in o2Length">
          <a-tag
            :key="index"
            color="#87d068"
            @click="addOutputFormat('o2', index)"
          >
            第{{ index }}列
          </a-tag>
        </template>
        <a-tag style="margin-top: 5px" @click="o2Length++"> 添加 </a-tag>
      </div>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="setOutputFormatShow = false"
            >取消</a-button
          >
          <a-button key="submit" type="primary" @click="setOutputFormatSubmit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="选择日期"
      v-model="selectDateShow"
      width="600px"
      :maskClosable="false"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="日期:" required>
          <a-date-picker
            v-model="date"
            v-if="nodeData.extraInfo?.type == 'yyyy-MM-dd'"
            placeholder="选择日期"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
          />
          <a-date-picker
            v-else
            v-model="date"
            placeholder="选择日期"
            format="YYYY-MM-DD HH:mm:ss"
            value-format="YYYY-MM-DD HH:mm:ss"
            :disabled="viewOnly"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="selectDateShow = false">取 消</a-button>
          <a-button type="primary" @click="selectDateSubmit">确 定</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      title="预览"
      v-model="previewSourceFileListShow"
      width="600px"
      :maskClosable="false"
    >
      <h4 style="margin-left: 10px">
        文件数:{{ previewSourceFileList.length }}
      </h4>
      <a-table
        :rowClassName="$common.rowClassColor"
        :columns="previewSourceFileColumns"
        :dataSource="previewSourceFileList"
        :pagination="false"
        bordered
        size="small"
      >
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="previewSourceFileListShow = false">关闭</a-button>
        </div>
      </template>
    </a-modal>
  </a-modal>
</template>
<script>
import "codemirror/lib/codemirror.css";
import { codemirror } from "vue-codemirror";
import "codemirror/mode/sql/sql.js";
import moment from "moment";

export default {
  name: "set_data",
  props: [
    "nodeData",
    "taskId",
    "type",
    "viewOnly",
    "menuData",
    "fromOptionsList",
    "toOptionsList",
    "fromShow",
    "fromPriorityDiffShow",
    "toShow",
    "tagShow",
    "menuList",
    "data",
    "fileSpaceList",
  ],
  components: {
    codemirror,
  },
  data() {
    return {
      activeName: "first",
      cmd: "",
      input11: "",
      title: "",
      dialogVisible: false,
      dataSourceIdList: [],
      tableList: [],
      sinkTableList: [],
      fieldSelectList: [],
      sinkFieldSelectList: [],
      fieldItemList: [],
      fieldItemColumns: [
        {
          title: "字段",
          ellipsis: true,
          dataIndex: "field",
        },
        {
          ellipsis: true,
          dataIndex: "value",
          scopedSlots: { customRender: "value", title: "valueTitle" },
          align: "center",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 100,
        },
      ],
      fileList: [],
      fieldCommentList: [], //上传字段中文数组
      fileOptionsList: [],
      poolFileId: "",
      priorityFileList: [
        {
          priority: "",
          fileName: "",
        },
      ],
      multipleFileList: [{ value: "" }],
      props: { multiple: true, emitPath: false, checkStrictly: true },
      options: [],
      fileLoadList: [
        {
          label: "文件上传",
          value: "UPLOAD",
        },
        {
          label: "本地文件",
          value: "LOCAL_FILE",
        },
        {
          label: "文件列表",
          value: "FILE_LIST",
        },
        {
          label: "文件集",
          value: "FILE_SET",
        },
        {
          label: "临时存放读取",
          value: "TMP_STORE",
        },
        {
          label: "FTP读取",
          value: "FTP",
        },
      ],
      cutTypeList: [
        {
          value: "自定义",
          label: "自定义",
        },
        {
          value: "固定长度",
          label: "固定长度",
        },
        {
          value: "分隔截取",
          label: "分隔截取",
        },
      ],
      fileColumnTypeList: [
        {
          value: "all",
          label: "全部列",
        },
        {
          value: "assign",
          label: "指定列",
        },
      ],
      splitTypeList: [
        {
          label: "文件个数",
          value: "fileNum",
        },
        {
          label: "数据条数",
          value: "dataNum",
        },
      ],
      typeList: [
        {
          value: "trim",
          label: "头尾去空",
        },
        {
          value: "blankLine",
          label: "去除空行",
        },
        {
          value: "firstLine",
          label: "去除第一行",
        },
        {
          value: "upperCase",
          label: "大写",
        },
        {
          value: "lowerCase",
          label: "小写",
        },
        {
          value: "format",
          label: "自定义格式化",
        },
      ],
      tablesTypeList: [
        {
          value: "0-9",
          label: "0-9",
        },
        {
          value: "0-f",
          label: "0-f",
        },
        {
          value: "custom",
          label: "自定义",
        },
      ],
      tableNameTypeList: [
        {
          value: "select",
          label: "选择表",
        },
        {
          value: "custom",
          label: "自定义",
        },
      ],
      columnTypeList: [],
      randomOffsetColumnTypeList: [],
      blackNameList: [],
      yang: "",
      fileLoading: false,
      parseJobFileTaskList: [],
      parseJobFileList: [],
      taskList: [],
      searchTaskList: [],
      zipMergeTypeList: [
        { value: "txt2txt", label: "txt2txt" },
        { value: "excel2txt", label: "excel2txt" },
      ],
      fastTypingDialogVisible: false,
      textarea: "",
      fastTypingShow: false,
      fromLabelList: [],
      toLabelList: [],
      fromLabel: "",
      toLabel: "",
      delayTypeList: [
        {
          value: "FIXED_SECONDS",
          label: "固定秒数",
        },
        {
          value: "FIXED_DATE",
          label: "指定日期",
        },
      ],
      methodList: [
        {
          value: "GET",
          label: "GET",
        },
        {
          value: "POST",
          label: "POST",
        },
      ],
      requestHeader: [],
      requestParameter: [],
      requestBody: [],
      bodyTypeList: [
        {
          value: "Object",
          label: "对象",
        },
        {
          value: "Array",
          label: "数组",
        },
        {
          value: "String",
          label: "字符串",
        },
      ],
      delimitersList: [{ value: "" }],
      textToExcelTypeList: [
        {
          value: "CSV",
          label: "CSV",
        },
        {
          value: "XLSX",
          label: "XLSX",
        },
      ],
      ftpTypeList: [
        {
          value: "ftp",
          label: "ftp",
        },
        {
          value: "sftp",
          label: "sftp",
        },
      ],
      separatorList: [
        {
          label: "逗号",
          value: ",",
        },
        {
          label: "空格",
          value: " ",
        },
        {
          label: "Tab",
          value: "	",
        },
        {
          label: "自定义",
          value: "",
        },
      ],
      dateTypeList: [
        {
          value: "yyyy-MM-dd",
          label: "年月日",
        },
        {
          value: "yyyy-MM-dd HH:mm:ss",
          label: "年月日时分秒",
        },
      ],
      blacklistFilterSource: [],
      fileCollectList: [],
      fileLoadFileList: [],
      daysList: [
        { id: 1, label: "无限制", value: "" },
        { id: 2, label: "最近7天", value: "7" },
        { id: 3, label: "最近15天", value: "15" },
        { id: 4, label: "最近30天", value: "30" },
      ],
      domainList: [],
      inputShow: false,
      sourceTitle: "输入数据",
      targetTitle: "输出数据",
      menuIndex: 0,
      inputTextarea: "",
      inputTextarea2: "",
      outputTextarea: "",
      outputTextarea2: "",
      isTest: false,
      syncLoading: null,
      editFieldShow: false,
      fieldId: "",
      fieldList: [],
      fileGroupList: [],
      taskFileList: [],
      conditionList: [{ columnIndex: "", expression: "", remark: "" }],
      conditionColumns: [
        {
          title: "列",
          ellipsis: true,
          dataIndex: "columnIndex",
          align: "center",
          width: 60,
        },
        {
          ellipsis: true,
          dataIndex: "expression",
          scopedSlots: {
            customRender: "expression",
            title: "columnExpressionTitle",
          },
          align: "center",
        },
        {
          title: "累加值",
          ellipsis: true,
          dataIndex: "value",
          scopedSlots: { customRender: "value" },
          align: "center",
          width: 100,
        },
        {
          ellipsis: true,
          dataIndex: "remark",
          scopedSlots: { customRender: "remark", title: "columnRemarkTitle" },
          align: "center",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 60,
        },
      ],
      supplementList: [{ index: "1", content: "" }],
      supplementColumns: [
        {
          title: "列序号",
          ellipsis: true,
          dataIndex: "index",
          width: 60,
          align: "center",
          scopedSlots: { customRender: "index" },
        },
        {
          ellipsis: true,
          dataIndex: "content",
          scopedSlots: { customRender: "content", title: "contentTitle" },
          align: "center",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 100,
        },
      ],
      excludeArea: [],
      includeArea: [],
      calculateKeyDataList: [],
      calculateConditionList: [],
      inputFileDataCheckConditionList: [],
      inputFileDataCheckConditionColumns: [
        {
          title: "条件",
          ellipsis: true,
          dataIndex: "condition",
          scopedSlots: { customRender: "condition" },
          align: "center",
          width: 170,
        },
        {
          title: "条件值",
          ellipsis: true,
          dataIndex: "value",
          scopedSlots: { customRender: "value" },
          align: "center",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 100,
        },
      ],
      inputFileDataCheckConditionItemList: [
        {
          value: "is_md5",
          label: "是MD5",
        },
        {
          value: "is_phone",
          label: "是手机号",
        },

        {
          value: "is_customer_code",
          label: "是CustomerCode",
        },
        {
          value: "is_number",
          label: "是数字",
        },
        {
          value: "字符长度",
          label: "字符长度",
        },
        {
          value: "有几列",
          label: "有几列",
        },
      ],
      inputFileDataCheckColumnTypeList: [
        {
          value: "ALL_COLUMNS",
          label: "所有列",
        },
        {
          value: "INDEX_COLUMNS",
          label: "指定列",
        },
      ],
      validSecondsList: [
        { id: 1, label: "1天", value: "86400" },
        { id: 2, label: "3天", value: "259200" },
        { id: 3, label: "7天", value: "604800" },
        { id: 4, label: "15天", value: "1296000" },
        { id: 5, label: "1个月", value: "2592000" },
        { id: 6, label: "3个月", value: "7776000" },
      ],
      userList: [],
      columnAppendTypeList: [
        { id: 1, label: "普通文本", value: "CONTENT" },
        { id: 2, label: "列计算", value: "CALCULATE" },
        { id: 3, label: "列计算(4位小数)", value: "CALCULATE_4F" },
        { id: 4, label: "序号递增", value: "SERIAL_NUMBER_INCR" },
        { id: 5, label: "顺序取值", value: "ORDER_ACCESS" },
        { id: 6, label: "随机取值", value: "RANDOM_ACCESS" },
        { id: 7, label: "列拼接", value: "COLUMN_CONNECT" },
        { id: 8, label: "动态列", value: "DYNAMIC_COLUMN_CONNECT" },
        { id: 9, label: "特定标签", value: "TAG" },
      ],
      dynamicColumnColumns: [
        {
          ellipsis: true,
          dataIndex: "column",
          scopedSlots: {
            customRender: "column",
            title: "columnTitle",
          },
          align: "center",
          width: 100,
        },
        {
          ellipsis: true,
          dataIndex: "expression",
          scopedSlots: {
            customRender: "expression",
            title: "expressionTitle",
          },
          align: "center",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 60,
        },
      ],
      dynamicColumnData: [],
      columnAppendTagList: [],
      tagTypeList: [],
      localData: null,
      updateModeList: [
        { id: 1, label: "IN查询式更新", value: "GENERATE_BATCH" },
        { id: 2, label: "按行执行完整sql", value: "GENERATED_SQL" },
      ],
      modeList: [
        { id: 1, label: "IN查询", value: "GENERATE_BATCH" },
        { id: 2, label: "完整sql查询", value: "GENERATED_SQL" },
      ],
      contentTypeList: [
        { id: 1, label: "TAB", value: "	" },
        { id: 2, label: "空格", value: " " },
        { id: 3, label: "逗号", value: "," },
        { id: 4, label: "空", value: "" },
        { id: 5, label: "自定义", value: "custom" },
      ],
      bucketNameList: [],
      categoryList: [],
      longUrlGroupList: [],
      expireRuleTypeList: [],
      dataFilterAction: [],
      taskTempList: [],
      accountList: [],
      templateList: [],
      urlFormat: false,
      longUrlExistYesOrNo: true,
      templateName: "",
      searchTimer: {},
      operatorsOptions: ["中国移动", "中国联通", "中国电信"],
      ageSelectList: [
        { code: "", desc: "不限" },
        { code: "01", desc: "17岁及以下" },
        { code: "02", desc: "18-24岁" },
        { code: "03", desc: "25-29岁" },
        { code: "04", desc: "30-34岁" },
        { code: "05", desc: "35-39岁" },
        { code: "06", desc: "40-44岁" },
        { code: "07", desc: "45-49岁" },
        { code: "08", desc: "50岁及以上" },
      ],
      ageList: [],
      carrierList: [],
      periodList: [7, 30, 60, 90, 180],
      ftpList: [],
      ftpFilePathList: [],
      blackFtpList: [],
      blacklistFilePathList: [],
      maxwellFilterTypeList: [],
      typesOptions: [
        { label: "新增", value: "insert" },
        { label: "更新", value: "update" },
        { label: "删除", value: "delete" },
      ],
      temOptions: {
        mode: "text/x-mariadb",
        readOnly: false,
        lineNumbers: true,
        line: true,
        styleActiveLine: true, //设置光标所在行高亮，需引入工具包
        autoRefresh: true,
        cursorBlinkRate: 530,
      },
      shufSelectorTypeList: [
        { id: 1, value: "constant", label: "固定数" },
        { id: 2, value: "ratio", label: "比例" },
      ],
      storageTypeList: [],
      compressTypeList: [],
      fileOrDirList: [],
      regexSelectList: [
        { label: "包含xxx", value: 1 },
        { label: "不包含xxx", value: 2 },
        { label: "以xxx开头", value: 3 },
        { label: "以xxx结尾", value: 4 },
        { label: "不以xxx开头", value: 5 },
        { label: "不以xxx结尾", value: 6 },
      ],
      regexSelect: 1,
      regexContentList: [],
      produceRegexShow: false,
      fieldMappingList: [],
      fieldMappingColumns: [
        {
          title: "原字段",
          dataIndex: "sourceField",
          scopedSlots: { customRender: "sourceField" },
        },
        {
          title: "下游字段",
          dataIndex: "sinkField",
          scopedSlots: { customRender: "sinkField" },
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      fieldConvertColumns: [
        {
          title: "转换类型",
          dataIndex: "convertType",
          ellipsis: true,
          width: 150,
          scopedSlots: { customRender: "convertType" },
        },
        {
          title: "其他信息",
          dataIndex: "extraInfo",
          scopedSlots: { customRender: "extraInfo" },
          ellipsis: true,
          width: 200,
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          width: 170,
          scopedSlots: { customRender: "action" },
        },
      ],
      fieldConvertList: [],
      fieldConvertIndex: "",
      convertRuleShow: false,
      updateFieldConvertTitle: "",
      updateFieldConvertIndex: "",
      updateFieldConvertShow: false,
      updateFieldConvertData: {},
      convertTypeList: [
        {
          code: "SUBSTRING",
          desc: "文本截取",
        },
        {
          code: "REPLACE",
          desc: "文本替换",
        },
        {
          code: "SPLIT",
          desc: "分割取数",
        },
      ],
      smsCustomerCodeFetchFieldSelectList: [],
      smsCustomerCodeFetchFieldList: [],
      outputFormatList: [],
      o1Length: 5,
      o2Length: 5,
      setOutputFormatShow: false,
      dateType: "",
      date: "",
      selectDateShow: false,
      uploadMonitorStatusList: [
        { label: "正常", value: "SUCCESS" },
        { label: "异常", value: "ERROR" },
      ],
      tmpStoreList: [],
      prevFileContentList: [],
      prevFileContentShow: false,
      previewSourceFileList: [],
      previewSourceFileColumns: [
        {
          title: "文件名称",
          dataIndex: "name",
        },
        {
          title: "文件大小",
          ellipsis: true,
          dataIndex: "readableFileSize",
          align: "center",
          width: 130,
        },
        {
          title: "上传时间",
          ellipsis: true,
          dataIndex: "expireAt",
          align: "center",
          width: 170,
        },
      ],
      previewSourceFileListShow: false,
      ftpServerList: [],
      ftpFileList: [],
      dataFilterBaseActionDescription: "",
      expireDaysShow: false,
      pipelineFormList: [],
      pipelineFormComponentList: [],
      userLoginNameList: [],
      variableColumns: [
        {
          title: "行",
          dataIndex: "row",
          align: "center",
          scopedSlots: { customRender: "row" },
          width: 100,
        },
        {
          title: "列",
          dataIndex: "col",
          scopedSlots: { customRender: "col" },
          width: 100,
        },
        {
          title: "变量名",
          dataIndex: "name",
          scopedSlots: { customRender: "name" },
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 60,
        },
      ],
    };
  },
  watch: {
    requestHeader: {
      deep: true,
      handler(newVal) {
        if (newVal[newVal.length - 1].key || newVal[newVal.length - 1].value) {
          newVal.push({ key: "", value: "" });
        }
      },
    },
    requestParameter: {
      deep: true,
      handler(newVal) {
        if (newVal[newVal.length - 1].key || newVal[newVal.length - 1].value) {
          newVal.push({ key: "", value: "" });
        }
      },
    },
    copyRequestBody: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal[newVal.length - 1].key) {
          this.requestBody.push({ key: "", value: "", type: "String" });
        } else {
          oldVal.forEach((item, index) => {
            if (index < newVal.length - 1) {
              if (oldVal[index].type !== newVal[index].type) {
                if (newVal[index].type == "Object") {
                  this.$set(this.requestBody[index], "value", [
                    { key: "", value: "" },
                  ]);
                } else if (newVal[index].type == "Array") {
                  this.$set(this.requestBody[index], "value", [
                    { key: 0, value: "" },
                  ]);
                } else {
                  this.$set(this.requestBody[index], "value", "");
                }
              } else if (oldVal[index].value !== newVal[index].value) {
                if (typeof newVal[index].value !== "string") {
                  if (newVal[index].type == "Object") {
                    if (
                      newVal[index].value[newVal[index].value.length - 1].key ||
                      newVal[index].value[newVal[index].value.length - 1].value
                    ) {
                      this.requestBody[index].value.push({
                        key: "",
                        value: "",
                      });
                    }
                  } else if (newVal[index].type == "Array") {
                    if (
                      newVal[index].value[newVal[index].value.length - 1].value
                    ) {
                      this.requestBody[index].value.push({
                        key: newVal[index].value.length,
                        value: "",
                      });
                    }
                  }
                }
              }
            }
          });
        }
      },
    },
    inputShow: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.$refs.titleInput.focus();
          });
        }
      },
    },
  },
  computed: {
    copyRequestBody() {
      return JSON.parse(JSON.stringify(this.requestBody));
    },
    width() {
      if (
        this.isTest ||
        (this.prevFileContentShow && this.prevFileContentList.length > 0)
      ) {
        return "1100px";
      }
      return "700px";
    },
    fieldDisabled() {
      return (field) => {
        let arr = this.fieldItemList.filter((item) => {
          return field == item.field;
        });
        if (arr.length > 0) {
          return true;
        } else {
          return false;
        }
      };
    },
    extraInfoKeyDesc() {
      return (key) => {
        let desc = "";
        switch (key) {
          case "beginIndex":
            desc = "开始结束索引";
            break;
          case "endIndex":
            desc = "结束索引";
            break;
          case "replaceOld":
            desc = "文本替换旧值";
            break;
          case "replaceNew":
            desc = "文本替换新值";
            break;
          case "splitSeparator":
            desc = "分割取数分隔符";
            break;
          case "splitIndex":
            desc = "分割取数下标";
            break;
        }
        return desc;
      };
    },
    convertTypeDesc() {
      return (convertType) => {
        let desc = this.convertTypeList.filter((item) => {
          return item.code == convertType;
        })[0].desc;
        return desc;
      };
    },
  },
  methods: {
    expireDaysShowChange() {
      if (!this.expireDaysShow) {
        this.$set(this.nodeData.extraInfo, "expireDays", 0);
      }
    },
    customerSortChange() {
      this.$set(this.nodeData.extraInfo, "customerSortString", "");
    },
    selectDate(type) {
      this.dateType = type;
      this.date = moment().startOf("day").format("YYYY-MM-DD");
      this.selectDateShow = true;
    },
    selectDateSubmit() {
      this.$set(this.nodeData.extraInfo, this.dateType, this.date);
      this.selectDateShow = false;
    },
    setOutputFormat() {
      this.setOutputFormatShow = true;
    },
    addOutputFormat(item, index) {
      this.outputFormatList.push({
        id: this.$common.randomStr(),
        label: item + ".第" + index + "列",
        value: "-" + item + "." + index,
        color: item == "o1" ? "#2db7f5" : "#87d068",
      });
    },
    deleteOutputFormat(removedItem) {
      this.outputFormatList = this.outputFormatList.filter(
        (item) => item !== removedItem
      );
      let outputFormatValueList = this.outputFormatList.map((item) => {
        return item.value;
      });
      this.$set(
        this.nodeData.extraInfo,
        "outputFormat",
        outputFormatValueList.join(" ")
      );
    },
    setOutputFormatSubmit() {
      let outputFormatValueList = this.outputFormatList.map((item) => {
        return item.value;
      });
      this.$set(
        this.nodeData.extraInfo,
        "outputFormat",
        outputFormatValueList.join(" ")
      );
      this.o1Length = 5;
      this.o2Length = 5;
      this.setOutputFormatShow = false;
    },
    inputChange() {
      this.$forceUpdate();
    },
    produceRegex() {
      this.regexSelect = 1;
      this.regexContentList = [{ value: "" }];
      this.produceRegexShow = true;
    },
    produceRegexSubmit() {
      let regex = "",
        content = "";

      let contentArr = this.regexContentList.map((item) => {
        return item.value;
      });
      content = contentArr.join("|");
      switch (this.regexSelect) {
        case 1:
          regex = content;
          break;
        case 2:
          regex = "^((?!" + content + ").)*$";
          break;
        case 3:
          regex = "^" + content + ".*";
          break;
        case 4:
          regex = content + "$";
          break;
        case 5:
          regex = "^(?!" + content + ")";
          break;
        case 6:
          regex = "(?<!" + content + ")$";
          break;
      }
      this.$set(this.nodeData.extraInfo, "regex", regex);
      this.produceRegexShow = false;
    },
    orgContentTypeChange(value) {
      if (value == "custom") {
        this.nodeData.extraInfo.orgContent = "";
        return;
      }
      this.nodeData.extraInfo.orgContent = value;
    },
    newContentTypeChange(value) {
      if (value == "custom") {
        this.nodeData.extraInfo.newContent = "";
        return;
      }
      this.nodeData.extraInfo.newContent = value;
    },
    searchUserList(name) {
      if (this.searchTimer[this.type]) {
        clearTimeout(this.searchTimer[this.type]);
        this.$set(this.searchTimer, this.type, null);
      }
      this.searchTimer[this.type] = setTimeout(() => {
        this.getUserList(name);
      }, 300);
    },
    getUserList(name) {
      this.$http.get("/user/list", { name }).then((res) => {
        if (res.result == 200) {
          this.userList = res.data;
        }
      });
    },
    getBucketNameList() {
      this.$http.get("/sysPipeline/bucketNameList").then((res) => {
        if (res.result == 200) {
          this.bucketNameList = res.data;
        }
      });
    },
    ftpTypeChange(value) {
      if (value == "ftp") {
        this.nodeData.extraInfo.port = "21";
      } else {
        this.nodeData.extraInfo.port = "22";
      }
    },
    textToExcelTypeChange(value) {
      if (value == "CSV") {
        this.nodeData.extraInfo.separator = ",";
      } else {
        this.nodeData.extraInfo.separator = "	";
      }
    },
    getTaskFileList() {
      let arr = this.fileGroupList.filter((item) => {
        return item.id == this.nodeData.extraInfo.taskId;
      });

      if (arr[0].fileList) {
        this.taskFileList = arr[0].fileList;
      }
    },
    searchFileGroupList(taskIdOrName) {
      if (this.searchTimer[this.type]) {
        clearTimeout(this.searchTimer[this.type]);
        this.$set(this.searchTimer, this.type, null);
      }
      this.searchTimer[this.type] = setTimeout(() => {
        this.getFileGroupList(taskIdOrName);
      }, 300);
    },
    getFileGroupList(taskIdOrName) {
      this.$http
        .get("/pipelineFile/fileGroupList", { taskIdOrName })
        .then((res) => {
          if (res.result == 200) {
            this.fileGroupList = res.data;
          }
        });
    },
    getDomainList() {
      this.$http.get("/shortUrl/domainList").then((res) => {
        if (res.result == 200) {
          this.domainList = res.data;
          if (!this.nodeData.extraInfo.domain) {
            this.nodeData.extraInfo.domain = this.domainList[0];
          }
        }
      });
    },
    searchFileList(name) {
      if (this.searchTimer[this.type]) {
        clearTimeout(this.searchTimer[this.type]);
        this.$set(this.searchTimer, this.type, null);
      }
      this.searchTimer[this.type] = setTimeout(() => {
        this.getFileList(name);
      }, 300);
    },
    getFileList(name) {
      let data = {
        pageNo: 1,
        pageSize: 20,
        name,
        id: name ? null : this.nodeData.extraInfo.id,
      };
      this.$http
        .get("/pipelineSourceFile/pipelineSourceFilePage", data)
        .then((res) => {
          if (res.result == 200) {
            this.fileLoadFileList = res.data.records;
          }
        });
    },
    fileCollectChange(value) {
      let name = this.fileCollectList.filter((item) => {
        return item.id == value;
      })[0].name;
      this.nodeData.extraInfo.name = name;
    },
    fileChange(value) {
      if (value) {
        let name = this.fileLoadFileList.filter((item) => {
          return item.id == value;
        })[0].name;
        this.nodeData.extraInfo.name = name;
      } else {
        this.getFileList();
        this.nodeData.extraInfo.name = "";
      }
    },
    searchFtpServerList(name) {
      if (this.searchTimer[this.type]) {
        clearTimeout(this.searchTimer[this.type]);
        this.$set(this.searchTimer, this.type, null);
      }
      this.searchTimer[this.type] = setTimeout(() => {
        this.getFtpServerList(name);
      }, 300);
    },
    getFtpServerList(name) {
      let data = {
        name,
      };
      this.$http.get("/ftp/ftpServerList", data).then((res) => {
        if (res.result === 200) {
          this.ftpServerList.splice(0);
          this.ftpServerList.push(...res.data);
        }
      });
    },
    getFtpFileList(ftpId) {
      if (ftpId) {
        this.$set(
          this.nodeData.extraInfo,
          "ftpName",
          this.ftpServerList.filter((item) => {
            return item.id == ftpId;
          })[0].name
        );
        this.nodeData.extraInfo.ftpPathList.splice(0);
      }
      this.getFileListByDir("/").then((res) => {
        if (res.result == 200) {
          this.ftpFileList = this.treeDataProcessing("/", res.data);
        }
      });
    },
    onLoadData(treeNode) {
      return new Promise((resolve) => {
        const { value } = treeNode;
        this.getFileListByDir(value + "/")
          .then((res) => {
            if (res.result == 200) {
              treeNode.dataRef.children = this.treeDataProcessing(
                value + "/",
                res.data
              );
              this.ftpFileList = [...this.ftpFileList];
              resolve();
            }
          })
          .catch(() => {
            resolve();
          });
      });
    },
    treeDataProcessing(path, list) {
      let data = list.map((item) => {
        let obj = {
          title: item.fileName,
          value: path + item.fileName,
          key: path + item.fileName,
          isLeaf: true,
        };
        if (item.dir) {
          obj.children = [];
          obj.selectable = false;
          obj.isLeaf = false;
        }
        return obj;
      });
      return data;
    },
    getFileListByDir(path) {
      let data = {
        ftpId: this.nodeData.extraInfo.ftpId,
        path,
      };
      return this.$http.get("/ftp/fileListByDir", data);
    },
    searchFileCollectList(name) {
      if (this.searchTimer[this.type]) {
        clearTimeout(this.searchTimer[this.type]);
        this.$set(this.searchTimer, this.type, null);
      }
      this.searchTimer[this.type] = setTimeout(() => {
        this.getFileCollectList(name);
      }, 300);
    },
    getFileCollectList(name) {
      let data = {
        pageNo: 1,
        pageSize: 20,
        name,
      };
      this.$http.get("/fileCollect/collectPage", data).then((res) => {
        if (res.result == 200) {
          this.fileCollectList = res.data.records;
        }
      });
    },
    delete_bodyObj(index, valueIndex) {
      this.requestBody[index].value.splice(valueIndex, 1);
    },
    delete_bodyArr(index, valueIndex) {
      this.requestBody[index].value.splice(valueIndex, 1);
      this.requestBody[index].value.forEach((item, index) => {
        this.$set(item, "key", index);
      });
    },
    delete_Parameter(index) {
      this.requestParameter.splice(index, 1);
    },
    delete_header(index) {
      this.requestHeader.splice(index, 1);
    },
    delete_body(index) {
      this.requestBody.splice(index, 1);
    },
    removeTrim(array) {
      for (var i = 0; i < array.length; i++) {
        if (array[i] == "") {
          array.splice(i, 1);
          i = i - 1;
        }
      }
      return array;
    },
    fastTyping_submit() {
      this.textarea = this.textarea.replace(/，/g, ",");
      this.textarea = this.textarea.replace(/ /g, ",");
      this.textarea = this.textarea.replace(/\|/g, ",");
      this.textarea = this.textarea.replace(/\｜/g, ",");
      let textarea = this.textarea.split(",");
      textarea = this.removeTrim(textarea);
      textarea.map((areaItem, index) => {
        this.options.map((item) => {
          item.children.map((childrenItem) => {
            if (childrenItem.value.split(":")[1] == areaItem) {
              textarea[index] = childrenItem.value;
            }
          });
        });
      });

      this.$nextTick(() => {
        if (this.type == "quickFilter") {
          if (!this.fastTypingShow) {
            this.includeArea = textarea;
          } else {
            this.excludeArea = textarea;
          }
        } else if (this.type == "ai00166") {
          this.$set(this.nodeData.extraInfo, "region", textarea);
        }
      });
      this.fastTypingDialogVisible = false;
      this.$message.success("设置成功");
    },
    fastTyping(val) {
      if (val == "包含") {
        this.fastTypingShow = false;
      } else {
        this.fastTypingShow = true;
      }
      this.textarea = "";
      this.fastTypingDialogVisible = true;
    },
    get_taskList() {
      this.taskList.splice(0);
      this.searchTaskList.splice(0);
      //获取任务列表
      this.$http.get("/job/pipelineDumpTypeList").then((res) => {
        if (res.result == 200) {
          this.taskList.push(...res.data);
          this.searchTaskList.push(...res.data);
        }
      });
    },
    taskListFilterOption(value) {
      this.taskList.splice(0);
      if (!value) {
        this.taskList.push(...this.searchTaskList);
      } else {
        this.searchTaskList.forEach((item) => {
          if (
            item.cn.indexOf(value) >= 0 ||
            item.en.toLowerCase().indexOf(value) >= 0
          ) {
            this.taskList.push(item);
          }
        });
      }
    },
    getParseJobFileList(val) {
      this.parseJobFileList.splice(0);
      this.parseJobFileTaskList.map((item) => {
        if (item.id == val) {
          this.parseJobFileList.push(...item.path);
        }
      });
    },
    addShortUrlGroupCallback() {
      if (this.viewOnly) {
        return;
      }
      if (this.nodeData.extraInfo.active) {
        this.$set(this.nodeData.extraInfo, "longUrl", "https://www.baidu.com/");
      } else {
        this.$set(this.nodeData.extraInfo, "longUrl", "");
      }
      this.$set(this.nodeData.extraInfo, "longUrlGroupId", undefined);
    },
    getDataFilterAction() {
      this.$http.get("/pipelineNodeInfo/dataFilterAction").then((res) => {
        if (res.result == 200) {
          this.dataFilterAction.splice(0);
          for (const key in res.data) {
            const element = res.data[key];
            let children = element.map((item) => {
              return { value: item, label: item };
            });
            this.dataFilterAction.push({
              value: key,
              label: key == "FILTER" ? "数据过滤" : "数据补充",
              children,
            });
          }
        }
      });
    },
    taskTemplateIdChange(val) {
      let title = "";
      if (this.type == "uploadXinBa") {
        title = this.taskTempList.filter((item) => {
          return item.id == val;
        })[0].title;
      } else {
        title = this.templateList.filter((item) => {
          return item.id == val;
        })[0].templateName;
      }
      this.$set(this.nodeData.extraInfo, "taskTemplateTitle", title);
    },
    uploadXinBaTypeChange() {
      this.$set(this.nodeData.extraInfo, "taskTemplateTitle", "");
      this.$set(this.nodeData.extraInfo, "taskTemplateId", undefined);
      this.loadDynamicApiData("taskTempList");
    },
    columnConversionTypeChange(value) {
      this.$set(
        this.nodeData.extraInfo,
        "typeDesc",
        this.columnTypeList.filter((item) => {
          return item.code == value;
        })[0].desc
      );
      this.$set(this.nodeData.extraInfo, "fields", "");
      this.$set(this.nodeData.extraInfo, "replaceOld", "");
      this.$set(this.nodeData.extraInfo, "replaceNew", "");
      this.$set(this.nodeData.extraInfo, "splitSeparator", ",");
      this.$set(this.nodeData.extraInfo, "splitIndex", "1");
      this.$set(this.nodeData.extraInfo, "splitExpression", "1,1");
      this.$set(this.nodeData.extraInfo, "length", "10");
      this.$set(
        this.nodeData.extraInfo,
        "chars",
        "0123456789abcdefghijklmnopqrstuvwxyz"
      );
      this.$set(this.nodeData.extraInfo, "offset", "-1d");
    },
    fetchSmsSendDataChange() {
      let api = "fields";
      if (!this.nodeData.extraInfo.fetchSmsSendData) {
        api = "fieldsForClick";
      }
      this.smsCustomerCodeFetchFieldList.splice(0);
      this.loadDynamicApiData(api);
    },
    loadDynamicApiData(api) {
      let data = {
        action: this.nodeData.type,
        api,
      };

      if (api == "taskTempList") {
        if (this.nodeData.extraInfo.type) {
          data.type = this.nodeData.extraInfo.type;
        } else {
          return;
        }
      }

      if (api == "tmpStoreList") {
        data.taskId = this.taskId;
      }

      if (api == "longUrlCheck") {
        if (
          this.nodeData.extraInfo.longUrl &&
          this.nodeData.extraInfo.needLongUrlCheck
        ) {
          data.longUrl = this.nodeData.extraInfo.longUrl;
        } else {
          return;
        }
      }

      if (api == "availableCount") {
        data.taskType = this.nodeData.extraInfo.taskType;
        data.accessKey = this.nodeData.extraInfo.accessKey;
      }

      if (api == "description") {
        // 数据补充类型查询列补充说明
        if (this.nodeData.extraInfo.actionSelectList[0] !== "FILL") {
          return;
        }
        data.category = this.nodeData.extraInfo.actionSelectList[1];
      }

      this.$http.get("/sysPipeline/loadDynamicApiData", data).then((res) => {
        if (res.result == 200) {
          if (api == "domainList") {
            this.domainList = res.data;
            if (!this.nodeData.extraInfo.domain) {
              this.nodeData.extraInfo.domain = this.domainList[0];
            }
          } else if (api == "categoryList") {
            this.categoryList = res.data;
          } else if (api == "longUrlGroupList") {
            this.longUrlGroupList = res.data;
          } else if (api == "taskTempList") {
            this.taskTempList = res.data;
          } else if (api == "accountList") {
            this.accountList = res.data;
          } else if (api == "templateList") {
            this.templateList = res.data;
          } else if (api == "conversionType") {
            this.columnTypeList = res.data;
          } else if (api == "randomOffsetColumnType") {
            this.randomOffsetColumnTypeList = res.data;
          } else if (api == "longUrlCheck") {
            this.urlFormat = true;
            this.longUrlExistYesOrNo = res.data.pass;
            this.templateName = res.data.templateName;
          } else if (api == "blacklistSourceList") {
            this.blackNameList = res.data;
          } else if (api == "storageType") {
            this.storageTypeList = res.data;
            if (!this.nodeData.extraInfo.storageType) {
              this.nodeData.extraInfo.storageType =
                this.storageTypeList[0].value;
            }
          } else if (api == "compressTypeList") {
            this.compressTypeList = res.data;
            if (!this.nodeData.extraInfo.targetType) {
              this.nodeData.extraInfo.targetType =
                this.compressTypeList[0].tail;
            }
          } else if (api == "fields" || api == "fieldsForClick") {
            this.smsCustomerCodeFetchFieldSelectList = res.data;
            this.$nextTick(() => {
              if (!this.nodeData.extraInfo.fields) {
                let fieldList = res.data
                  .filter((item) => {
                    return item.checked;
                  })
                  .map((item) => {
                    return item.value;
                  });
                this.smsCustomerCodeFetchFieldList.splice(0);
                this.smsCustomerCodeFetchFieldList.push(...fieldList);
              }
            });
          } else if (api == "tmpStoreList") {
            this.tmpStoreList = res.data;
          } else if (api == "expireRuleTypeList") {
            this.expireRuleTypeList = res.data;
          } else if (api == "tagType") {
            this.tagTypeList = res.data;
          } else if (api == "availableCount") {
            this.nodeData.extraInfo.availableCount = res.data.availableCount;
          } else if (api == "description") {
            this.dataFilterBaseActionDescription = res.data;
          }
        }
      });
    },
    async storeDynamicApiData() {
      let data = {
        taskId: this.taskId,
        api: "storeTagInfo",
        action: this.nodeData.type,
        tag: this.nodeData.extraInfo.tag,
        code: this.nodeData.extraInfo.code,
      };

      return new Promise(async (resolve, reject) => {
        await this.$http.get("/sysPipeline/storeDynamicApiData", data).then(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
      });
    },
    getCategoryList() {
      this.$http.get("/loadConfig", { code: "TD_CATEGORY" }).then((res) => {
        if (res.result == 200) {
          this.categoryList = res.data.data;
          if (!this.nodeData.extraInfo.category && !this.viewOnly) {
            this.$set(
              this.nodeData.extraInfo,
              "category",
              this.categoryList[0]
            );
          }
        }
      });
    },
    getFtpList() {
      this.$http.get("/loadConfig", { code: "TD_FTP" }).then((res) => {
        if (res.result == 200) {
          this.ftpList = res.data.data;
          this.ftpFilePathList.splice(0);
          if (!this.nodeData.extraInfo.filePath && !this.viewOnly) {
            this.ftpFilePathList.push(this.ftpList[0].value);
          } else {
            this.ftpFilePathList.push(
              ...this.nodeData.extraInfo.filePath.split(",")
            );
          }
        }
      });
    },
    getBlackFtpList() {
      this.$http.get("/loadConfig", { code: "TD_BLACK_FTP" }).then((res) => {
        if (res.result == 200) {
          this.blackFtpList = res.data.data;
          this.blacklistFilePathList.splice(0);
          if (!this.nodeData.extraInfo.blacklistFilePath && !this.viewOnly) {
            this.blacklistFilePathList.push(this.blackFtpList[0].value);
          } else {
            this.blacklistFilePathList.push(
              ...this.nodeData.extraInfo.blacklistFilePath.split(",")
            );
          }
        }
      });
    },
    getPipelineFormList() {
      let data = {
        pageNo: 1,
        pageSize: 1000,
        myCollect: false,
        type: "single_task_form",
      };
      this.$http.get("/pipelineForm/list", data).then((res) => {
        if (res.result === 200) {
          this.pipelineFormList = res.data.records;
          if (this.nodeData.extraInfo.formId) {
            this.getPipelineFormComponentList();
          }
        }
      });
    },
    getPipelineFormComponentList(formId) {
      this.pipelineFormComponentList.splice(0);
      if (formId) {
        this.nodeData.extraInfo.itemList.splice(0);
        this.$set(
          this.nodeData.extraInfo,
          "formName",
          this.pipelineFormList.filter((item) => {
            return item.id == formId;
          })[0].name
        );
      }
      this.$http
        .get("/pipelineForm/listAllComponent", {
          formId: this.nodeData.extraInfo.formId,
        })
        .then((res) => {
          if (res.result === 200) {
            let hasFileType = res.data.some((item) => {
              return item.componentType == "FILE";
            });
            if (hasFileType) {
              this.$message.warning("该模板中含有文件上传组件，不符合条件");
              this.$set(this.nodeData.extraInfo, "formId", undefined);
              this.$set(this.nodeData.extraInfo, "formName", "");
            } else {
              if (formId) {
                res.data.map((item) => {
                  this.nodeData.extraInfo.itemList.push({
                    componentId: item.id,
                    value:
                      item.componentType == "DB_MULTIPLE_SELECT"
                        ? item.fillValue || []
                        : item.fillValue || "",
                  });
                });
              }
              this.pipelineFormComponentList.push(...res.data);
            }
          }
        });
    },
    searchParseJobFileTask(val) {
      if (this.searchTimer[this.type]) {
        clearTimeout(this.searchTimer[this.type]);
        this.$set(this.searchTimer, this.type, null);
      }
      this.searchTimer[this.type] = setTimeout(() => {
        this.getParseJobFileTask(val);
      }, 300);
    },
    getParseJobFileTask(val) {
      this.parseJobFileTaskList.splice(0);
      let data = {
        pageNo: 1,
        pageSize: 50,
        myTask: false,
        status: "DONE",
        expired: false,
        id: val,
      };
      this.$http.get("/job/status", data).then((res) => {
        this.parseJobFileTaskList.push(...res.data.records);
      });
    },
    columnTypeChange() {
      if (this.nodeData.extraInfo.columnType == "assign") {
        this.nodeData.extraInfo.separator = ",";
        this.nodeData.extraInfo.index = "";
        this.nodeData.extraInfo.isNumber = false;
      }
    },
    cutTypeChange(val) {
      this.nodeData.extraInfo.param = "";
      this.nodeData.extraInfo.separator = "";
      this.nodeData.extraInfo.targetIndex = "";
      this.nodeData.extraInfo.startIndex = "";
      this.nodeData.extraInfo.endIndex = "";
    },
    getArea() {
      this.$http.get("/getProvinceAndCity").then((res) => {
        if (res.result === 200) {
          this.options.splice(0);
          let data = res.data;
          for (let i in data) {
            let a = {};
            a.value = i;
            a.label = i;
            a.children = [];
            if (data[i] && data[i].length > 0) {
              for (let j of data[i]) {
                let b = {};
                b.value = i + ":" + j;
                b.label = i + " " + j;
                a.children.push(b);
              }
            }
            this.options.push(a);
          }
        }
      });
    },
    delete_multipleFileItem(index) {
      this.multipleFileList.splice(index, 1);
    },
    delete_delimiter(index) {
      this.delimitersList.splice(index, 1);
    },
    delete_fileItem(index) {
      this.priorityFileList.splice(index, 1);
    },
    delete_condition(index) {
      this.conditionList.splice(index, 1);
      this.conditionList.forEach((item, index) => {
        this.$set(
          item,
          "columnIndex",
          this.nodeData.extraInfo.baseColumn.split(",").length + index + 1
        );
      });
    },
    delete_supplement(index) {
      this.supplementList.splice(index, 1);
      this.supplementList.forEach((item, index) => {
        this.$set(item, "index", index + 1);
      });
    },
    delete_variable(index) {
      this.nodeData.extraInfo.variableList.splice(index, 1);
    },
    delete_calculateKeyData(index) {
      this.calculateKeyDataList.splice(index, 1);
    },
    delete_calculateCondition(index) {
      this.calculateConditionList.splice(index, 1);
    },
    delete_inputFileDataCheck(index) {
      this.inputFileDataCheckConditionList.splice(index, 1);
    },
    delete_dynamicColumn(index) {
      this.dynamicColumnData.splice(index, 1);
    },
    multipleFileAdd() {
      let data = {
        value: undefined,
      };
      this.multipleFileList.push(data);
    },
    delimitersAdd() {
      let data = {
        value: "",
      };
      this.delimitersList.push(data);
    },
    priorityFileAdd() {
      let data = {
        priority: "",
        fileName: undefined,
      };
      this.priorityFileList.push(data);
    },
    conditionAdd() {
      let data = {
        columnIndex:
          this.nodeData.extraInfo.baseColumn.split(",").length +
          this.conditionList.length +
          1,
        expression: "",
        value: "1",
        remark: "",
      };
      this.conditionList.push(data);
    },
    supplementAdd() {
      let data = {
        index: this.supplementList.length + 1,
        content: "",
      };
      this.supplementList.push(data);
    },
    variableAdd() {
      let data = {
        row: "",
        col: "",
        name: "",
      };
      this.nodeData.extraInfo.variableList.push(data);
    },
    calculateKeyDataAdd() {
      let data = {
        key: "",
        function: "",
        validSeconds: "",
      };
      this.calculateKeyDataList.push(data);
    },
    calculateConditionAdd() {
      let data = { expression: "", tag: "", value: "" };
      this.calculateConditionList.push(data);
    },
    inputFileDataCheckAdd() {
      let data = { condition: "字符长度", value: "" };
      this.inputFileDataCheckConditionList.push(data);
    },
    dynamicColumnAdd() {
      let data = {
        column: "",
        expression: "",
      };
      this.dynamicColumnData.push(data);
    },
    baseColumnBlur() {
      this.conditionList.forEach((item, index) => {
        this.$set(
          item,
          "columnIndex",
          this.nodeData.extraInfo.baseColumn.split(",").length + index + 1
        );
      });
    },
    allowClearChange(value) {
      if (!value) {
        this.getPipelineLogList();
      }
    },
    searchPipelineLogList(val) {
      if (this.searchTimer[this.type]) {
        clearTimeout(this.searchTimer[this.type]);
        this.$set(this.searchTimer, this.type, null);
      }
      this.searchTimer[this.type] = setTimeout(() => {
        this.getPipelineLogList(val);
      }, 300);
    },
    getPipelineLogList(name, id) {
      this.fileOptionsList.splice(0);
      let data = {
        pageNo: 1,
        pageSize: 100,
        name,
        id,
      };
      this.$http
        .get("/pipelineSourceFile/pipelineSourceFilePage", data)
        .then((res) => {
          if (res.result === 200) {
            this.fileOptionsList.push(...res.data.records);
          }
        });
    },
    random() {
      if (this.type === "zipMerge") {
        this.$set(
          this.nodeData.extraInfo,
          "unzipPassword",
          this.$common.random()
        );
      } else if (this.type === "fileCompression") {
        this.$set(this.nodeData.extraInfo, "password", this.$common.random());
      }
    },
    fileOrDirAdd() {
      this.fileOrDirList.push({ value: "" });
    },
    delete_fileOrDirItem(index) {
      this.fileOrDirList.splice(index, 1);
    },
    fieldMappingAdd() {
      this.fieldMappingList.push({
        sourceField: undefined,
        sinkField: undefined,
        fieldConvertList: [],
      });
    },
    delete_fieldMappingItem(index) {
      this.fieldMappingList.splice(index, 1);
    },
    fieldConvertEdit(record, index) {
      this.fieldConvertList = record.fieldConvertList;
      this.fieldConvertIndex = index;
      this.convertRuleShow = true;
    },
    addFieldConvert() {
      this.updateFieldConvertTitle = "新增";
      this.updateFieldConvertData = {
        convertType: "",
        extraInfo: {},
      };
      this.updateFieldConvertShow = true;
    },
    editFieldConvert(val, index) {
      this.updateFieldConvertTitle = "修改";
      this.updateFieldConvertIndex = index;
      this.updateFieldConvertData = {
        convertType: val.convertType,
        extraInfo: val.extraInfo,
      };
      this.updateFieldConvertShow = true;
    },
    convertTypeChange(convertType) {
      switch (convertType) {
        case "SUBSTRING":
          this.$set(this.updateFieldConvertData, "extraInfo", {
            beginIndex: "",
            endIndex: "",
          });
          break;
        case "REPLACE":
          this.$set(this.updateFieldConvertData, "extraInfo", {
            replaceOld: "",
            replaceNew: "",
          });
          break;
        case "SPLIT":
          this.$set(this.updateFieldConvertData, "extraInfo", {
            splitSeparator: ",",
            splitIndex: "",
          });
          break;
      }
    },
    updateFieldConvertSubmit() {
      if (this.updateFieldConvertTitle == "新增") {
        this.fieldConvertList.push(this.updateFieldConvertData);
        this.$message.success("新增成功");
      } else {
        this.fieldConvertList.splice(
          this.updateFieldConvertIndex,
          1,
          this.updateFieldConvertData
        );
        this.$message.success("修改成功");
      }
      this.updateFieldConvertShow = false;
    },
    deleteFieldConvert(index) {
      this.fieldConvertList.splice(index, 1);
    },
    convertRuleSubmit() {
      this.$set(this.fieldMappingList, "fieldConvertList", [
        ...this.fieldConvertList,
      ]);
      this.$message.success("修改成功");
      this.convertRuleShow = false;
    },
    prevFileContentShowChange() {
      if (this.prevFileContentShow) {
        this.getPrevFileContentList();
      }
    },
    getPrevFileContentList() {
      let data = {
        taskId: this.taskId,
        nodeId: this.nodeData.id,
      };
      this.$http
        .get("/pipelineFile/checkPrevFileContentList", data)
        .then((res) => {
          if (res.result === 200) {
            let list = res.data;
            list.forEach((item) => {
              item.fileContent = item.lines[0].join("<br/>");
              if (item.label) {
                item.fileContent = item.label + ":<br/>" + item.fileContent;
              }
            });
            this.prevFileContentList = list;
          }
        });
    },
    expireRuleChange() {
      if (this.nodeData.extraInfo.expireRule == "UN_EXPIRE") {
        this.$set(this.nodeData.extraInfo, "expireSeconds", "");
      }
    },
    // 初始化
    info(test) {
      this.isTest = test;
      this.dialogVisible = true;
      this.prevFileContentShow = false;
      this.$nextTick(() => {
        this.$set(this.temOptions, "readOnly", this.viewOnly);
        this.$set(this.temOptions, "cursorBlinkRate", this.viewOnly ? -1 : 530);

        if (this.menuData.menuType == "line") {
          this.title = "连线参数设置";
          if (this.menuData.data.label) {
            let fromLabel = "",
              toLabel = "";
            if (this.fromShow && this.toShow) {
              if (this.menuData.data.label.indexOf(":") !== -1) {
                fromLabel = this.menuData.data.label.split(":")[0];
                toLabel = this.menuData.data.label.split(":")[1];
              } else {
                if (this.fromOptionsList.length > 0) {
                  let arr = this.fromOptionsList.filter((item) => {
                    return this.menuData.data.label.indexOf(item.value) !== -1;
                  });
                  if (arr.length > 0) {
                    fromLabel = this.menuData.data.label;
                  }
                }
                if (this.toOptionsList.length > 0) {
                  let arr = this.toOptionsList.filter((item) => {
                    return this.menuData.data.label.indexOf(item.value) !== -1;
                  });
                  if (arr.length > 0) {
                    toLabel = this.menuData.data.label;
                  }
                }
              }
            } else if (this.fromShow && !this.toShow) {
              fromLabel = this.menuData.data.label;
            } else if (!this.fromShow && this.toShow) {
              toLabel = this.menuData.data.label;
            }
            if (this.fromOptionsList.length > 0) {
              if (fromLabel) {
                this.fromLabelList.push(...fromLabel.split(","));
              }
            } else {
              this.fromLabel = fromLabel;
            }
            if (this.toOptionsList.length > 0) {
              if (toLabel) {
                this.toLabelList.push(...toLabel.split(","));
              }
            } else {
              this.toLabel = toLabel;
            }
          }
        } else {
          this.title = this.nodeData.name;
          this.localData = JSON.parse(JSON.stringify(this.nodeData));
          this.menuList.forEach((menu) => {
            menu.children.forEach((nodeData) => {
              if (nodeData.type == this.nodeData.type) {
                this.menuIndex = menu.id;
              }
            });
          });
          switch (this.menuIndex) {
            case 3:
              this.sourceTitle = "输入数据1";
              break;
            case 4:
              this.targetTitle = "成功";
              break;
          }
          if (this.type === "quickFilter") {
            if (this.nodeData.extraInfo.includeArea) {
              this.includeArea = this.nodeData.extraInfo.includeArea.split(",");
            } else {
              this.includeArea = [];
            }
            if (this.nodeData.extraInfo.excludeArea) {
              this.excludeArea = this.nodeData.extraInfo.excludeArea.split(",");
            } else {
              this.excludeArea = [];
            }
          }
          if (this.type === "blacklistFilter") {
            this.blacklistFilterSource.splice(0);
            if (this.nodeData.extraInfo.source) {
              this.blacklistFilterSource =
                this.nodeData.extraInfo.source.split(",");
            }
          }
          if (this.type === "prioritySelector") {
            this.priorityFileList.splice(0);
            if (this.nodeData.extraInfo.priorityFileList) {
              this.priorityFileList = this.nodeData.extraInfo.priorityFileList;
            }
          }
          if (this.type === "multipleFileFilter") {
            this.multipleFileList.splice(0);
            if (this.nodeData.extraInfo.fileList.length > 0) {
              this.nodeData.extraInfo.fileList.map((element, index) => {
                this.multipleFileList.push({ value: element.fileName });
              });
            }
          }
          if (this.type === "multipleColumn2One") {
            this.delimitersList.splice(0);
            if (this.nodeData.extraInfo.delimiters.length > 0) {
              this.nodeData.extraInfo.delimiters.map((element, index) => {
                this.delimitersList.push({ value: element });
              });
            } else {
              this.delimitersList.push({ value: "" });
            }
          }
          if (this.type === "parseJobFileSelect") {
            this.parseJobFileList.splice(0);
            if (this.nodeData.extraInfo.taskId) {
              this.parseJobFileTaskList.map((item) => {
                if (item.id == this.nodeData.extraInfo.taskId) {
                  this.parseJobFileList.push(...item.path);
                }
              });
            }
          }
          if (this.type === "httpApi") {
            this.requestHeader.splice(0);
            this.requestParameter.splice(0);
            this.requestBody.splice(0);
            for (const key in this.nodeData.extraInfo.requestHeader) {
              this.requestHeader.push({
                key,
                value: this.nodeData.extraInfo.requestHeader[key],
              });
            }
            for (const key in this.nodeData.extraInfo.requestParameter) {
              this.requestParameter.push({
                key,
                value: this.nodeData.extraInfo.requestParameter[key],
              });
            }
            for (const key in this.nodeData.extraInfo.requestBody) {
              if (typeof this.nodeData.extraInfo.requestBody[key] == "string") {
                this.requestBody.push({
                  key,
                  value: this.nodeData.extraInfo.requestBody[key],
                  type: "String",
                });
              } else if (
                Array.isArray(this.nodeData.extraInfo.requestBody[key])
              ) {
                let data = [];
                this.nodeData.extraInfo.requestBody[key].forEach(
                  (item, index) => {
                    data.push({ key: index, value: item });
                  }
                );
                data.push({ key: data.length, value: "" });
                this.requestBody.push({
                  key,
                  value: data,
                  type: "Array",
                });
              } else {
                let data = [];
                for (const element in this.nodeData.extraInfo.requestBody[
                  key
                ]) {
                  data.push({
                    key: element,
                    value: this.nodeData.extraInfo.requestBody[key][element],
                  });
                }
                data.push({ key: "", value: "" });
                this.requestBody.push({
                  key,
                  value: data,
                  type: "Object",
                });
              }
            }
            this.requestHeader.push({
              key: "",
              value: "",
            });
            this.requestParameter.push({
              key: "",
              value: "",
            });
            this.requestBody.push({
              key: "",
              value: "",
              type: "String",
            });
          }
          if (this.type == "dataBaseInsert") {
            this.fieldItemList.splice(0);
            if (this.nodeData.extraInfo.fieldItemList.length > 0) {
              this.fieldItemList.push(...this.nodeData.extraInfo.fieldItemList);
            }
          }
          if (this.type == "dataStatistics") {
            this.conditionList.splice(0);
            if (this.nodeData.extraInfo.conditionList.length > 0) {
              this.conditionList.push(...this.nodeData.extraInfo.conditionList);
            }
          }
          if (this.type == "columnSupplement") {
            this.supplementList.splice(0);
            if (this.nodeData.extraInfo.supplementList.length > 0) {
              this.supplementList.push(
                ...this.nodeData.extraInfo.supplementList
              );
            }
          }
          if (this.type == "calculate") {
            this.calculateConditionList.splice(0);
            if (this.nodeData.extraInfo.conditionList.length > 0) {
              this.calculateConditionList.push(
                ...this.nodeData.extraInfo.conditionList
              );
            } else {
              this.calculateConditionList = [
                { expression: "", tag: "", value: "" },
                { expression: "", tag: "", value: "" },
              ];
            }
            this.calculateKeyDataList.splice(0);
            if (this.nodeData.extraInfo.keyDataList.length > 0) {
              this.calculateKeyDataList.push(
                ...this.nodeData.extraInfo.keyDataList
              );
            } else {
              this.calculateKeyDataList.push({
                key: "",
                function: "",
                validSeconds: "",
              });
            }
          }
          if (this.type == "inputFileDataCheck") {
            this.inputFileDataCheckConditionList.splice(0);
            if (this.nodeData.extraInfo.conditionList.length > 0) {
              this.inputFileDataCheckConditionList.push(
                ...this.nodeData.extraInfo.conditionList
              );
            } else {
              this.inputFileDataCheckConditionList = [
                { condition: "字符长度", value: "" },
              ];
            }
          }
          if (this.type == "join") {
            if (!this.nodeData.extraInfo.outputFormaSelectShow) {
              this.$set(
                this.nodeData.extraInfo,
                "outputFormaSelectShow",
                false
              );
            }
            this.outputFormatList.splice(0);
            let outputFormatList =
              this.nodeData.extraInfo.outputFormat.split(" ");
            outputFormatList.forEach((item, index) => {
              this.outputFormatList.push({
                id: this.$common.randomStr(),
                label:
                  item.split(".")[0].split("-")[1] +
                  ".第" +
                  item.split(".")[1] +
                  "列",
                value: item,
                color: item.split(".")[0] == "-o1" ? "#2db7f5" : "#87d068",
              });
            });
          }

          if (this.type == "columnAppend") {
            this.loadDynamicApiData("tagType");
            if (this.nodeData.extraInfo.type == "DYNAMIC_COLUMN_CONNECT") {
              this.dynamicColumnData.splice(0);
              if (this.nodeData.extraInfo.content) {
                this.dynamicColumnData.push(
                  ...JSON.parse(this.nodeData.extraInfo.content)
                );
              }
            } else if (this.nodeData.extraInfo.type == "TAG") {
              this.columnAppendTagList.splice(0);
              if (this.nodeData.extraInfo.content) {
                this.columnAppendTagList.push(
                  ...this.nodeData.extraInfo.content.split(",")
                );
              }
            }
          }

          if (this.type == "fileLoad") {
            if (this.nodeData.extraInfo.uploadType == "FILE_LIST") {
              this.getFileList();
            }
            if (this.nodeData.extraInfo.uploadType == "TMP_STORE") {
              this.loadDynamicApiData("tmpStoreList");
            }
            if (this.nodeData.extraInfo.uploadType == "FTP") {
              this.getFtpServerList();
              this.ftpFileList.splice(0);
              if (this.nodeData.extraInfo.ftpId) {
                this.getFtpFileList();
              }
            }
            if (this.nodeData.extraInfo.uploadType == "FILE_SET") {
              if (!this.nodeData.extraInfo.fileCollectLimitCountShow) {
                this.$set(
                  this.nodeData.extraInfo,
                  "fileCollectLimitCountShow",
                  false
                );
                this.$set(this.nodeData.extraInfo, "fileCollectLimitCount", "");
              }
            }
          }
          if (
            this.type == "databaseLoad" ||
            this.type == "sqlBatchQuery" ||
            this.type == "dataBaseUpdate" ||
            this.type == "databaseSelect" ||
            this.type == "dataBaseInsert" ||
            this.type == "sync2Clickhouse" ||
            this.type == "maxwellFilter" ||
            this.type == "databaseSelectColumnSupplement" ||
            this.type == "database2Maxwell" ||
            this.type == "maxwell2Database"
          ) {
            this.getPipelineDataSourceList();
          }
          if (
            (this.type == "fileLoad" &&
              this.nodeData.extraInfo.uploadType == "FILE_SET") ||
            this.type === "fileUploadCollect" ||
            this.type == "fileCollectDistinct"
          ) {
            this.getFileCollectList();
          }

          if (
            this.type === "fileUploadCollect" ||
            this.type == "fileListUpload" ||
            this.type == "uploadFileSystem"
          ) {
            if (!this.nodeData.extraInfo?.expireDays) {
              if (this.nodeData.extraInfo?.expireSeconds <= 0) {
                this.$set(this.nodeData.extraInfo, "expireDays", 0);
                this.expireDaysShow = false;
              } else {
                this.$set(
                  this.nodeData.extraInfo,
                  "expireDays",
                  this.nodeData.extraInfo.expireSeconds / 86400
                );
                this.expireDaysShow = true;
              }
            }
          }

          if (this.type == "shortUrlCreate") {
            this.getDomainList();
          }
          if (this.type == "quickFilter" || this.type == "ai00166") {
            this.getArea();
          }
          if (
            this.type === "prioritySelector" ||
            this.type === "multipleFileFilter" ||
            this.type === "contentAppend" ||
            this.type == "multipleFileFilter"
          ) {
            this.getPipelineLogList();
          }
          if (
            this.type === "blacklistFilter" ||
            this.type === "uploadBlacklist"
          ) {
            this.loadDynamicApiData("blacklistSourceList");
          }

          if (this.type == "fileSelect") {
            if (this.nodeData.extraInfo?.fileId) {
              this.nodeData.extraInfo.active = "1";
            }
            this.getFileGroupList();
            this.getPipelineLogList(null, this.nodeData.extraInfo.fileId);
          }
          if (this.type == "parseJobPipeline") {
            this.get_taskList();
          }
          if (this.type == "parseJobFileSelect") {
            this.getParseJobFileTask();
          }
          if (this.type == "alarm") {
            if (this.nodeData.extraInfo.userLoginName) {
              this.userLoginNameList.splice(0);
              this.userLoginNameList.push(
                ...this.nodeData.extraInfo.userLoginName.split(",")
              );
            }
          }
          if (this.type == "pauseNotify") {
            if (this.nodeData.extraInfo.notifyUsers) {
              this.userLoginNameList.splice(0);
              this.userLoginNameList.push(
                ...this.nodeData.extraInfo.notifyUsers.split(",")
              );
            }
          }
          if (this.type == "ossUpload") {
            this.getBucketNameList();
            this.loadDynamicApiData("storageType");
          }
          if (this.type == "addShortUrlGroup") {
            this.loadDynamicApiData("categoryList");
            this.loadDynamicApiData("domainList");
            this.loadDynamicApiData("longUrlGroupList");
            this.loadDynamicApiData("expireRuleTypeList");
          }
          if (this.type == "dataFilterBaseAction") {
            this.getDataFilterAction();
            if (this.nodeData.extraInfo.action) {
              this.loadDynamicApiData("description");
            } else {
              this.dataFilterBaseActionDescription = "";
            }
          }
          if (this.type == "uploadXinBa") {
            this.loadDynamicApiData("accountList");
            this.loadDynamicApiData("taskTempList");
          }
          if (this.type == "uploadQuDian") {
            this.loadDynamicApiData("templateList");
          }
          if (this.type == "columnConversion") {
            this.loadDynamicApiData("conversionType");
          }
          if (this.type == "columnRandomOffset") {
            this.loadDynamicApiData("randomOffsetColumnType");
          }
          if (this.type == "smsCustomerCodeFetch") {
            let api = "fields";
            if (!this.nodeData.extraInfo.fetchSmsSendData) {
              api = "fieldsForClick";
            }
            this.loadDynamicApiData(api);
            this.smsCustomerCodeFetchFieldList.splice(0);
            if (this.nodeData.extraInfo.fields) {
              this.smsCustomerCodeFetchFieldList.push(
                ...this.nodeData.extraInfo.fields.split(",")
              );
            }
            if (
              this.nodeData.extraInfo.hasOwnProperty("success") &&
              !this.nodeData.extraInfo.hasOwnProperty("sendStatus")
            ) {
              this.$set(
                this.nodeData.extraInfo,
                "sendStatus",
                this.nodeData.extraInfo.success ? "SUCCESS" : ""
              );
            }
          }
          if (this.type == "fileCompression") {
            this.loadDynamicApiData("compressTypeList");
            this.fileOrDirList.splice(0);
            if (this.nodeData.extraInfo.fileOrDirList.length > 0) {
              this.nodeData.extraInfo.fileOrDirList.forEach((value) => {
                this.fileOrDirList.push({ value });
              });
            }
          }
          if (this.type == "maxwellFilter") {
            this.maxwellFilterTypeList.splice(0);
            if (this.nodeData.extraInfo.types.split(",").length > 0) {
              this.maxwellFilterTypeList.push(
                ...this.nodeData.extraInfo.types.split(",")
              );
            }
          }
          if (this.type == "maxwell2Database") {
            this.fieldMappingList.splice(0);
            if (this.nodeData.extraInfo.fieldMappingList.length > 0) {
              this.fieldMappingList.push(
                ...this.nodeData.extraInfo.fieldMappingList
              );
            }
          }
          if (this.type == "ai00166") {
            this.carrierList.splice(0);
            this.carrierList.push(
              ...this.nodeData.extraInfo.carrier.split(",")
            );
            this.ageList.splice(0);
            if (this.nodeData.extraInfo.age) {
              this.ageList.push(...this.nodeData.extraInfo.age.split(","));
            } else {
              this.ageList.push("");
            }
            this.getCategoryList();
            this.getFtpList();
            this.getBlackFtpList();
          }
          if (this.type == "createFormTask") {
            this.getPipelineFormList();
          }
        }
      });
    },
    async submit() {
      if (this.menuData.menuType == "line") {
        if (this.fromLabelList.length > 0) {
          this.fromLabel = this.fromLabelList.join(",");
        }
        if (this.toLabelList.length > 0) {
          this.toLabel = this.toLabelList.join(",");
        }
        let label = "";
        if (this.fromShow && this.toShow) {
          if (this.fromLabel && this.toLabel) {
            label = this.fromLabel + ":" + this.toLabel;
          } else if (this.fromLabel) {
            label = this.fromLabel;
          } else {
            label = this.toLabel;
          }
        } else if (this.fromShow && !this.toShow) {
          label = this.fromLabel;
        } else if (!this.fromShow && this.toShow) {
          label = this.toLabel;
        }
        this.$emit(
          "setLineLabel",
          this.menuData.data.from,
          this.menuData.data.to,
          label
        );
        this.dialogVisible = false;
        this.$message.success("设置成功");
      } else {
        let data = {
          taskId: this.taskId,
          nodeId: this.nodeData.id,
          type: this.nodeData.type,
          extraInfo: {},
        };

        if (this.type === "fileLoad") {
          if (this.nodeData.extraInfo.uploadType == "LOCAL_FILE") {
            this.$set(
              this.nodeData.property,
              "resultFileType",
              this.$common.getFileExtension(this.nodeData.extraInfo.path)
            );
            this.$set(
              this.nodeData.property,
              "resultFileName",
              this.nodeData.property.resultFileName.split(".")[0] +
                "." +
                this.nodeData.property.resultFileType
            );
          }
        } else if (this.type === "httpApi") {
          this.requestHeader.forEach((element, index) => {
            if (index < this.requestHeader.length - 1) {
              this.$set(
                this.nodeData.extraInfo.requestHeader,
                element.key,
                element.value
              );
            }
          });
          this.requestParameter.forEach((element, index) => {
            if (index < this.requestParameter.length - 1) {
              this.$set(
                this.nodeData.extraInfo.requestParameter,
                element.key,
                element.value
              );
            }
          });
          this.requestBody.forEach((element, index) => {
            if (index < this.requestBody.length - 1) {
              if (element.type == "String") {
                this.$set(
                  this.nodeData.extraInfo.requestBody,
                  element.key,
                  element.value
                );
              } else if (element.type == "Object") {
                let data = {};
                element.value.forEach((item, itemIndex) => {
                  if (itemIndex < element.value.length - 1) {
                    data[item.key] = item.value;
                  }
                });
                this.$set(
                  this.nodeData.extraInfo.requestBody,
                  element.key,
                  data
                );
              } else if (element.type == "Array") {
                let data = [];
                element.value.forEach((item, itemIndex) => {
                  if (itemIndex < element.value.length - 1) {
                    data[item.key] = item.value;
                  }
                });
                this.$set(
                  this.nodeData.extraInfo.requestBody,
                  element.key,
                  data
                );
              }
            }
          });
        } else if (this.type === "databaseLoad") {
          data.extraInfo = {
            datasourceId: this.nodeData.extraInfo.datasourceId,
            databaseName: this.nodeData.extraInfo.databaseName,
            sql: this.nodeData.extraInfo.sql,
            skipTitle: this.nodeData.extraInfo.skipTitle,
            active: this.nodeData.extraInfo.active,
            maxwell: this.nodeData.extraInfo.maxwell,
          };
          if (this.nodeData.extraInfo.active == "2") {
            data.extraInfo.tablesType = this.nodeData.extraInfo.tablesType;
            if (data.extraInfo.tablesType == "custom") {
              data.extraInfo.tables = this.nodeData.extraInfo.tables;
            } else if (data.extraInfo.tablesType == "0-9") {
              data.extraInfo.tables = "0,1,2,3,4,5,6,7,8,9";
            } else {
              data.extraInfo.tables = "0,1,2,3,4,5,6,7,8,9,a,b,c,d,e,f";
            }
          } else if (this.nodeData.extraInfo.active == "3") {
            data.extraInfo.param = this.nodeData.extraInfo.param;
            data.extraInfo.separator = this.nodeData.extraInfo.separator;
            delete data.extraInfo.maxwell;
          }

          let arr = this.dataSourceIdList.filter((item) => {
            return item.id == this.nodeData.extraInfo.datasourceId;
          });
          if (arr.length > 0) {
            data.extraInfo.databaseName = arr[0].databaseName;
          }
          this.$set(this.nodeData, "extraInfo", data.extraInfo);
        } else if (this.type === "prioritySelector") {
          data.extraInfo = {
            poolFileName: this.nodeData.extraInfo.poolFileName,
            count: this.nodeData.extraInfo.count,
            appendPool: this.nodeData.extraInfo.appendPool,
          };
          this.fileOptionsList.map((item) => {
            if (data.extraInfo.poolFileName == item.name) {
              data.extraInfo.poolFileId = item.id;
            }
            this.priorityFileList.forEach((fileItem, index) => {
              if (fileItem.fileName == item.name) {
                this.$set(this.priorityFileList[index], "fileId", item.id);
              }
            });
          });
          data.extraInfo.priorityFileList = this.priorityFileList;
          this.$set(this.nodeData, "extraInfo", data.extraInfo);
        } else if (this.type === "multipleFileFilter") {
          data.extraInfo.fileList = [];
          this.multipleFileList.forEach((item) => {
            data.extraInfo.fileList.push({
              fileId: "",
              fileName: item.value,
            });
          });
          data.extraInfo.fileList.forEach((item) => {
            this.fileOptionsList.forEach((fileItem) => {
              if (item.fileName == fileItem.name) {
                item.fileId = fileItem.id;
              }
            });
          });
          this.$set(this.nodeData, "extraInfo", data.extraInfo);
        } else if (this.type === "quickFilter") {
          data.extraInfo = {
            includeOperator: this.nodeData.extraInfo.includeOperator,
            countLimitEnabled: this.nodeData.extraInfo.countLimitEnabled,
            excludeArea: "",
            includeArea: "",
            segments: this.nodeData.extraInfo.segments,
            columnIndex: this.nodeData.extraInfo.columnIndex,
          };
          if (this.nodeData.extraInfo.countLimitEnabled) {
            data.extraInfo.limitCount = this.nodeData.extraInfo.limitCount;
          }
          if (this.excludeArea.length > 0) {
            data.extraInfo.excludeArea = this.excludeArea.join(",");
          }
          if (this.includeArea.length > 0) {
            data.extraInfo.includeArea = this.includeArea.join(",");
          }
          this.$set(this.nodeData, "extraInfo", data.extraInfo);
        } else if (this.type === "sort") {
          data.extraInfo = {
            unique: this.nodeData.extraInfo.unique,
            reverse: this.nodeData.extraInfo.reverse,
            columnType: this.nodeData.extraInfo.columnType,
            useSpark: this.nodeData.extraInfo.useSpark,
          };
          if (this.nodeData.extraInfo.columnType == "assign") {
            data.extraInfo.index = this.nodeData.extraInfo.index;
            data.extraInfo.separator = this.nodeData.extraInfo.separator;
            data.extraInfo.isNumber = this.nodeData.extraInfo.isNumber;
          }
          this.$set(this.nodeData, "extraInfo", data.extraInfo);
        } else if (this.type === "blacklistFilter") {
          data.extraInfo = {
            source: this.blacklistFilterSource.join(","),
          };
          this.$set(this.nodeData, "extraInfo", data.extraInfo);
        } else if (this.type === "cut") {
          data.extraInfo = {
            cutType: this.nodeData.extraInfo.cutType,
          };
          if (data.extraInfo.cutType == "自定义") {
            data.extraInfo.param = this.nodeData.extraInfo.param;
          } else if (data.extraInfo.cutType == "固定长度") {
            if (
              !this.nodeData.extraInfo.startIndex ||
              !this.nodeData.extraInfo.endIndex
            ) {
              this.$message.warning("起始位置和结束位置不可为空");
              return;
            }
            data.extraInfo.startIndex = this.nodeData.extraInfo.startIndex;
            data.extraInfo.endIndex = this.nodeData.extraInfo.endIndex;
            data.extraInfo.param =
              "-c " +
              this.nodeData.extraInfo.startIndex +
              "-" +
              this.nodeData.extraInfo.endIndex;
          } else if (data.extraInfo.cutType == "分隔截取") {
            data.extraInfo.separator = this.nodeData.extraInfo.separator;
            data.extraInfo.targetIndex = this.nodeData.extraInfo.targetIndex;
          }
          this.$set(this.nodeData, "extraInfo", data.extraInfo);
        } else if (this.type === "fileSelect") {
          if (this.nodeData.extraInfo.active == "1") {
            data.extraInfo = {
              fileId: this.nodeData.extraInfo.fileId,
              active: "1",
            };
            this.fileOptionsList.map((item) => {
              if (data.extraInfo.fileId == item.id) {
                data.extraInfo.fileName = item.name;
              }
            });
          } else {
            data.extraInfo = {
              fileIdList: this.nodeData.extraInfo.fileIdList,
              taskId: this.nodeData.extraInfo.taskId,
              active: "2",
            };
          }
          this.$set(this.nodeData, "extraInfo", data.extraInfo);
        } else if (this.type === "parseJobFileSelect") {
          data.extraInfo = {
            fileId: this.nodeData.extraInfo.fileId,
            taskId: this.nodeData.extraInfo.taskId,
          };
          this.parseJobFileList.map((item) => {
            if (data.extraInfo.fileId == item.fileId) {
              data.extraInfo.fileName = item.name;
            }
          });
          this.$set(this.nodeData, "extraInfo", data.extraInfo);
        } else if (this.type === "contentAppend") {
          data.extraInfo = {
            fileName: this.nodeData.extraInfo.fileName,
            unique: this.nodeData.extraInfo.unique,
            rewrite: this.nodeData.extraInfo.rewrite,
          };
          this.fileOptionsList.map((item) => {
            if (data.extraInfo.fileName == item.name) {
              data.extraInfo.fileId = item.id;
            }
          });
          this.$set(this.nodeData, "extraInfo", data.extraInfo);
        } else if (this.type === "multipleColumn2One") {
          data.extraInfo = {
            delimiters: [],
            filterBlankLine: this.nodeData.extraInfo.filterBlankLine,
          };
          this.delimitersList.map((item) => {
            data.extraInfo.delimiters.push(item.value);
          });
          this.$set(this.nodeData, "extraInfo", data.extraInfo);
        } else if (this.type === "dataBaseInsert") {
          data.extraInfo = {
            fieldItemList: [...this.fieldItemList],
            tableName: this.nodeData.extraInfo.tableName,
            separator: this.nodeData.extraInfo.separator,
            datasourceId: this.nodeData.extraInfo.datasourceId,
            tableNameInputShow: this.nodeData.extraInfo.tableNameInputShow
              ? true
              : false,
          };
          this.$set(this.nodeData, "extraInfo", data.extraInfo);
        } else if (this.type == "dataStatistics") {
          data.extraInfo = {
            conditionList: [...this.conditionList],
            baseColumn: this.nodeData.extraInfo.baseColumn,
            separator: this.nodeData.extraInfo.separator,
          };
          this.$set(this.nodeData, "extraInfo", data.extraInfo);
        } else if (this.type == "contentFormat") {
          data.extraInfo = {
            type: this.nodeData.extraInfo.type,
          };
          if (data.extraInfo.type == "format") {
            data.extraInfo.separator = this.nodeData.extraInfo.separator;
            data.extraInfo.format = this.nodeData.extraInfo.format;
            data.extraInfo.handleSpecialCharacter =
              this.nodeData.extraInfo.handleSpecialCharacter;
          }
          this.$set(this.nodeData, "extraInfo", data.extraInfo);
        } else if (this.type === "calculate") {
          data.extraInfo = {
            keyDataList: [],
            conditionList: [],
          };
          data.extraInfo.keyDataList.push(...this.calculateKeyDataList);
          data.extraInfo.conditionList.push(...this.calculateConditionList);
          this.$set(this.nodeData, "extraInfo", data.extraInfo);
        } else if (this.type === "columnSupplement") {
          data.extraInfo = {
            supplementList: [],
            separator: this.nodeData.extraInfo.separator,
          };
          data.extraInfo.supplementList.push(...this.supplementList);
          this.$set(this.nodeData, "extraInfo", data.extraInfo);
        } else if (this.type === "inputFileDataCheck") {
          data.extraInfo = {
            columnType: this.nodeData.extraInfo.columnType,
            columnIndex: "",
            passRate: this.nodeData.extraInfo.passRate,
            conditionList: [],
          };
          if (data.extraInfo.columnType == "INDEX_COLUMNS") {
            data.extraInfo.columnIndex = this.nodeData.extraInfo.columnIndex;
          }

          data.extraInfo.conditionList.push(
            ...this.inputFileDataCheckConditionList
          );
          this.$set(this.nodeData, "extraInfo", data.extraInfo);
        } else if (this.type == "addShortUrlGroup") {
          if (!this.longUrlExistYesOrNo && this.nodeData.extraInfo.active) {
            this.$message.success({
              message: "长链未匹配到任何模板，请修改",
              type: "error",
            });
            return;
          }
          const unitsInSeconds = {
            月: 30 * 24 * 60 * 60, // 假设每月按30天算
            天: 24 * 60 * 60,
            小时: 60 * 60,
            分钟: 60,
            秒: 1,
          };
          this.$set(
            this.nodeData.extraInfo,
            "expireSeconds",
            this.nodeData.extraInfo.expireTime *
              1 *
              unitsInSeconds[this.nodeData.extraInfo.expireUnit]
          );
        } else if (this.type === "zipMerge") {
          if (!this.nodeData.extraInfo.passwordSwitch) {
            this.$set(this.nodeData.extraInfo, "unzipPassword", "");
          }
        } else if (this.type == "textToExcel") {
          if (this.nodeData.extraInfo.type == "CSV") {
            this.$set(
              this.nodeData.property,
              "resultFileName",
              this.nodeData.property.resultFileName.split(".")[0] + ".csv"
            );
            this.$set(this.nodeData.property, "resultFileType", "csv");
          } else {
            this.$set(
              this.nodeData.property,
              "resultFileName",
              this.nodeData.property.resultFileName.split(".")[0] + ".xlsx"
            );
            this.$set(this.nodeData.property, "resultFileType", "xlsx");
          }
        } else if (this.type === "fileCompression") {
          data.extraInfo = {
            passwordSwitch: this.nodeData.extraInfo.passwordSwitch,
            password: this.nodeData.extraInfo.password,
            targetType: this.nodeData.extraInfo.targetType,
            jobId: this.nodeData.extraInfo.jobId,
            fileOrDirList: [],
          };
          if (!data.extraInfo.passwordSwitch) {
            data.extraInfo.password = "";
          }
          if (this.fileOrDirList.length > 0) {
            this.fileOrDirList.forEach((item) => {
              data.extraInfo.fileOrDirList.push(item.value);
            });
          }
          this.$set(this.nodeData, "extraInfo", data.extraInfo);
        } else if (this.type === "maxwellFilter") {
          data.extraInfo = {
            database: this.nodeData.extraInfo.database,
            datasourceId: this.nodeData.extraInfo.datasourceId,
            table: this.nodeData.extraInfo.table,
            types: this.nodeData.extraInfo.types,
            filterColumn: this.nodeData.extraInfo.filterColumn,
            filterOperation: this.nodeData.extraInfo.filterOperation
              ? this.nodeData.extraInfo.filterOperation
              : "equals",
            filterColumnValue: this.nodeData.extraInfo.filterColumnValue,
            filterColumnSwitch: this.nodeData.extraInfo.filterColumnSwitch,
          };
          if (!data.extraInfo.filterColumnSwitch) {
            data.extraInfo.filterColumn = "";
            data.extraInfo.filterColumnValue = "";
          }
          if (!data.extraInfo.filterColumn) {
            data.extraInfo.filterColumnSwitch = false;
          }
          data.extraInfo.types = this.maxwellFilterTypeList.join(",");
          this.$set(this.nodeData, "extraInfo", data.extraInfo);
        } else if (this.type === "maxwell2Database") {
          data.extraInfo = {
            datasourceId: this.nodeData.extraInfo.datasourceId,
            sourceDatabaseName: this.nodeData.extraInfo.sourceDatabaseName,
            sourceTableName: this.nodeData.extraInfo.sourceTableName,
            sourceTableType: this.nodeData.extraInfo.sourceTableType,
            sinkDataSourceId: this.nodeData.extraInfo.sinkDataSourceId,
            sinkDatabaseName: this.nodeData.extraInfo.sinkDatabaseName,
            sinkTableName: this.nodeData.extraInfo.sinkTableName,
            fieldMappingList: [...this.fieldMappingList],
          };
          this.$set(this.nodeData, "extraInfo", data.extraInfo);
        } else if (this.type === "smsCustomerCodeFetch") {
          data.extraInfo = {
            fetchSmsSendData: this.nodeData.extraInfo.fetchSmsSendData,
            sendStatus: this.nodeData.extraInfo.sendStatus,
            batchNameType: this.nodeData.extraInfo.batchNameType,
            datasourceId: this.nodeData.extraInfo.datasourceId,
            selfUnique: this.nodeData.extraInfo.selfUnique,
            fields: this.smsCustomerCodeFetchFieldList.join(","),
          };
          this.$set(this.nodeData, "extraInfo", data.extraInfo);
        } else if (this.type === "ai00166") {
          data.extraInfo = {
            batchName: this.nodeData.extraInfo.batchName,
            carrier: this.carrierList.join(","),
            region: this.nodeData.extraInfo.region,
            age: this.ageList.join(","),
            sex: this.nodeData.extraInfo.sex,
            ruleDk: this.nodeData.extraInfo.ruleDk,
            period: this.nodeData.extraInfo.period,
            filePath: this.ftpFilePathList.join(","),
            blacklistFilePath: this.blacklistFilePathList.join(","),
            excludeArea: this.nodeData.extraInfo.excludeArea,
            model: this.nodeData.extraInfo.model,
            category: this.nodeData.extraInfo.category,
          };
          this.$set(this.nodeData, "extraInfo", data.extraInfo);
        } else if (this.type == "dataFilterBaseAction") {
          data.extraInfo = {
            action:
              this.nodeData.extraInfo.actionSelectList.length > 0
                ? this.nodeData.extraInfo.actionSelectList[1]
                : undefined,
            actionSelectList: this.nodeData.extraInfo.actionSelectList,
          };
          this.$set(this.nodeData, "extraInfo", data.extraInfo);
        } else if (this.type == "columnAppend") {
          if (this.nodeData.extraInfo.type == "DYNAMIC_COLUMN_CONNECT") {
            if (this.dynamicColumnData.length > 0) {
              this.$set(
                this.nodeData.extraInfo,
                "content",
                JSON.stringify(this.dynamicColumnData)
              );
            }
          } else if (this.nodeData.extraInfo.type == "TAG") {
            this.$set(
              this.nodeData.extraInfo,
              "content",
              this.columnAppendTagList.join(",")
            );
          }
        } else if (this.type == "fileStore") {
          data.extraInfo = {
            tag: this.nodeData.extraInfo.tag,
            code: this.nodeData.extraInfo.code,
          };
          if (data.extraInfo.tag) {
            if (!data.extraInfo.code) {
              await this.$http
                .get("/sysPipeline/generateName")
                .then(async (res) => {
                  if (res.result === 200) {
                    data.extraInfo.code = res.data.name;
                    this.$set(this.nodeData, "extraInfo", data.extraInfo);
                    await this.storeDynamicApiData().catch((err) => {
                      return;
                    });
                  }
                });
            } else {
              await this.storeDynamicApiData().catch((err) => {
                return;
              });
            }
          }
        } else if (
          this.type === "fileUploadCollect" ||
          this.type == "fileListUpload" ||
          this.type == "uploadFileSystem"
        ) {
          this.$set(
            this.nodeData.extraInfo,
            "expireSeconds",
            this.nodeData.extraInfo.expireDays > 0
              ? this.nodeData.extraInfo.expireDays * 86400
              : this.nodeData.extraInfo.expireDays
          );
        } else if (this.type == "alarm") {
          data.extraInfo = {
            content: this.nodeData.extraInfo.content,
            userLoginName: this.userLoginNameList.join(","),
          };
          this.$set(this.nodeData, "extraInfo", data.extraInfo);
        } else if (this.type == "pauseNotify") {
          data.extraInfo = {
            notifyUsers: this.userLoginNameList.join(","),
          };
          this.$set(this.nodeData, "extraInfo", data.extraInfo);
        }

        if (this.isTest) {
          let nodeInfo = JSON.stringify(this.nodeData, null, 4).toString();
          let testData = {
            nodeInfo: nodeInfo,
          };
          if (this.menuIndex === 2 || this.menuIndex === 4) {
            testData.inputOne = this.inputTextarea;
          }
          if (this.menuIndex === 3) {
            testData.inputOne = this.inputTextarea;
            testData.inputTwo = this.inputTextarea2;
          }
          this.syncLoading = this.$syncLoading({
            message: "请稍等...",
          });
          this.$http
            .json_post("/sysPipeline/singlePipelineTest", testData)
            .then((res) => {
              if (res.result === 200) {
                this.$message.success("测试成功");
                this.syncLoading.close();
                if (this.menuIndex === 4) {
                  this.outputTextarea = res.data[0];
                  this.outputTextarea2 = res.data[1];
                } else {
                  this.outputTextarea = res.data[0];
                }
              }
            });
        } else {
          this.dialogVisible = false;
          this.$message.success("设置成功");
          if (this.nodeData.state !== "WAITING") {
            let oldExtraInfo = JSON.stringify(this.localData.extraInfo);
            let newExtraInfo = JSON.stringify(this.nodeData.extraInfo);
            if (oldExtraInfo !== newExtraInfo) {
              this.$emit("setClick", this.nodeData.id, "resetState_click");
            }
          }
        }
      }
    },
    closed() {
      if (this.type == "fileLoad") {
        if (this.nodeData.extraInfo.uploadType == "UPLOAD") {
          this.fileList.splice(0);
        }
      }
      this.inputTextarea = "";
      this.inputTextarea2 = "";
      this.outputTextarea = "";
      this.outputTextarea2 = "";
      this.fromLabel = "";
      this.toLabel = "";
      this.fromLabelList.splice(0);
      this.toLabelList.splice(0);

      if (this.menuData.menuType !== "line") {
        this.$set(this.nodeData, "name", this.title);
        this.$parent.modifyTaskNode(
          "MODIFY",
          this.localData,
          this.nodeData,
          true
        );
        this.$parent.recordModifySubmit();
      }
      this.$parent.dataReload(JSON.parse(JSON.stringify(this.data)));
    },
    searchPipelineDataSourceList(val) {
      if (this.searchTimer[this.type]) {
        clearTimeout(this.searchTimer[this.type]);
        this.$set(this.searchTimer, this.type, null);
      }
      this.searchTimer[this.type] = setTimeout(() => {
        this.getPipelineDataSourceList(val);
      }, 300);
    },
    // 查询数据库
    getPipelineDataSourceList(name) {
      this.dataSourceIdList.splice(0);
      this.$http
        .get("/pipelineDatasource/pipelineDatasourceList", { name })
        .then((res) => {
          if (res.result === 200) {
            this.dataSourceIdList.push(...res.data);
            if (name || this.nodeData.extraInfo.datasourceId) {
              this.getTableList();
            }
            if (
              this.type == "maxwell2Database" &&
              this.nodeData.extraInfo.sinkDataSourceId
            ) {
              this.getTableList(true);
            }
          }
        });
    },
    dataSourceChange() {
      if (this.type == "maxwellFilter") {
        this.$set(this.nodeData.extraInfo, "table", undefined);
      } else if (this.type == "maxwell2Database") {
        this.$set(this.nodeData.extraInfo, "sourceTableName", undefined);
      } else {
        this.$set(this.nodeData.extraInfo, "tableName", undefined);
      }
      this.getTableList();
    },
    sinkDataSourceChange() {
      this.$set(this.nodeData.extraInfo, "sinkTableName", undefined);
      this.getTableList(true);
    },
    getTableList(sink) {
      if (
        this.type === "databaseLoad" ||
        this.type == "sqlBatchQuery" ||
        this.type == "dataBaseUpdate" ||
        this.type == "databaseSelect" ||
        this.type == "databaseSelectColumnSupplement"
      ) {
        return;
      }
      let datasourceId = null;
      if (sink) {
        this.sinkTableList.splice(0);
        datasourceId = this.nodeData.extraInfo.sinkDataSourceId;

        let arr = this.dataSourceIdList.filter((item) => {
          return item.id == datasourceId;
        });
        if (arr.length == 0) {
          return;
        }
        this.$set(
          this.nodeData.extraInfo,
          "sinkDatabaseName",
          arr[0].databaseName
        );
      } else {
        this.tableList.splice(0);
        datasourceId = this.nodeData.extraInfo.datasourceId;

        let arr = this.dataSourceIdList.filter((item) => {
          return item.id == datasourceId;
        });
        if (arr.length == 0) {
          return;
        }
        if (this.type == "dataBaseInsert") {
          this.$set(
            this.nodeData.extraInfo,
            "datasourceName",
            arr[0].databaseName
          );
        } else if (this.type == "maxwellFilter") {
          this.$set(this.nodeData.extraInfo, "database", arr[0].databaseName);
        } else if (this.type == "database2Maxwell") {
          this.$set(
            this.nodeData.extraInfo,
            "datasourceName",
            arr[0].databaseName
          );
        } else if (this.type == "maxwell2Database") {
          this.$set(
            this.nodeData.extraInfo,
            "sourceDatabaseName",
            arr[0].databaseName
          );
        }
      }
      this.$http.get("/dataBase/tableList", { datasourceId }).then((res) => {
        if (res.result === 200) {
          if (sink) {
            this.sinkTableList.push(...res.data);
            if (this.nodeData.extraInfo.sinkTableName) {
              this.getFieldList(
                datasourceId,
                this.nodeData.extraInfo.sinkDatabaseName,
                this.nodeData.extraInfo.sinkTableName,
                true
              );
            }
          } else {
            this.tableList.push(...res.data);
            if (this.type == "dataBaseInsert") {
              this.getFieldList(
                datasourceId,
                this.nodeData.extraInfo.datasourceName,
                this.nodeData.extraInfo.tableName,
                false
              );
            }

            if (
              this.type == "maxwell2Database" &&
              this.nodeData.extraInfo.sourceTableType == "select"
            ) {
              this.getFieldList(
                datasourceId,
                this.nodeData.extraInfo.sourceDatabaseName,
                this.nodeData.extraInfo.sourceTableName
              );
            }
          }
        }
      });
    },
    tableNameChange(datasourceId, tableName, sink) {
      this.fieldMappingList.splice(0);
      let databaseName = "";
      if (sink) {
        databaseName = this.nodeData.extraInfo.sinkDatabaseName;
      } else {
        databaseName = this.nodeData.extraInfo.sourceDatabaseName;
      }
      this.getFieldList(datasourceId, databaseName, tableName, sink, true);
    },
    getFieldList(datasourceId, databaseName, tableName, sink, change) {
      this.$http
        .get("/dataBase/fieldList", {
          datasourceId,
          databaseName,
          tableName,
        })
        .then((res) => {
          if (res.result === 200) {
            if (sink) {
              this.sinkFieldSelectList.splice(0);
              this.sinkFieldSelectList.push(...res.data);
            } else {
              this.fieldSelectList.splice(0);
              this.fieldSelectList.push(...res.data);
              if (this.type == "dataBaseInsert" && change) {
                this.fieldItemList.splice(0);
                this.fieldItemList.push(...res.data);
                this.fieldItemList.forEach((item, index) => {
                  this.$set(item, "value", "${" + (index + 1) + "}");
                });
              }
            }
            if (this.type == "maxwell2Database" && change) {
              this.fieldSelectList.forEach((item) => {
                this.fieldMappingList.push({
                  sourceField: item.field,
                  sinkField: undefined,
                  fieldConvertList: [],
                });
              });

              if (
                this.nodeData.extraInfo.sinkDatabaseName &&
                this.nodeData.extraInfo.sourceDatabaseName
              ) {
                this.fieldMappingList.forEach((item) => {
                  this.sinkFieldSelectList.forEach((sinkFieldItem) => {
                    if (item.sourceField == sinkFieldItem.field) {
                      this.$set(item, "sinkField", sinkFieldItem.field);
                    }
                  });
                });
              }
            }
          }
        });
    },
    fieldAdd() {
      this.fieldList.splice(0);
      this.editFieldShow = true;
    },
    editFieldSubmit() {
      this.fieldList.forEach((field) => {
        let obj = {
          field,
          value: "",
        };
        this.fieldItemList.push(obj);
      });
      this.editFieldShow = false;
    },
    delete_field(index) {
      this.fieldItemList.splice(index, 1);
    },
    uploadTypeChange() {
      if (this.nodeData.extraInfo.uploadType == "FILE_SET") {
        this.$set(this.nodeData.extraInfo, "fileCollectLimitCountShow", false);
        this.$set(this.nodeData.extraInfo, "fileCollectLimitCount", "");
        this.$set(this.nodeData.extraInfo, "id", undefined);
        this.searchFileCollectList();
      } else if (this.nodeData.extraInfo.uploadType == "FILE_LIST") {
        this.$set(this.nodeData.extraInfo, "id", undefined);
        this.searchFileList();
      } else if (this.nodeData.extraInfo.uploadType == "TMP_STORE") {
        this.loadDynamicApiData("tmpStoreList");
      } else if (this.nodeData.extraInfo.uploadType == "FTP") {
        this.getFtpServerList();
      }
    },
    getPreviewSourceFileList() {
      let data = {
        collectId: this.nodeData.extraInfo.id,
        fileCollectLimitCount: this.nodeData.extraInfo.fileCollectLimitCount,
      };
      this.$http.get("/fileCollect/previewSourceFileList", data).then((res) => {
        if (res.result === 200) {
          this.previewSourceFileList = res.data;
          this.previewSourceFileListShow = true;
        }
      });
    },
    tmpStoreTagChange() {
      let tag = this.tmpStoreList.filter((item) => {
        return item.code == this.nodeData.extraInfo.tmpStoreCode;
      })[0].tag;
      this.$set(this.nodeData.extraInfo, "tag", tag);
    },
    customRequest(info) {
      this.fileLoading = true;
      this.$http
        .files("/sysPipeline/uploadPipelineOriginalFile", {
          file: info.file,
          taskId: this.taskId,
        })
        .then((res) => {
          if (res.result == 200) {
            this.$message.success("上传成功");
            this.fileLoading = false;
            this.$set(this.nodeData, "extraInfo", res.data.fileUploadInfo);
            this.$set(this.nodeData.extraInfo, "uploadType", "UPLOAD");
            this.$set(
              this.nodeData.property,
              "resultFileName",
              this.nodeData.property.resultFileName.split(".")[0] +
                "." +
                this.$common.getFileExtension(
                  res.data.fileUploadInfo.originalFileName
                )
            );
            this.$set(
              this.nodeData.property,
              "resultFileType",
              this.$common.getFileExtension(
                res.data.fileUploadInfo.originalFileName
              )
            );
          }
        });
    },
    beforeUpload(files, fileList) {
      if (this.fileList.length > 0) {
        this.$message.warning(
          `当前限制选择 1 个文件，本次选择了 ${
            fileList.length
          } 个文件，共选择了 ${this.fileList.length + fileList.length} 个文件`
        );
        return false;
      } else {
        this.fileList = fileList;
        return true;
      }
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
      this.$message.success("删除成功");
    },
  },
};
</script>

<style scoped lang="scss">
.el-cascader {
  margin-bottom: 0px !important;
  &:deep() .el-cascader__search-input {
    min-width: 5px;
  }
}
.card {
  color: black;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #e4e7ed;
  display: inline-block;
  line-height: 14px;
  cursor: pointer;
}
.card:first-child {
  border-right: none;
}
.checked {
  color: #409eff;
}

.card [disabled] {
  color: #00000040 !important;
  cursor: not-allowed !important;
  pointer-events: none !important;
}
.greenSwitch {
  background-color: #00d300 !important;
}
:deep() .ant-switch-checked {
  background-color: #1890ff !important;
}
.codemirrorFormItem {
  :deep() .ant-form-item-control {
    line-height: 21px !important;
  }
  .code_style {
    flex: 1 0 auto;
    width: 100%;
    overflow-x: scroll;
    background-color: #f9fbfc !important;
    font-size: 14px;
    :deep(.CodeMirror) {
      height: 240px;
      cursor: text !important;
      background-color: #f9fbfc !important;
      .CodeMirror-gutters {
        border-right: none !important;
        background-color: #f9fbfc !important;
        color: #4c5773 !important;
      }
    }
  }
}
.test_unit_div {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .test_unit_title {
    width: 100%;
  }

  .test_unit_title_2 {
    width: 40%;
  }

  .test_unit_title_3 {
    width: 60%;
    border-left: 1px solid #e8e8e8;
  }

  .prevFileContent {
    width: 40%;
    height: 500px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ededed;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #7c7c7c;
    }
  }

  .item_title {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .test_unit_content {
    display: flex;
    justify-content: space-between;

    .item_i {
      margin-top: 155px;
      font-size: 30px;
    }

    .item_1,
    .item_2 {
      display: flex;
      flex-direction: column;
      width: 45%;
      height: 310px;

      .item_title {
        text-align: left;
      }
      .content_title {
        margin-bottom: 0px;
      }
    }

    .item_3 {
      width: 100%;
    }

    .item_1_1,
    .item_2_2 {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    .item_textarea_2 {
      width: 100%;
    }

    .item_textarea_1 {
      width: 100%;
    }

    .item_textarea_3 {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .test_unit_content_2 {
    width: 100%;
  }

  .test_unit_content_3 {
    width: 60%;
  }

  .dropbox-ts {
    font-size: 12px;
    color: #999;
    display: block;
    line-height: 16px;
  }
}
</style>
