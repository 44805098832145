<template>
  <div class="pipelineListOrigin">
    <a-tabs v-model="activeName">
      <a-tab-pane tab="任务模板" key="1">
        <router-view name="second" v-if="activeName == '1'"></router-view>
      </a-tab-pane>
      <a-tab-pane tab="接口任务模板" key="2">
        <router-view name="second" v-if="activeName == '2'"></router-view>
      </a-tab-pane>
      <a-tab-pane tab="数据源管理" key="3">
        <router-view name="second" v-if="activeName == '3'"></router-view>
      </a-tab-pane>
      <a-tab-pane tab="文件列表" key="4">
        <router-view name="second" v-if="activeName == '4'"></router-view>
      </a-tab-pane>
      <a-tab-pane tab="节点列表" key="5">
        <router-view name="second" v-if="activeName == '5'"></router-view>
      </a-tab-pane>
      <a-tab-pane tab="FTP信息列表" key="6">
        <router-view name="second" v-if="activeName == '6'"></router-view>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
export default {
  name: "pipelineListOrigin",
  data() {
    return {
      activeName: "2",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/pipelineListOrigin/pipelineList") {
          this.activeName = "1";
        } else if (newVal == "/pipelineListOrigin/apiTask") {
          this.activeName = "2";
        } else if (newVal == "/pipelineListOrigin/dataOrigin") {
          this.activeName = "3";
        } else if (newVal == "/pipelineListOrigin/fileList") {
          this.activeName = "4";
        } else if (newVal.indexOf("/pipelineListOrigin/nodeListTab") !== -1) {
          this.activeName = "5";
        } else if (newVal == "/pipelineListOrigin/ftpServer") {
          this.activeName = "6";
        }
      },
    },
    activeName: {
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/pipelineListOrigin/pipelineList") return;
          this.$router.replace("/pipelineListOrigin/pipelineList");
        }
        if (newVal === "2") {
          if (this.$route.path == "/pipelineListOrigin/apiTask") return;
          this.$router.replace("/pipelineListOrigin/apiTask");
        }
        if (newVal === "3") {
          if (this.$route.path == "/pipelineListOrigin/dataOrigin") return;
          this.$router.replace("/pipelineListOrigin/dataOrigin");
        }
        if (newVal === "4") {
          if (this.$route.path == "/pipelineListOrigin/fileList") return;
          this.$router.replace("/pipelineListOrigin/fileList");
        }
        if (newVal === "5") {
          if (
            this.$route.path.indexOf("/pipelineListOrigin/nodeListTab") !== -1
          )
            return;
          this.$router.replace("/pipelineListOrigin/nodeListTab");
        }
        if (newVal === "6") {
          if (this.$route.path == "/pipelineListOrigin/ftpServer") return;
          this.$router.replace("/pipelineListOrigin/ftpServer");
        }
      },
    },
  },
};
</script>

<style></style>
