<template>
  <div class="portTime">
    <div class="searchCondition">
      <div class="searchConditionItem">
        <span>调用者：</span>
        <a-input
          v-model.trim="customer"
          placeholder="请输入调用者"
          allowClear
          @change="allowClearChange"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchConditionItem">
        <span>接口名称：</span>
        <a-input
          v-model.trim="className"
          placeholder="请输入接口名称"
          allowClear
          @change="allowClearChange"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchConditionItem">
        <span>接口方法名称：</span>
        <a-input
          v-model.trim="methodName"
          placeholder="请输入接口方法名称"
          allowClear
          @change="allowClearChange"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchConditionItem">
        <span>创建时间：</span>
        <a-range-picker
          v-model="date"
          value-format="YYYY-MM-DD"
          format="YYYY-MM-DD"
          style="width: 250px"
        ></a-range-picker>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
      </div>
    </div>

    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableData"
      :pagination="tablePagination"
      size="small"
      :rowKey="(record) => record.id"
      :loading="loadingTable"
    >
      <span slot="totalDispatchTime" slot-scope="text, record">
        {{ (record.totalDispatchTime / record.dispatchCount).toFixed(2) }}
      </span>
    </a-table>
  </div>
</template>

<script>
export default {
  name: "portDetail",
  data() {
    return {
      tableColumns: [
        {
          title: "序号",
          ellipsis: true,
          dataIndex: "id",
          align: "center",
          width: 100,
        },
        {
          title: "日期",
          ellipsis: true,
          dataIndex: "date",
          width: 130,
        },
        {
          title: "调用者",
          ellipsis: true,
          dataIndex: "customer",
          width: 100,
        },
        {
          title: "接口名称",
          ellipsis: true,
          dataIndex: "className",
          width: 200,
        },
        {
          title: "接口方法名称",
          ellipsis: true,
          dataIndex: "methodName",
          width: 200,
        },
        {
          title: "总调度次数",
          ellipsis: true,
          dataIndex: "dispatchCount",
          align: "center",
          width: 100,
        },
        {
          title: "平均调度耗时毫秒",
          ellipsis: true,
          dataIndex: "totalDispatchTime",
          scopedSlots: { customRender: "totalDispatchTime" },
          align: "center",
          width: 150,
        },
        {
          title: "最长调度耗时",
          ellipsis: true,
          dataIndex: "maxDispatchTime",
          align: "center",
          width: 150,
        },
        {
          title: "成功调度次数",
          ellipsis: true,
          dataIndex: "successDispatchCount",
          align: "center",
          width: 150,
        },
        {
          title: "失败调度次数",
          ellipsis: true,
          dataIndex: "failureDispatchCount",
          align: "center",
          width: 150,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 170,
        },
      ],
      tableData: [],
      tablePagination: {},
      loadingTable: false,
      currentPage: 1,
      customer: "",
      className: "",
      methodName: "",
      date: [],
    };
  },
  mounted() {
    this.query(1);
  },
  methods: {
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 查询
    query(index) {
      this.loadingTable = true;
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: 10,
        customer: this.customer,
        className: this.className,
        methodName: this.methodName,
      };
      if (this.date !== null) {
        data.dateStart = this.date[0];
        data.dateEnd = this.date[1];
      }
      this.$http.get("/monitor/page", data, "/apiPrefix").then((res) => {
        if (res.result === 200) {
          this.tableData = res.data.records;
          this.tablePagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.currentPage_data(current),
          };
          this.loadingTable = false;
        }
      });
    },
    // 翻页
    currentPage_data(index) {
      this.query(index);
    },
  },
};
</script>
