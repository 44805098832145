<template>
  <div class="pipelineList">
    <div class="searchCondition">
      <div class="searchConditionItem">
        <span>账号：</span>
        <a-input
          v-model.trim="accessKey"
          placeholder="请输入账号"
          allowClear
          @change="allowClearChange"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="editFormShow = true" icon="plus"
          >新增接口账号</a-button
        >
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableData"
      :pagination="tablePagination"
      size="small"
      :rowKey="(record) => record.id"
      :loading="loadingTable"
    >
      <span slot="enabled" slot-scope="text, record">
        <a-switch
          checked-children="已启用"
          un-checked-children="已禁用"
          v-model="record.enabled"
          @change="edit_api(record, true)"
        />
      </span>
      <span slot="action" slot-scope="text, record">
        <a-button type="link" @click="edit_api(record)">修改</a-button>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="left"
          okText="确认"
          cancelText="取消"
          @confirm="delete_api(record)"
        >
          <template slot="title">确认是否删除{{ record.accessKey }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>

    <a-modal
      :maskClosable="false"
      :title="title"
      v-model="editFormShow"
      width="500px"
      :afterClose="editFormClose"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="账号:">
          <a-input
            v-model="accessKeyAdd"
            placeholder="请输入账号"
            style="width: 80%"
            allowClear
            :disabled="disabled"
          />
        </a-form-item>
        <a-form-item label="密码:">
          <a-input
            v-model="accessSecret"
            placeholder="请输入密码"
            style="width: 80%"
            allowClear
          />
        </a-form-item>
        <a-form-item label="回传地址:">
          <a-input
            v-model="callbackUrl"
            placeholder="请输入回传地址"
            style="width: 80%"
            allowClear
          />
        </a-form-item>
        <a-form-item label="启用状态:">
          <a-switch
            checked-children="启用"
            un-checked-children="禁用"
            v-model="enabled"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button type="primary" @click="editForm_submit">确定</a-button>
          <a-button @click="editFormShow = false">取消</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "apiUserList",
  data() {
    return {
      tableColumns: [
        {
          title: "序号",
          ellipsis: true,
          dataIndex: "id",
          align: "center",
          width: 100,
        },
        {
          title: "账号",
          ellipsis: true,
          dataIndex: "accessKey",
          width: 200,
        },
        {
          title: "密码",
          ellipsis: true,
          dataIndex: "accessSecret",
          width: 200,
        },
        {
          title: "回传地址",
          ellipsis: true,
          dataIndex: "callbackUrl",
          width: 200,
        },
        {
          title: "启用状态",
          ellipsis: true,
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
          align: "center",
          width: 100,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 120,
        },
      ],
      tableData: [],
      loadingTable: false,
      tablePagination: {},
      currentPage: 1,
      editFormShow: false,
      callbackUrl: "",
      accessSecret: "",
      enabled: undefined,
      title: "新增",
      id: "",
      accessKey: "",
      accessKeyAdd: "",
      disabled: false,
    };
  },
  created() {
    this.query(1);
  },
  methods: {
    editForm_submit() {
      let data = {
        accessKey: this.accessKeyAdd,
        accessSecret: this.accessSecret,
        callbackUrl: this.callbackUrl,
        enabled: this.enabled,
      };
      if (this.title == "新增") {
        this.$http
          .json_post("/pipelineApiUser/addPipelineApiUser", data)
          .then((res) => {
            if (res.result == 200) {
              this.$message.success("新增成功");
              this.editFormShow = false;
              this.query();
            }
          });
      } else {
        data.id = this.id;
        this.$http
          .json_post("/pipelineApiUser/editPipelineApiUser", data)
          .then((res) => {
            if (res.result == 200) {
              this.$message.success("修改成功");
              this.editFormShow = false;
              this.query();
            }
          });
      }
    },
    editFormClose() {
      this.title = "新增";
      this.accessKeyAdd = undefined;
      this.accessSecret = "";
      this.callbackUrl = "";
      this.enabled = undefined;
      this.disabled = false;
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 查询
    query(index) {
      this.loadingTable = true;
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: 10,
        accessKey: this.accessKey,
      };
      this.getPipelineLogList(data);
    },
    // 查询记录数据
    getPipelineLogList(data) {
      this.$http
        .get("/pipelineApiUser/pipelineApiUserPage", data)
        .then((res) => {
          if (res.result === 200) {
            this.tableData = res.data.records;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.currentPage_data(current),
            };
            this.loadingTable = false;
          }
        });
    },
    edit_api(val, enable) {
      this.title = "修改";
      this.accessKeyAdd = val.accessKey;
      this.accessSecret = val.accessSecret;
      this.callbackUrl = val.callbackUrl;
      this.enabled = val.enabled;
      this.id = val.id;
      this.disabled = true;
      if (enable) {
        this.editForm_submit();
      } else {
        this.editFormShow = true;
      }
    },
    delete_api(val) {
      let data = {
        id: val.id,
      };
      this.$http
        .json_post("/pipelineApiUser/deletePipelineApiUser", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("删除成功");
            this.query();
          }
        });
    },
    // 翻页
    currentPage_data(index) {
      this.query(index);
    },
  },
};
</script>
